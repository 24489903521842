import { Vital } from "../../interfaces/vitals/Vital";
///// Placeholder data while we wait for websocket data //////

export const patientVitalsPlaceholder = () => {
  const dummyVital: Vital = {
    text: "--",
    unit: "--",
    value: "--",
    valid: false,
    error_code: "",
    error_message: "",
    error_alarm_flag: "0",
    param_name: "?",
    low: "--",
    high: "--",
  };

  return {
    HR: dummyVital,
    EtCO2: dummyVital,
    SpO2: {
      sp: dummyVital,
      perf: dummyVital,
    },
    IBP1: {
      systole: dummyVital,
      diastole: dummyVital,
      map: dummyVital,
    },
    IBP2: {
      systole: dummyVital,
      diastole: dummyVital,
      map: dummyVital,
    },
    NIBP: {
      systole: dummyVital,
      diastole: dummyVital,
      map: dummyVital,
    },
    Resp: {
      co2: dummyVital,
      rr: dummyVital,
    },
    Temp: {
      t1: dummyVital,
      t2: dummyVital,
    },
  };
};
