import { useEffect, useState } from "react";

export const useExternalScript = (url: string) => {
  let [state, setState] = useState<
    "ready" | "loading" | "idle" | "error" | undefined
  >(url ? "loading" : "idle");

  const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";

  useEffect(() => {
    if (!isPharlap) return;
    if (!url) {
      setState("idle");
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e: any) => {
      setState(e.type === "load" ? "ready" : "error");
    };

    if (!script) {
      console.log("script not found, creating");
      script = document.createElement("script");
      // @ts-ignore
      script.type = "application/javascript";
      // @ts-ignore
      script.src = url;
      // @ts-ignore
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    } else {
      setState("ready");
    }

    return () => {
      script?.removeEventListener("load", handleScript);
      script?.removeEventListener("error", handleScript);
    };
  }, [isPharlap, url]);
  return state;
};
