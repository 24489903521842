// Your SysPoolView component

import { Fragment } from "react";
import DeviceCard from "../../components/DeviceCard";
import { Device } from "../../interfaces/Device";
import { ReactNode } from "react";
import { Typography, Grid } from "@mui/material";

interface Props {
  devicelist: Device[];
  setDevices: (devices: Device[]) => void;
  children: ReactNode;
}

const deviceListStyle: React.CSSProperties = {
  maxHeight: "600px", // Set this to whatever height works for your design
  overflowY: "auto",
};

export const SysPoolView = ({ devicelist, setDevices, children }: Props) => {
  console.log("Syspool:", devicelist);
  return (
    <>
      {children}
      <div style={deviceListStyle}>
        {devicelist?.length > 0 ? (
          <Grid container spacing={3}>
            {devicelist?.map((device: Device) => (
              <Fragment key={device.device_id}>
                {device.device_id && <DeviceCard device={device} />}
              </Fragment>
            ))}
          </Grid>
        ) : (
          <Typography>No devices to show</Typography>
        )}
      </div>
    </>
  );
};
