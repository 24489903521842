import {
  Grid,
  //	Typography,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Device } from "../../../interfaces/Device";
import { useState, useEffect } from "react";
//import Card from "@mui/material";
//import CardContent from "@mui/material";
import { AlarmProfile } from "./AlarmProfile";
import { AlarmProfileSelect } from "./AlarmProfileSelect";
import { useCookies } from "react-cookie";
import DeviceService from "../../../services/DeviceService";
import QRCode from "react-qr-code";
// import * as CONSTANTS from "../../../utils/constants/constants";
import VideoLinkCard from "../../../components/VideoLinkCard";
import { Card, CardContent, Typography } from "@mui/material";

// var CryptoJS = require("crypto-js");

interface Props {
  device: Device | null;
  alarmProp: any;
  openState: boolean;
  setOpenState: (show: boolean) => void;
}

const dictToArray = (d: { [key: string]: { headline: string } }) => {
  const arr = [];

  for (var key in d) {
    if (d.hasOwnProperty(key)) {
      const item = d[key];
      const headline = item.headline;
      arr.push([key, headline]);
    }
  }

  return arr;
};

export const DeviceDialog = ({
  device,
  alarmProp,
  openState,
  setOpenState,
}: Props) => {
  // @ts-ignore
  const user: any = JSON.parse(localStorage.getItem("user"));
  //console.log ("Alarm profiles alarmProp:", alarmProp)
  //console.log ("DeviceDialog:", user, device)
  const alarmProfiles = dictToArray(alarmProp.alarm_profile_defs);

  const lookup_alarmProfileKey = (value: string) => {
    for (var i = 0; i < alarmProfiles.length; i++) {
      const ap = alarmProfiles[i];
      if (value === ap[1]) {
        return ap[0];
      }
    }
    return "unknown"; // default
  };
  const lookup_alarmProfileValue = (key: string) => {
    for (var i = 0; i < alarmProfiles.length; i++) {
      const ap = alarmProfiles[i];
      if (key === ap[0]) {
        return ap[1];
      }
    }
    return "unknown"; // default
  };

  const [selectedAlarmProfile, setSelectedAlarmProfile] = useState<string>(
    alarmProfiles[0][1]
  ); // Default to the first in the list.
  const [selectedAlarmProfileDef, setSelectedAlarmProfileDef] = useState(
    alarmProp.alarm_profile_defs["NEWS"]
  );

  const [cookies, ,] = useCookies(["access_token"]);

  const theme = useTheme();
  const { t } = useTranslation();
  //const [openState, setOpenState] = useState (false);
  const [nickname, setNickname] = useState<string>("");
  const [nicknameInitial, setNicknameInitial] = useState<string>("");
  const [postData, setPostData] = useState("");
  const [location, setLocation] = useState<string>("");
  const [locationInitial, setLocationInitial] = useState<string>("");

  const [tempUnit, setTempUnit] = useState<string>(
    device ? device.temp_unit : "C"
  );
  const [tempUnitInitial, setTempUnitInitial] = useState<string>("");
  const [alarmProfileInitial, setAlarmProfileInitial] = useState<string>("");
  const [showSaveButton, setShowSaveButton] = useState(false);

  const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
  const [isEditingThresholds, setIsEditingThresholds] = useState(false);
  const [alarms, setAlarms] = useState<any>();
  const [alarmsEnable, setAlarmsEnable] = useState(false);
  const [alarmsEnableInitial, setAlarmsEnableInitial] = useState(
    device?.enable_alarms ? true : false
  );
  const [alarmsOnInitial, setAlarmsOnInitial] = useState("");
  const [alarmsOffInitial, setAlarmsOffInitial] = useState("");
  const [alarmsOn, setAlarmsOn] = useState("");
  const [alarmsOff, setAlarmsOff] = useState("");
  //var decoded = jwt_decode(cookies.access_token);
  //console.log ("decodedToken, isExpired = ", decoded)

  const handleClose = () => {
    setIsEditingThresholds(false);
    setOpenState(false);
  };

  useEffect(() => {
    if (device === null) {
      return;
    }
    if (device) {
      //console.log("Device:", device);

      // setOpenState(true);
      // const clear_post_data = {
      // 	api_url: CONSTANTS.HOST,
      // 	post_url: user.proxy_server,
      // 	device_id: device.device_id,
      // 	phrase: device.device_id + "abc123",
      // };

      //console.log("user.qr_data is ", user.qr_data);
      const qr_data = JSON.parse(user.qr_data);
      qr_data["device_id"] = device.device_id;
      qr_data["device_type"] = device.device_type;
      qr_data["device_nickname"] = device.nickname;
      qr_data["sno"] = device.device_serialnumber;
      qr_data["org"] = user.organization;
      //["api"] = user.api

      // console.log("Postdata is ", clear_post_data);
      // const post_data = CryptoJS.AES.encrypt(
      // 	JSON.stringify(user.qr_data),
      // 	"Secret Passphrase"
      // ).toString();
      // Decrypt:
      // console.log(
      // 	CryptoJS.AES.decrypt(
      // 		post_data,
      // 		"Secret Passphrase"
      // 	).toString(CryptoJS.enc.Utf8)
      // );
      setPostData(JSON.stringify(qr_data));

      //console.log ("postdata encrypted is ", post_data, "END")
      // console.log("qr_data is ", JSON.stringify(qr_data));
      setNicknameInitial(device.nickname);
      setNickname(device.nickname);
      setLocationInitial(device.location);
      setLocation(device.location);
      setTempUnitInitial(device.temp_unit);
      setTempUnit(device.temp_unit);
      setAlarmsEnable(device.enable_alarms);
      setAlarmsEnableInitial(device.enable_alarms ? true : false);
      setAlarmProfileInitial(
        lookup_alarmProfileValue(device.alarm_profile_key)
      );
      setSelectedAlarmProfile(
        lookup_alarmProfileValue(device.alarm_profile_key)
      );
      setSelectedAlarmProfileDef(
        alarmProp.alarm_profile_defs[device.alarm_profile_key]
      );
      const deviceAlaramProfile = JSON.parse(device.alarm_profile);
      setAlarmsOnInitial("" + deviceAlaramProfile.raise_holdoff);
      setAlarmsOn("" + deviceAlaramProfile.raise_holdoff);
      setAlarmsOffInitial("" + deviceAlaramProfile.clear_holdoff);
      setAlarmsOff("" + deviceAlaramProfile.clear_holdoff);
      const excludeAlarms = ["Act", "ACVPU"];
      if (isPharlap) {
        deviceAlaramProfile.alarms = deviceAlaramProfile?.alarms.filter(
          (alarm: any) => !excludeAlarms.includes(alarm.code)
        );
      }
      if (deviceAlaramProfile?.alarms) setAlarms(deviceAlaramProfile.alarms);
      else setAlarms(deviceAlaramProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const handleNicknameChange = (val: string) => {
    setNickname(val);
  };

  const handleLocationChange = (val: string) => {
    setLocation(val);
  };

  const handleTempUnitChange = (event: SelectChangeEvent) => {
    setTempUnit(event.target.value);
  };

  const handleAlarmsOnChange = (val: string) => {
    if (parseInt(val) < 0) {
      return;
    }
    setAlarmsOn(val);
  };
  const handleAlarmsOffChange = (val: string) => {
    if (parseInt(val) < 0) {
      return;
    }
    setAlarmsOff(val);
  };

  const handleEnableAlarmsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAlarmsEnable(event.target.checked);
  };

  const evalSaveVisibility = () => {
    const save_nickname = nickname.trim() !== nicknameInitial;
    const save_location = location.trim() !== locationInitial;
    const save_tempunit = tempUnit.trim() !== tempUnitInitial;
    const save_alarmprofile = selectedAlarmProfile !== alarmProfileInitial;
    const save_almsenable = alarmsEnable !== alarmsEnableInitial;
    const almOn = alarmsOn !== alarmsOnInitial;
    const almOff = alarmsOff !== alarmsOffInitial;

    const showButton =
      save_nickname ||
      save_location ||
      save_tempunit ||
      save_almsenable ||
      save_alarmprofile ||
      almOn ||
      almOff;
    //console.log ("Show button:", save_nickname , save_location , save_tempunit , save_alarmprofile, almOn, almOff)
    return showButton;
  };

  useEffect(() => {
    setShowSaveButton(evalSaveVisibility());
    // eslint-disable-next-line
  }, [
    nickname,
    location,
    tempUnit,
    alarmsEnable,
    selectedAlarmProfile,
    nicknameInitial,
    tempUnitInitial,
    locationInitial,
    alarmProfileInitial,
    alarmsEnableInitial,
    alarmsOn,
    alarmsOff,
    alarmsOnInitial,
    alarmsOffInitial,
  ]);

  const setDeviceVideoLink = (link: string, expiry: string): void => {
    if (device) {
      device.video_link = link;
      device.video_expiry = expiry;
    }
  };

  const changeAlmProfile = (p: any): void => {
    setSelectedAlarmProfileDef(p);
    setAlarmsOn(p.raise_holdoff);
    setAlarmsOff(p.clear_holdoff);
  };
  //useEffect (() => {
  //	console.log ("New alarm profile:", selectedAlarmProfileDef)
  //	setAlarmsOn (selectedAlarmProfileDef.raise_holdoff);
  //	setAlarmsOff (selectedAlarmProfileDef.clear_holdoff)
  //}, [selectedAlarmProfileDef])
  const handleSaveChanges = () => {
    console.log("handleSaveChanges:", device);
    if (!device) {
      return;
    }
    device.nickname = nickname;
    device.location = location;
    device.alarm_profile_key = lookup_alarmProfileKey(selectedAlarmProfile);
    device.temp_unit = tempUnit;
    device.enable_alarms = alarmsEnable;

    // Update the alarm profile. Here we need to update the individual alarm points with the device-level holdoff values
    // But only if alarming is enabled.
    if (alarmsEnable) {
      //const almp = JSON.parse (device.alarm_profile)
      const almp = selectedAlarmProfileDef;
      almp.raise_holdoff = alarmsOn !== "" ? parseInt(alarmsOn) : 0;
      almp.clear_holdoff = alarmsOff !== "" ? parseInt(alarmsOff) : 0;

      for (var i = 0; i < almp.alarms.length; i++) {
        almp.alarms[i].raise_holdoff = almp.raise_holdoff;
        almp.alarms[i].clear_holdoff = almp.clear_holdoff;
      }
      device.alarm_profile = JSON.stringify(almp);
    }

    DeviceService.editDevice(
      device,
      cookies.access_token,
      device.device_id
    ).then((res: any) => {
      //console.log ("Result:", res)
      if (res.status === 200) {
        //console.log ("Success")
        setNicknameInitial(nickname);
        setLocationInitial(location);
        setTempUnitInitial(tempUnit);
        setAlarmsEnableInitial(alarmsEnable);
        setAlarmProfileInitial(selectedAlarmProfile);
        const alm = JSON.parse(device.alarm_profile);
        setAlarmsOnInitial("" + alm.raise_holdoff);
        setAlarmsOffInitial("" + alm.clear_holdoff);
      } else {
        console.log("Error in updating device:", device);
      }
    });
  }; // handleSaveChanges

  const editThresholds = () => {
    if (isEditingThresholds) {
      updateAlarms();
      setIsEditingThresholds(false);
    } else {
      setIsEditingThresholds(true);
    }
  };

  const updateAlarms = () => {
    DeviceService.editAlarms(
      {
        new_alarms: alarms,
      },
      cookies.access_token,
      device!.device_id
    ).then((res: any) => {
      if (res.status === 200) {
        console.log("Alarms updated");
      } else {
        console.log("Error in updating alarms");
      }
    });
  };

  const deviceVideoLink: string = device?.video_link ? device.video_link : "";
  const deviceVideoExpiry: string = device?.video_expiry
    ? device.video_expiry
    : "";

  const videoLinkCapable: boolean =
    device?.camera_id != null && device?.camera_id !== "";

  return (
    <Dialog
      open={openState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle> {t("device_details")} </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={4} mb={1}>
            <Card
              sx={{
                borderRadius: "5",
                border: 1,
                borderColor: "grey.500",
              }}
            >
              <CardContent>
                <CardItem title={t("device_id")} content={device?.device_id} />
                <CardItem title={t("type")} content={device?.device_type} />
                <CardItem title={t("model")} content={device?.device_model} />
                <CardItem
                  title={t("serialnumber")}
                  content={device?.device_serialnumber}
                />
                {postData !== "" && <QRCode value={postData} size={128} />}
              </CardContent>
            </Card>
          </Grid>

          <Grid container item columnSpacing={1} xs={12} md={8}>
            <Grid item xs={12} md={3} my={1}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("nickname")}
                id="nickname"
                value={nickname}
                onChange={(e) => handleNicknameChange(e.target.value)}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} my={1}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("location")}
                id="location"
                value={location}
                variant="outlined"
                onChange={(e) => handleLocationChange(e.target.value)}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={2} my={1}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: "text.primary" }} id="tempunit">
                  {t("temp_unit")}
                </InputLabel>
                <Select
                  value={tempUnit}
                  label={t("temp_unit")}
                  onChange={handleTempUnitChange}
                >
                  <MenuItem key={"Celcius"} value={"C"}>
                    {t("celcius")}
                  </MenuItem>
                  <MenuItem key={"Fahrenheit"} value={"F"}>
                    {t("fahrenheit")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!isPharlap && (
              <Grid item xs={12} md={4} my={1}>
                <AlarmProfileSelect
                  profileDefs={alarmProp.alarm_profile_defs}
                  selectedProfile={selectedAlarmProfile}
                  setProfileDef={changeAlmProfile}
                  setProfileSelect={setSelectedAlarmProfile}
                  title={t("scoring_method")}
                />
              </Grid>
            )}

            {/* Alarm hold-on and old-off timers */}
            <Card
              sx={{
                borderRadius: "5",
                border: 1,
                borderColor: "grey.500",
                width: "100%",
                marginLeft: 1,
              }}
            >
              <Grid container flexDirection="row">
                <Grid container justifyContent="flex-start">
                  <Typography padding={1} color="text.primary">
                    {t("alarms_off")}
                  </Typography>
                  <Switch
                    checked={alarmsEnable}
                    onChange={handleEnableAlarmsChange}
                  />
                  <Typography padding={1} color="text.primary">
                    {t("alarms_on")}
                  </Typography>
                </Grid>

                {alarmsEnable && (
                  <Grid
                    container
                    item
                    md={5}
                    my={1}
                    justifyContent={"flex-start"}
                    flexDirection="row"
                  >
                    <Typography padding={1} color="text.primary">
                      {t("alarms_raise_tolerance")}
                    </Typography>
                    <TextField
                      sx={{
                        width: 90,
                        height: 20,
                        input: {
                          height: 10,
                          color: theme.palette.text.primary,
                          background: theme.palette.background.default,
                        },
                        label: {
                          color: theme.palette.text.primary,
                        },
                      }}
                      id="alarm_on"
                      value={alarmsOn || ""}
                      onChange={(e) => handleAlarmsOnChange(e.target.value)}
                      label={t("Seconds")}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}

                {alarmsEnable && (
                  <Grid
                    container
                    item
                    md={6}
                    my={1}
                    justifyContent="flex-start"
                    flexDirection="row"
                  >
                    <Typography padding={1} color="text.primary">
                      {t("alarms_clear_tolerance")}
                    </Typography>
                    <TextField
                      sx={{
                        width: 90,
                        height: 20,
                        input: {
                          height: 10,
                          color: theme.palette.text.primary,
                          background: theme.palette.background.default,
                        },
                        label: {
                          color: theme.palette.text.primary,
                        },
                      }}
                      id="alarm_on"
                      value={alarmsOff}
                      onChange={(e) => handleAlarmsOffChange(e.target.value)}
                      label={t("Seconds")}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>

            {isPharlap && videoLinkCapable && (
              <Grid item xs={12}>
                <VideoLinkCard
                  device_id={device?.device_id}
                  initialVideoLink={deviceVideoLink}
                  initialEnabled={true}
                  initialExpirationTime={new Date(deviceVideoExpiry)}
                  setDeviceVideoLink={setDeviceVideoLink}
                />
              </Grid>
            )}
            <Grid container justifyContent="flex-end" sx={{ height: 100 }}>
              {showSaveButton && (
                <Button onClick={handleSaveChanges}>{t("save_changes")}</Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sx={{ marginTop: 1 }} justifyContent="center">
          <AlarmProfile
            profile={selectedAlarmProfileDef}
            alarms={alarms}
            isEditingThresholds={isEditingThresholds}
            setAlarms={setAlarms}
            setIsEditingThresholds={setIsEditingThresholds}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={editThresholds}>
          {isEditingThresholds ? t("save") : t("edit_thresholds")}
        </Button>
        <Button onClick={handleClose}> {t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ItemProps {
  title: any;
  content: any;
}

export const CardItem = ({ title, content }: ItemProps) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography>{title} </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{content}</Typography>
      </Grid>
    </Grid>
  );
};
