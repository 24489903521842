import { Grid, GridSize } from "@mui/material";
import { useEffect, useState, memo, Fragment, ReactNode } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import useVitalsSocket from "../../hooks/useVitalsSocket";
import { Device } from "../../interfaces/Device";
import DeviceService from "../../services/DeviceService";
import { Parameter } from "./Parameter";
import { ParameterBox } from "./ParameterBox";
import SelectParameters from "./SelectParameters";
import { TopPartMonitorNEWS } from "./TopPartMonitorNEWS";

interface Props {
  device: Device;
  compact?: boolean;
  fullWidth?: boolean;
  setDevices?: (devices: Device[]) => void;
  width?: GridSize;
  selectParams?: ReactNode;
  availableParams: string[];
  type?: string;
  useLink?: boolean;
}

const DeviceMonitor = ({
  device,
  compact = false,
  fullWidth = false,
  useLink = true,
  setDevices,
  width = 4,
  availableParams,
  type = "devicemonitor",
}: Props) => {
  const { vitals, news, patientInfo, connectStatus } = useVitalsSocket({
    device,
    setDevices,
  });
  const ALL_PARAMS = ["HR", "SpO2", "IBP1", "IBP2", "NIBP", "Resp", "Temp"];
  const savedParams =
    type === "mapmonitor"
      ? availableParams
      : JSON.parse(
          // @ts-ignore
          localStorage.getItem(type + "-" + device.device_id)
        );

  const [selectableParameters, setSelectableParameters] = useState<string[]>(
    []
  );

  const [selectedParameters, setSelectedParameters] = useState<string[]>(
    savedParams || ALL_PARAMS
  );

  const [cookies] = useCookies(["access_token"]);

  const { data } = useFetch({
    url: DeviceService.getDevice(),
    access_token: cookies.access_token,
    id: device?.device_id,
  });

  useEffect(() => {
    if (data && device.device_id !== "None") {
      let parameters = JSON.parse(data.device_def).parameters;
      let params: any[] = [];
      Object.keys(parameters).forEach((key) => {
        let temp = key;
        if (temp.includes("_")) temp = temp.split("_")[0];
        else if (temp === "SPO2") temp = "SpO2";
        else if (temp === "RR") temp = "Resp";
        else if (temp === "Temp1") temp = "Temp";

        if (!params.includes(temp) && ALL_PARAMS.includes(temp)) {
          setSelectableParameters((prev) => [...prev, temp]);
          params.push(temp);
        }
      });

      if (!savedParams)
        setSelectedParameters(() => {
          if (params.length > 6)
            return params.filter((_param, index) => index < 6);
          else return params;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let v = vitals;

  if (compact) {
    return (
      <Grid container xs={12} md={12} alignContent="baseline" padding="3px">
        <TopPartMonitorNEWS
          device={device}
          connectStatus={connectStatus}
          patientInfo={patientInfo}
          news={news}
        />
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{ backgroundColor: "black" }}
        >
          {Object.keys(v).map((key: string) => {
            if (!selectedParameters.includes(key)) return null;
            const param = {
              ...(v as any)[key],
              param_name: key,
            };
            return (
              <Parameter
                key={key}
                parameter={param}
                connectStatus={connectStatus}
                compact
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      item
      container
      sm={12}
      md={!fullWidth ? 4 : 12}
      alignContent="baseline"
      padding="3px"
    >
      <TopPartMonitorNEWS
        device={device}
        news={news}
        patientInfo={patientInfo}
        connectStatus={connectStatus}
        useLink={useLink}
        selectParams={
          <SelectParameters
            deviceId={device.device_id}
            selectableParameters={selectableParameters}
            selectedParameters={selectedParameters}
            setSelectedParameters={setSelectedParameters}
            maxParameters={6}
            type={type}
          />
        }
      />
      {Object.keys(v).map((key: string, i: number) => {
        const isLast = i + 1 === selectedParameters.length - 1;
        const oneParam = selectedParameters.length === 1 && i === 1;
        if ((selectedParameters.length % 2 === 1 && isLast) || oneParam) {
          return (
            <Grid item xs={6} key={key}>
              <ParameterBox>
                <Fragment />
              </ParameterBox>
            </Grid>
          );
        } else if (!selectedParameters.includes(key)) return null;
        let param = {
          ...(v as any)[key],
          param_name: key,
        };

        // Fix EtCO2 not displaying. Used to be inside of Resp. Quick Fix.
        if (key === "Resp") {
          param = {
            ...param,
            ...(v as any)["EtCO2"],
          };
        }
        return (
          <Grid key={key} container item xs={6}>
            <Parameter parameter={param} connectStatus={connectStatus} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(DeviceMonitor);
