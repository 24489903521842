import { Grid, Box, Typography } from "@mui/material";
import { Device } from "../../interfaces/Device";
import { LiveUnit } from "./Live/LiveUnit";
import "../VicuManagement/VicuManagement.css";
import { ReactNode } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import React from "react";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../ReRoute/ServerProblem";

interface Props {
  devicelist: Device[];
  children?: ReactNode;
}

//const PARMS = ["ECG_L1", "SpO2", "Resp", "IBP1"];

interface parmProps {
  waves: string[];
  parms: string[];
}

export const parmMap: { [id: string]: parmProps } = {
  "Recobro v2": {
    waves: ["ECG_L1", "SpO2", "Resp", "IBP1"],
    parms: ["HR", "Resp", "SpO2", "IBP1"],
  },
  "CL-Vest": {
    waves: ["ECG_L1", "Resp", "ACT"],
    parms: ["HR", "Resp", "Temp"],
  },
  LP1550: { waves: ["ECG_L2", "Resp"], parms: ["HR", "Resp", "Temp"] },
  LP1251e: { waves: ["ECG_L2", "Resp"], parms: ["HR", "Resp", "Temp"] },
  "c-med alpha": { waves: [], parms: ["HR", "SpO2", "Temp"] },
  TEGO: { waves: [], parms: ["HR", "SpO2", "Temp"] },
  TOKO: { waves: ["ECG_L1", "SpO2"], parms: ["HR", "SpO2", "Temp"] },
  "Defigard Touch 7": {
    waves: ["ECG_L1", "SpO2", "IBP1", "EtCO2"],
    parms: ["HR", "Resp", "SpO2", "IBP1"],
  },
};

export const LiveDashboard = ({ devicelist, children }: Props) => {
  console.log("LiveDashboard renders", devicelist);

  /*
   * Depending on the device type, we show different parameters in the dashboard
   *
   * Recobro: HR, SpO2, Resp, IBP1
   * LifeSignals: HR, RR, Temp
   * Chronolife: HR, RR, Temp
   */

  const [cookies, ,] = useCookies(["access_token"]);

  const {
    data: alarmProfiles,
    loading: almLoading,
    error: almError,
  } = useFetch({
    url: DeviceService.getTypes(),
    access_token: cookies.access_token,
  });

  if (!alarmProfiles || almLoading) {
    return <Loading />;
  }
  if (almError) {
    return <ServerProblem />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {children}

      {
        <Grid container direction="row">
          {devicelist?.length > 0 ? (
            devicelist.map((device) => (
              <LiveUnit
                key={device.device_id}
                device={device}
                availableWaveforms={
                  parmMap[device.device_model]
                    ? parmMap[device.device_model].waves
                    : []
                }
                parameterBox={true}
                header={true}
                minHeight={123}
                availableParameters={
                  parmMap[device.device_model]
                    ? parmMap[device.device_model].parms
                    : []
                }
                alarmProp={alarmProfiles}
              />
            ))
          ) : (
            <Typography>No devices to show</Typography>
          )}
        </Grid>
      }
    </Box>
  );
};
