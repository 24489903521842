import { useContext } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { useAlarmMessage } from "../../hooks/useAlarmMessage";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import CustomTabs from "../tabs/CustomTabs";
import { TrendDashboard } from "../../pages/Home/TrendDashboard";
import { LiveDashboard } from "../../pages/Home/LiveDashboard";
import { SysAdmin } from "../../pages/SysAdmin/SysAdmin";
import { NEWSTable } from "../../pages/Home/NEWS/NEWSTable";
import DeviceService from "../../services/DeviceService";
import VICUService from "../../services/VICUService";
import { StandardView } from "../../pages/Home/StandardView";
import { MapView } from "../../pages/Home/MapView/MapView";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import { UserContext } from "../../context/UserContext";
import { useState, useEffect } from "react";
import { Device } from "../../interfaces/Device";
import { Grid, Button } from "@mui/material";
//import { User } from "../../interfaces/user/User";

import { VICU } from "../../interfaces/VICU";
import UtilsService from "../../services/UtilsService";
import { useDrop } from "react-dnd";
import { SysPoolView } from "../../pages/Home/SysPoolView";
import useAdminSocket from "../../hooks/useAdminSocket";

export const CMSDashboard = () => {
  // console.log ("CMSDashboard renders")
  const { t } = useTranslation();
  const context = useContext(UserContext);
  const [cookies, ,] = useCookies(["access_token"]);
  const [selectedVICU, setSelectedVICU] = useState<VICU | null>(null);
  const [visibleDevices, setVisibleDevices] = useState<Device[]>([]);
  var token = cookies.access_token; // userCtx.user.token;
  const [adminDataPacket, setAdminDataPacket] = useState<any>(null);
  const [monitorGroupList, setMonitorGroupList] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const updateDeviceList = () => {
    refresh();
  };

  // Retrieve all devices for this organization the when the CMSDashboard renders.
  // Should only happen once, as this is the top-level component.
  const {
    data: devices,
    setData: setDevices,
    loading,
    error,
    refresh,
  } = useFetch({
    url: DeviceService.getDevices(),
    access_token: token,
  });

  // Fetch all Monitor Groups
  const {
    data: initialMonitorGroupList,
    loading: monitorGroupLoading,
    error: monitorGroupError,
  } = useFetch({
    url: VICUService.getAllGroups(),
    access_token: cookies.access_token,
  });

  // This gets triggered when the initial list of monitor groups arrive from the server.
  // It happens when the CMSDashboard loads.
  useEffect(() => {
    if (initialMonitorGroupList === null) {
      return;
    }
    console.log("Initial Monitor Group List:", initialMonitorGroupList);
    if (selectedVICU === null) {
      // Select the first monitor group in the list if nothing has been accessed yet.
      setSelectedVICU(initialMonitorGroupList[0]);
    }
    //console.log ("Initial active Monitor Group:", selectedVICU)
    setMonitorGroupList(initialMonitorGroupList);
  }, [initialMonitorGroupList, selectedVICU]);

  useEffect(() => {
    if (!selectedVICU) {
      return;
    }
    console.log("New Monitor Group Selected:", selectedVICU);
    setVisibleDevices(selectedVICU.devices);
  }, [selectedVICU]);

  const {
    data: systemType,
    loading: systemTypeLoading,
    error: systemTypeError,
  } = useFetch({
    url: UtilsService.getSystemType(),
    access_token: token,
  });

  useEffect(() => {
    if (systemType) {
      localStorage.setItem("SYSTEM_TYPE", systemType["system_type"]);
    }
  }, [systemType]);

  /*
   * Admin data packets
   * These data packets contain information about the system, access permissions etc.
   */
  useEffect(() => {
    if (adminDataPacket === null) {
      return;
    }
    let msgType = adminDataPacket["type"];

    let message = adminDataPacket["message"];
    let opcode = message["opcode"];
    // console.log (message)
    switch (msgType) {
      case "admin-update": {
        switch (opcode) {
          case "user-access": {
            if (user?.username !== message["username"]) {
              return;
            }
            let groups = JSON.parse(message["assigned"]);
            setMonitorGroupList(groups);
            break;
          }

          case "group-update": {
            // Update the list of devices for this monitor group.
            var updatedMonitorGroupList = monitorGroupList;
            for (var i = 0; i < updatedMonitorGroupList.length; i++) {
              let monGroup = updatedMonitorGroupList[i];
              if (monGroup.group_id === message.group_id) {
                monGroup.devices = message.devices;

                // /Force a screen update ifithis group is currently visible.
                if (selectedVICU?.group_id === message.group_id) {
                  setVisibleDevices(monGroup.devices);
                }
              }
            }
            setMonitorGroupList(updatedMonitorGroupList);

            break;
          }
        } // switch opcode

        break;
      } // switch message type

      default: {
        console.log("Admin data packet:", adminDataPacket);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminDataPacket]);

  // Set up Admin socket for general commands
  useAdminSocket({ setAdminDataPacket });

  useAlarmMessage();

  if (loading || systemTypeLoading || monitorGroupLoading) {
    return <Loading />;
  }

  if (error || systemTypeError || monitorGroupError) {
    return <ServerProblem />;
  }
  if (!context) {
    // Handle the error, maybe return a loading state or null
    return null;
  }

  const { user } = context;
  console.log("User is ", user);
  //console.log("Device list:", devices);
  // console.log ("VICU list:", vicu_list)

  if (user?.is_superuser) {
    return <SysAdmin />;
  }
  if (systemType && systemType["system_type"] === "PHARLAP") {
    return <LiveDashboard devicelist={devices} />;
  }

  // Create the base arrays
  const tabNames = [
    t("live_dashboard"),
    t("news_dashboard"),
    t("trend_dashboard"),
    t("standard_dashboard"),
    t("mapview"),
  ];

  const tabComponents = [
    <LiveDashboard devicelist={visibleDevices}>
      <VicuTabBar
        vicuList={monitorGroupList?.filter(
          (item: any) => item.group_name !== "syspool"
        )}
        activateVicu={setSelectedVICU}
        activeVicu={selectedVICU}
        refreshDevices={updateDeviceList}
      />
    </LiveDashboard>,
    <NEWSTable devicelist={visibleDevices}>
      <VicuTabBar
        vicuList={monitorGroupList?.filter(
          (item: any) => item.group_name !== "syspool"
        )}
        activateVicu={setSelectedVICU}
        activeVicu={selectedVICU}
        refreshDevices={updateDeviceList}
      />
    </NEWSTable>,
    <TrendDashboard devices={visibleDevices}>
      <VicuTabBar
        vicuList={monitorGroupList?.filter(
          (item: any) => item.group_name !== "syspool"
        )}
        activateVicu={setSelectedVICU}
        activeVicu={selectedVICU}
        refreshDevices={updateDeviceList}
      />
    </TrendDashboard>,
    <StandardView devicelist={visibleDevices} setDevices={setDevices}>
      <VicuTabBar
        vicuList={monitorGroupList?.filter(
          (item: any) => item.group_name !== "syspool"
        )}
        activateVicu={setSelectedVICU}
        activeVicu={selectedVICU}
        refreshDevices={updateDeviceList}
      />
    </StandardView>,
    <MapView devices={visibleDevices} setDevices={setDevices}>
      <VicuTabBar
        vicuList={monitorGroupList?.filter(
          (item: any) => item.group_name !== "syspool"
        )}
        activateVicu={setSelectedVICU}
        activeVicu={selectedVICU}
        refreshDevices={updateDeviceList}
      />
    </MapView>,

    <SysPoolView devicelist={visibleDevices} setDevices={setDevices}>
      <VicuTabBar
        vicuList={monitorGroupList}
        activateVicu={setSelectedVICU}
        activeVicu={selectedVICU}
        refreshDevices={updateDeviceList}
      />
    </SysPoolView>,
  ];

  // If user is an admin, add the SysPoolView related items
  if (user?.is_admin) {
    tabNames.push(t("manage groups"));

    tabComponents.push(
      <SysPoolView devicelist={visibleDevices} setDevices={setDevices}>
        <VicuTabBar
          vicuList={monitorGroupList}
          activateVicu={setSelectedVICU}
          activeVicu={selectedVICU}
          refreshDevices={updateDeviceList}
        />
      </SysPoolView>
    );
  }

  return (
    <div>
      <CustomTabs
        tabNames={tabNames}
        tabComponents={tabComponents}
        value={currentTab}
        onTabChange={setCurrentTab}
      />
    </div>
  );
};

interface VicuTabBarProps {
  vicuList: VICU[];
  activateVicu: any;
  activeVicu?: VICU | null;
  refreshDevices: any;
}

export const VicuTabBar = ({
  vicuList,
  activateVicu,
  activeVicu,
  refreshDevices,
}: VicuTabBarProps) => {
  return (
    <>
      {/* VICU list */}
      <Grid container direction="row" sx={{ paddingBottom: 1 }}>
        {vicuList?.map((vicu: VICU) => (
          <VicuTab
            key={vicu.group_id}
            vicu={vicu}
            activateVicu={activateVicu}
            active={activeVicu === vicu}
            activeGroup={activeVicu}
            refreshDevices={refreshDevices}
          />
        ))}
      </Grid>
    </>
  );
};

interface VicuTabProps {
  vicu: VICU;
  activateVicu: any;
  active: boolean;
  activeGroup?: VICU | null;
  refreshDevices: any;
}

export const VicuTab = ({
  vicu,
  activateVicu,
  active,
  activeGroup,
  refreshDevices,
}: VicuTabProps) => {
  const [cookies, ,] = useCookies(["access_token"]);
  const selectVICU = (v: VICU) => {
    activateVicu(v);
  };

  /*
   * This hook triggers when a device has been dropped into the Monitor Group.
   * Send a request to the back-end to add it to the monitor goup.
   */

  const [{ isOver }, drop] = useDrop({
    accept: "ITEM",
    drop: (item: any) => {
      // activateVicu(item.id);  // Assuming activateVicu needs the ID of the dropped item
      console.log("Dropping onto!", item.id, vicu, activeGroup);

      // If the activeGroup is the pool group, it means the device should be removed from the current group.
      if (vicu.group_name === "syspool") {
        console.log("Removing device!");
        VICUService.manageGroupDevices(
          // @ts-ignore
          activeGroup.group_id,
          { type: "detach", devices: [{ device_id: item.id }] },
          cookies.access_token
        )
          .then((res: any) => {
            console.log(res);

            // Also remove the device from the active Group
            refreshDevices();
          })
          .catch((err: any) => {
            console.log(err);
          });
        return;
      } // if syspool

      // We're adding the device to a group.
      VICUService.manageGroupDevices(
        // @ts-ignore
        vicu.group_id,
        { type: "attach", devices: [{ device_id: item.id }] },
        cookies.access_token
      )
        .then((res: any) => {
          console.log(res);
          refreshDevices();
        })
        .catch((err: any) => {
          console.log(err);
        });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} style={{ backgroundColor: isOver ? "lightgray" : "white" }}>
      <Button
        variant={active ? "contained" : "outlined"}
        sx={{
          marginRight: 1,
          color: vicu.vicu_type === "pool" ? "darkorange" : undefined,
        }}
        onClick={(event: any) => selectVICU(vicu)}
      >
        {vicu.group_name}
      </Button>
    </div>
  );
};
