import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Vital } from "../../interfaces/vitals/Vital";

interface Props {
  parameter: Vital;
  connectStatus: string;
}

export const DeviceAlarm = ({ parameter, connectStatus }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid>
      {parameter?.active_alarm && connectStatus === "connected" && (
        <Grid>
          <Typography variant="body2" color={theme.palette.error.main}>
            {parameter?.param_name} {t("out_of_range")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
