import { createContext, useMemo, useState } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

export const ThemeContext = createContext({
  toggleColorMode: () => {},
});

export default function ThemeContextProvider({ children }: any) {
  // @ts-ignore
  let color = localStorage.getItem("mode");
  // @ts-ignore
  const [mode, setMode] = useState<"light" | "dark">(color || "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  let theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light" && {
            primary: {
              main: "#416BA9", // Infiniwell blue
            },

            text: {
              primary: "rgba(0, 0, 0, .87)", // Black
              secondary: "rgba(255, 255, 255, 1)", // White
              disabled: "rgba(0, 0, 0, 0.38)", // Grey
            },
            background: {
              default: "#F9F9F9",
            },
            error: {
              main: "#FF4433", // Red NEWS
              light: "rgb(255, 240, 167)", // Yellow NEWS
              dark: "rgb(245, 196, 134)", // Orange NEWS
            },
            info: {
              main: "#28a745",
              dark: "#F5F5F5",
              light: "#ffffff",
            },
          }),
          ...(mode === "dark" && {
            primary: {
              main: "#3FA8F3", // Infiniwell blue secondary
              light: "#222222",
              dark: "#dddddd",
            },
            text: {
              primary: "rgba(255, 255, 255, 1)", // White
              secondary: "rgba(195, 195, 195, .87)", // Black
              disabled: "rgba(255, 255, 255, 0.38)",
            },
            background: {
              default: "#424242",
            },
            error: {
              main: "#F29983", // Red NEWS
              light: "#FDF2B0", // Yellow NEWS
              dark: "#FCCD8C", // Orange NEWS
            },
            info: {
              main: "#28a745",
              dark: "#141414",
              light: "#343434",
            },
          }),
        },
        breakpoints: {
          values: { xs: 350, sm: 650, md: 900, lg: 1536, xl: 1836 },
        },
        typography: {
          fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
      }),
    [mode]
  );
  theme = responsiveFontSizes(theme);
  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
