import { Device } from "../../../interfaces/Device";
import { MapContainer, TileLayer } from "react-leaflet";
import DeviceMarker from "./DeviceMarker";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { isBrowser, isMobile } from "react-device-detect";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  devices: Device[];
  setDevices: (devices: Device[]) => void;
  children: ReactNode;
}
export const MapView = ({ devices, setDevices, children }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [position, setPosition] = useState<any>(null);
  const [mappedDevices, setMappedDevices] = useState<string[]>([]);

  useEffect(() => {
    let isMounted = true;
    const arr: string[] = devices?.map((device: Device) => {
      return device.device_id;
    });
    if (isMounted) setMappedDevices(arr);
    return () => {
      isMounted = false;
    };
  }, [devices]);

  useEffect(() => {
    const successCallback = (position: any) => {
      setPosition([position.coords.latitude, position.coords.longitude]);
    };
    const errorCallback = (error: any) => {
      setPosition([63, 10]);
    };

    if (navigator.geolocation && !position) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }

    const timer = setTimeout(() => {
      setPosition([63, 10]);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [position]);

  const filterDevices = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    //console.log ("filterDevices", event.target.id, event.target.checked)
    const device_id: string = event.target.id;
    //        const checked   = event.target.checked;

    const tmp = mappedDevices;
    if (checked) {
      if (!mappedDevices.includes(device_id)) {
        tmp.push(device_id);
        setMappedDevices(tmp);
      }
    } else {
      const index = mappedDevices.indexOf(device_id, 0);
      if (index > -1) {
        mappedDevices.splice(index, 1);
      }
    }
    //console.log (mappedDevices)
  };
  if (!position) return <Loading />;

  return (
    <>
      {children}
      {devices?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: isBrowser ? "row" : "column",
          }}
        >
          <Box>
            <Typography variant="h6" color={theme.palette.text.primary}>
              {t("select_devices")}
            </Typography>

            <FormGroup row={isMobile}>
              {devices?.map((device: Device) => (
                <FormControlLabel
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  key={device.device_id}
                  control={
                    <Checkbox
                      defaultChecked
                      id={device.device_id}
                      onChange={filterDevices}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {device.nickname ? device.nickname : device.device_id}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </Box>

          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default" }}
          >
            <MapContainer
              style={{ height: "70vh" }}
              zoom={14}
              center={position}
              scrollWheelZoom={true}
              closePopupOnClick={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.jawg.io/1a3bb38c-fc14-4a39-91b2-9bf3738d0850/{z}/{x}/{y}{r}.png?access-token=cl4DjZDEwMUCIKkxRgdeFZb4MaERLc8degcELVw9BcapTstY1jN1rlLIrHJHW2w5"
              />

              {devices?.map((device: Device) => {
                return device.device_id &&
                  mappedDevices.includes(device.device_id) ? (
                  <DeviceMarker
                    key={device.device_id}
                    device={device}
                    setDevices={setDevices}
                  />
                ) : null;
              })}
            </MapContainer>
          </Box>
        </Box>
      ) : (
        <Typography>No devices to show</Typography>
      )}
    </>
  );
};
