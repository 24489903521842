import {
  Grid,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { IngestionServer } from "../../interfaces/IngestionServer";

import "../VicuManagement/VicuManagement.css";
import IngestionServerService from "../../services/IngestionServerService";

// Custom styling for the switch
const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    // Default (unchecked) state
    color: "blue", // Replace with your preferred color for the thumb
    "& + .MuiSwitch-track": {
      backgroundColor: "grey", // Replace with your preferred color for the track
    },
    // Checked state
    "&.Mui-checked": {
      color: "blue", // Replace with your preferred color when checked for the thumb
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "grey", // Replace with your preferred color when checked for the track
    },
  },
}));

interface Props {
  targetServer?: IngestionServer;
  openState: boolean;
  setOpenState: (show: boolean) => void;
  dialogMode: string;
}

export const IngestionServerDialog = ({
  targetServer,
  openState,
  setOpenState,
  dialogMode,
}: Props) => {
  //console.log ("IngestionServerDialog: targetServer = ", targetServer)

  const { t } = useTranslation();
  const [cookies, ,] = useCookies(["access_token"]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [serverName, setServerName] = useState<string>(
    targetServer ? targetServer.name : ""
  );
  const [serverNameChanged, setServerNameChanged] = useState(false);

  const [serverId, setServerId] = useState<string>(
    targetServer ? targetServer.server_id : ""
  );
  const [serverIdChanged, setServerIdChanged] = useState(false);

  const [ingestionServer, setIngestionServer] = useState<string>(
    targetServer ? targetServer?.server : ""
  );
  const [ingestionServerChanged, setIngestionServerChanged] = useState(false);

  const [ingestionKey, setIngestionKey] = useState<string>(
    targetServer ? targetServer?.key : ""
  );
  const [ingestionKeyChanged, setIngestionKeyChanged] = useState(false);

  //const [enabled, setenabled] = useState <boolean> (targetServer ? targetServer.enabled : false)
  //const [enabledChanged, setEnabledhanged] = useState (false);

  const [serverType, setServerType] = useState<string>(
    targetServer ? targetServer.serverType : "NATIVE_KAFKA"
  );
  const [serverTypeChanged, setServerTypeChanged] = useState(false);

  const theme = useTheme();

  const [isKafka, setIsKafka] = useState(
    targetServer?.serverType !== "AZURE_EVENT_HUB"
  ); // true for Kafka, false for EventHub

  const handleClose = () => {
    setOpenState(false);
    window.location.href = "/sysadmin";
  };

  const handleSaveChanges = () => {
    console.log("Handle save changes:", dialogMode);

    if (dialogMode === "add") {
      const data = {
        name: serverName,
        server_id: serverId,
        server: ingestionServer,
        key: ingestionKey,
        enabled: true,
        serverType: serverType,
      };

      // Create a new organization
      IngestionServerService.addIngestionServer(data, cookies.access_token)
        .then((response) => {
          console.log("Success:", response);
          setShowSaveButton(false);
          setServerNameChanged(false);
          setOpenState(false);
          window.location.href = "/sysadmin";
        })
        .catch((errorResponse) => {
          console.log("Error:", errorResponse);
          console.log("HTTP error code:", errorResponse.status);
          if (errorResponse.status === 409) {
            alert(
              "An ingestion server with id " + serverId + " already exists"
            );
          }
        });

      return;
    } // if "add"

    // We have an EDIT
    if (!targetServer) {
      return;
    }

    const data = {
      name: serverName,
      server_id: serverId,
      server: ingestionServer,
      key: ingestionKey,
      enabled: true,
      serverType: serverType,
    };

    console.log("EDIT:", data);
    alert("ok");
    IngestionServerService.editIngestionServer(data, cookies.access_token)
      .then((res: any) => {
        //console.log("result:", res);
        targetServer.name = serverName;
        setShowSaveButton(false);
        setServerNameChanged(false);
        window.location.href = "/sysadmin";
      })
      .catch((err: any) => {
        console.log(err);
      });
  }; // handleSaveChanges

  useEffect(() => {
    var showSave = false;
    switch (dialogMode) {
      case "edit": {
        showSave =
          serverIdChanged ||
          serverIdChanged ||
          ingestionKeyChanged ||
          serverTypeChanged;
        break;
      }

      case "add": {
        showSave =
          serverName !== "" &&
          serverId !== "" &&
          ingestionServer !== "" &&
          ingestionKey !== "" &&
          serverType !== "";
        break;
      }
    }
    setShowSaveButton(showSave);
  }, [
    dialogMode,
    serverName,
    serverId,
    ingestionServer,
    ingestionKey,
    serverType,
    serverNameChanged,
    serverIdChanged,
    ingestionServerChanged,
    ingestionKeyChanged,
    serverTypeChanged,
  ]);

  const deleteServer = () => {
    setConfirmDelete(true);
  };

  const cancelDelete = () => {
    setConfirmDelete(false);
  };

  const yesDelete = () => {
    // Really delete the VICU. Send a request to the back-end

    if (!targetServer) {
      return;
    }

    const data = {
      server_id: targetServer.server_id,
    };
    IngestionServerService.deleteIngestionServer(data, cookies.access_token)
      .then((res: any) => {
        setConfirmDelete(false);
        window.location.href = "/sysadmin";
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleItemChange = (id: string, data: any) => {
    // eslint-disable-next-line
    const urlPattern =
      /^((https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?([\/\w.-]*)*\/?)?$/;
    // eslint-disable-next-line
    const addressPattern =
      /^(https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?(:\d{0,5})?([\/\w.-]*)*\/?$/;

    const stringPattern = /^[a-zA-Z0-9_-]*$/;
    switch (id) {
      case "serverId": {
        const value: string = data.trim();
        if (stringPattern.test(value) || value === "") {
          setServerId(value);
        }

        setServerIdChanged(value !== targetServer?.server_id);
        break;
      }

      case "serverName": {
        const val: string = data;
        setServerName(val);
        setServerNameChanged(val !== targetServer?.name);
        break;
      }

      case "serverType": {
        const selected: boolean = data; // true == AZURE_EVENT_HUB
        console.log("Switch value:", data);
        setIsKafka(!selected);
        const server_type: string = selected
          ? "AZURE_EVENT_HUB"
          : "NATIVE_KAFKA";
        console.log(
          "Server type change from ",
          targetServer?.serverType,
          "to ",
          server_type
        );

        setServerType(server_type);
        setServerTypeChanged(server_type !== targetServer?.serverType);
        break;
      }

      case "ingestionServer": {
        const value: string = data.trim();
        if (addressPattern.test(value) || value === "") {
          setIngestionServer(value);
        }
        setIngestionServerChanged(value !== targetServer?.server);
        break;
      }

      case "ingestionKey": {
        const val: string = data.trim();
        setIngestionKey(val);
        setIngestionKeyChanged(val !== targetServer?.key);
        break;
      }
    } // switch
  }; // handleItemChange

  const showDeleteButton = dialogMode !== "add";
  //console.log ("Org info:", orgInfo)

  return (
    <>
      <Dialog open={confirmDelete}>
        <DialogTitle> {t("confirm_server_delete")} </DialogTitle>
        <DialogContent>
          <Typography>{t("delete_server_notification")}</Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="outlined" onClick={cancelDelete}>
              {" "}
              {t("cancel")}
            </Button>
            <Button variant="contained" onClick={yesDelete} color="warning">
              {" "}
              {t("yes_delete")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog open={openState} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs>
              <Box>{t("server_administration")}</Box>
            </Grid>

            <Grid item>
              <TextField
                label={t("server_id")}
                id="server_id"
                type=""
                value={serverId}
                onChange={(e) => handleItemChange("serverId", e.target.value)}
                disabled={targetServer !== undefined}
                InputLabelProps={{ shrink: true }}
                placeholder={t("enter_unique_id")}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background:
                      !serverIdChanged || !targetServer
                        ? theme.palette.background.default
                        : theme.palette.warning.light,
                  },
                  label: { color: theme.palette.text.primary },
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container item pt={3} mb={1} spacing={2}>
            {/* First Row */}
            <Grid item xs={12}>
              <Grid container direction="row" columnSpacing={1}>
                <Grid item xs={8}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={t("server_name")}
                    id="server_name"
                    value={serverName}
                    placeholder={t("decriptive_name")}
                    onChange={(e) =>
                      handleItemChange("serverName", e.target.value)
                    }
                    sx={{
                      input: {
                        color: theme.palette.text.primary,
                        background:
                          !serverNameChanged || !targetServer
                            ? theme.palette.background.default
                            : theme.palette.warning.light,
                      },
                      label: { color: theme.palette.text.primary },
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>{t("native_kafka")}</Typography>
                </Grid>
                <Grid item>
                  <CustomSwitch
                    checked={!isKafka}
                    onChange={(e) =>
                      handleItemChange("serverType", e.target.checked)
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography>{t("azure_eventhub")}</Typography>
                </Grid>
              </Grid>
            </Grid>{" "}
            {/* Row Container */}
            {/* Second Row */}
            {/* Fifth Row */}
            <Grid item xs={12}>
              <Grid container direction="row" columnSpacing={1}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={t("ingestion_server")}
                    id="ingestion_server"
                    value={ingestionServer}
                    placeholder={t("kafka_server_address")}
                    onChange={(e) =>
                      handleItemChange("ingestionServer", e.target.value)
                    }
                    sx={{
                      input: {
                        color: theme.palette.text.primary,
                        background:
                          !ingestionServerChanged || !targetServer
                            ? theme.palette.background.default
                            : theme.palette.warning.light,
                      },
                      label: { color: theme.palette.text.primary },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>{" "}
            {/* Sixth Row Container */}
            {/* Sixth Row */}
            <Grid item xs={12}>
              <Grid container direction="row" columnSpacing={1}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    label={t("ingestion_key")}
                    id="ingestion_key"
                    value={ingestionKey}
                    placeholder={t("kafka_secret_key")}
                    onChange={(e) =>
                      handleItemChange("ingestionKey", e.target.value)
                    }
                    sx={{
                      "& input, & textarea": {
                        color: theme.palette.text.primary,
                        background:
                          !ingestionKeyChanged || !targetServer
                            ? theme.palette.background.default
                            : theme.palette.warning.light,
                      },
                      label: { color: theme.palette.text.primary },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>{" "}
            {/* Seventh Row Container */}
          </Grid>{" "}
          {/* Outer container */}
        </DialogContent>

        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="outlined" onClick={handleClose}>
              {" "}
              {t("close")}
            </Button>
            {showSaveButton && (
              <Button
                onClick={handleSaveChanges}
                variant="outlined"
                sx={{ background: theme.palette.warning.light }}
              >
                {t("save_changes")}
              </Button>
            )}

            {showDeleteButton && (
              <Button variant="outlined" onClick={deleteServer}>
                {" "}
                {t("delete_server")}
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ItemProps {
  title: any;
  content: any;
}

export const CardItem = ({ title, content }: ItemProps) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        {" "}
        <Typography>{title} </Typography>{" "}
      </Grid>
      <Grid item xs={6}>
        {" "}
        <Typography>{content}</Typography>{" "}
      </Grid>
    </Grid>
  );
};
