import { TableRow, TableCell, Typography, useTheme, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import useVitalsSocket from "../../../hooks/useVitalsSocket";
import { Device } from "../../../interfaces/Device";
import { GetColorNEWS } from "../../../utils/functions/GetColorNEWS";
import { GetColorParamNEWS } from "../../../utils/functions/GetColorParamNEWS";
import { useEffect } from "react";
import { ConditionalLink } from "../../../components/ConditionalLink";
import { isBrowser, isMobile } from "react-device-detect";

interface Props {
  device: Device;
  setNEWS: (device_id: string, score: number) => void;
}

export const NEWSRow = ({ device, setNEWS }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { vitals, news, patientInfo, connectStatus } = useVitalsSocket({
    device,
  });

  const fontVariant = isBrowser ? "body2" : "caption";

  useEffect(() => {
    var score = 0;
    let isMounted = true;
    if (news?.score) {
      score = news.score;
    }
    if (isMounted) {
      setNEWS(device.device_id, score);
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news?.score]);

  const checkValue = (num: string | null | undefined) => {
    if (num) {
      return num;
    }
    return "--";
  };

  const displayParam = (paramNEWS?: number, paramsVitals?: string) => {
    return (
      <TableCell
        sx={{
          padding: "6px 6px",
        }}
      >
        <Grid container>
          <Typography
            variant={fontVariant}
            sx={{
              borderRadius: "3px",
              padding: "1px",
              border: "solid 1px " + GetColorParamNEWS(paramNEWS),
            }}
          >
            {checkValue(paramsVitals)}
          </Typography>
        </Grid>
      </TableCell>
    );
  };
  const monitorLink: string = "/wavemonitor/" + device.device_id + "/";

  return (
    <>
      {isMobile && (
        <TableRow
          sx={{
            borderBottom: "hidden",
          }}
        >
          <TableCell
            colSpan={6}
            sx={{
              padding: "0px 6px",
            }}
          >
            <Typography variant="caption" fontWeight="bold">
              {device.nickname ? device.nickname : device.device_id}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      <TableRow
        key={device.device_id}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
          "&:nth-of-type(even)": {
            background: theme.palette.info.dark,
          },
        }}
      >
        {isBrowser && (
          <>
            <TableCell sx={{ padding: 1 }} component="th" scope="row">
              {patientInfo?.first_name} {patientInfo?.last_name}
            </TableCell>
            <TableCell>
              <ConditionalLink show={true} to={monitorLink}>
                {device.nickname ? device.nickname : device.device_id}
              </ConditionalLink>
            </TableCell>
            <TableCell sx={{ padding: 1 }}>
              <Typography
                variant="body2"
                color={
                  connectStatus !== "connected"
                    ? theme.palette.error.main
                    : theme.palette.success.main
                }
              >
                {connectStatus !== "connected" ? t("no_data") : t("streaming")}
              </Typography>
            </TableCell>
          </>
        )}
        {displayParam(news?.pulse, vitals?.HR?.value)}
        {displayParam(news?.spo2, vitals?.SpO2?.sp?.value)}
        {displayParam(news?.temperature, vitals?.Temp?.t1?.value)}
        {displayParam(news?.bp, vitals?.IBP1?.systole?.value)}
        {displayParam(news?.rr, vitals?.Resp?.rr?.value)}
        <TableCell sx={{ padding: 1 }}>
          <Typography
            variant={fontVariant}
            sx={{
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: GetColorNEWS(news?.score),
            }}
            color={
              GetColorNEWS(news?.score) !== "initial"
                ? theme.palette.common.black
                : theme.palette.common.white
            }
          >
            {news?.score !== undefined
              ? news?.score + " - " + news?.scoretext
              : "0"}
          </Typography>
        </TableCell>
        {isBrowser && (
          <TableCell sx={{ padding: 1 }}>
            <Typography variant={fontVariant}>
              {news && (news.pulse > 0 ? "Pulse: " + news.pulse : null)}
              {news && (news.spo2 > 0 ? " SpO2: " + news.spo2 : null)}
              {news && news.temperature > 0
                ? " Temp: " + news.temperature
                : null}
              {news && (news.bp > 0 ? " BP: " + news.bp : null)}
              {news && (news.rr > 0 ? " RR: " + news.rr : null)}
              {!news && connectStatus === "connected" && "--"}
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

// {news?.score ? news?.score + " - " + news?.scoretext : "--"}
