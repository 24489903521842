import { Alert, AlertColor, AlertTitle, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  type: AlertColor | undefined;
  message: string;
  open: boolean;
  nextPage?: string | null;
  reload?: boolean;
  startingTab?: number | null;
}

export const AlertMessage = ({
  type,
  message,
  open,
  nextPage,
  reload,
  startingTab,
}: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const history = useHistory();

  useEffect(() => {
    setIsOpen(open);
  }, [open, message]);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
    if (nextPage) {
      history.push({
        pathname: nextPage,
        state: { startingTab: startingTab },
      });
    }

    if (reload) {
      window.location.reload();
    }
  };
  return (
    <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity={type} onClose={handleClose}>
        <AlertTitle>{type?.toUpperCase()}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};
