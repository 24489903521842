import { AlertColor } from "@mui/material";
import { useState } from "react";

export const useAlertMessage = () => {
  const [reload, setReload] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState<AlertColor | undefined>(undefined);
  const [nextPage, setNextPage] = useState<string | null>(null);

  return {
    isOpen,
    setIsOpen,
    message,
    setMessage,
    type,
    setType,
    nextPage,
    setNextPage,
    reload,
    setReload,
  };
};
