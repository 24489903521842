//import {memo} from "react";

import { Grid } from "@mui/material";
import { graphUnitHeightPx } from "./CareSummaryInfo";
import { ScoringBoxLabel } from "./ScoringBoxLabel";
import { ScoringBoxGraph } from "./ScoringBoxGraph";

export interface ScoringBoxProps {
  parm: any[];
  timestamps: number[];
  ranges: any;
  colors: any;
  def?: any;
  min_ts: number;
  max_ts: number;
  current_ts: number;
}

export const ScoringBox = ({
  parm,
  timestamps,
  ranges,
  colors,
  def,
  min_ts,
  max_ts,
  current_ts,
}: ScoringBoxProps) => {
  //console.log ("ScoringBox:", parm, ranges)
  if (!parm || parm.length === 0 || ranges === null) {
    return <></>;
  }
  //console.log ("scoringbox:", ranges, parm.length)
  const min_value = ranges.unitStep.min; // * ranges.unitStep.step;
  const max_value = ranges.unitStep.max; // * ranges.unitStep.step;
  const n_steps = (max_value - min_value) / ranges.unitStep.step; //ranges.unitStep.max - ranges.unitStep.min;
  const height = n_steps * graphUnitHeightPx;

  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      style={{ height: height + 1, marginTop: 3 }}
    >
      <Grid container item xs={2} style={{ border: "1px solid grey" }}>
        <ScoringBoxLabel def={def} ranges={ranges} />
      </Grid>
      <Grid item xs={10} style={{ border: "1px solid grey" }}>
        <ScoringBoxGraph
          parm={parm}
          ranges={ranges}
          timestamps={timestamps}
          colors={colors}
          min_ts={min_ts}
          max_ts={max_ts}
          current_ts={current_ts}
        />
      </Grid>
    </Grid>
  );
};
