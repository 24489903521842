import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useTranslation } from "react-i18next";
import "./AlarmProfile.css";
import { Dispatch, SetStateAction } from "react";
import { MenuButton } from "../../../components/MenuButton";

interface Props {
  profile: any;
  alarms: any;
  isEditingThresholds: boolean;
  setAlarms: any;
  setIsEditingThresholds: Dispatch<SetStateAction<boolean>>;
}

export const AlarmProfile = ({
  profile,
  alarms,
  isEditingThresholds,
  setAlarms,
  setIsEditingThresholds,
}: Props) => {
  //const theme = useTheme();
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    // <div>
    //     <h4>
    //         {t("alarm_profile")} : {profile.headline}{" "}
    //     </h4>
    //     <AlarmProfileHeader />
    //     {alarms.map((alm: any) => {
    //         return <AlarmProfileLine key={alm.code} alm={alm} />;
    //     })}
    // </div>
    <>
      <Typography variant="h5">
        {t("alarm_profile")} : {profile.headline}
      </Typography>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            }}
          >
            <TableRow>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("code")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("parameter")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Critical")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Major")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Minor")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Normal")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Minor")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Major")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Critical")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Alert")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Enable")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" color={theme.palette.common.white}>
                  {t("Edit")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {alarms.map((parameter: any, index: number) => (
              <TableRow key={parameter.code}>
                <TableCell>{parameter.code}</TableCell>
                <TableCell>{parameter.name}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: theme.palette.common.black,
                    backgroundColor: "#E59D88",
                  }}
                >
                  {isEditingThresholds && (
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="space-evenly"
                      margin={0}
                      padding={0}
                    >
                      <Typography>≤</Typography>
                      <TextField
                        sx={{
                          width: 50,
                        }}
                        inputProps={{
                          style: {
                            padding: 0,
                            fontSize: 14,
                            textAlign: "center",
                            color: theme.palette.common.black,
                          },
                        }}
                        defaultValue={parameter?.low.critical}
                        onChange={(e) => {
                          parameter.low.critical =
                            e.target.value === ""
                              ? null
                              : parseInt(e.target.value);
                          setAlarms([...alarms]);
                        }}
                      />
                    </Grid>
                  )}

                  {!isEditingThresholds &&
                    parameter?.low.critical &&
                    "≤ " + parameter.low.critical}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: theme.palette.common.black,
                    backgroundColor: "#F4CF95",
                  }}
                >
                  {isEditingThresholds && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Typography>≤</Typography>
                      <TextField
                        sx={{
                          width: 50,
                        }}
                        inputProps={{
                          style: {
                            color: theme.palette.common.black,
                            fontSize: 14,
                            padding: 0,
                            textAlign: "center",
                          },
                        }}
                        defaultValue={parameter?.low.major}
                        onChange={(e) => {
                          parameter.low.major =
                            e.target.value === ""
                              ? null
                              : parseInt(e.target.value);
                          setAlarms([...alarms]);
                        }}
                      />
                    </Grid>
                  )}
                  {!isEditingThresholds &&
                    parameter.low.major &&
                    "≤ " + parameter.low.major}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: theme.palette.common.black,
                    backgroundColor: "#FCF2B8",
                  }}
                >
                  {isEditingThresholds && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Typography>≤</Typography>
                      <TextField
                        sx={{
                          width: 50,
                        }}
                        inputProps={{
                          style: {
                            color: theme.palette.common.black,
                            fontSize: 14,
                            padding: 0,
                            textAlign: "center",
                          },
                        }}
                        defaultValue={parameter?.low.minor}
                        onChange={(e) => {
                          parameter.low.minor =
                            e.target.value === ""
                              ? null
                              : parseInt(e.target.value);
                          setAlarms([...alarms]);
                        }}
                      />
                    </Grid>
                  )}
                  {!isEditingThresholds &&
                    parameter.low.minor &&
                    "≤ " + parameter.low.minor}
                </TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: theme.palette.common.black,
                    backgroundColor: "#FCF2B8",
                  }}
                >
                  {isEditingThresholds && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Typography>≥</Typography>
                      <TextField
                        sx={{
                          width: 50,
                        }}
                        inputProps={{
                          style: {
                            color: theme.palette.common.black,
                            padding: 0,
                            fontSize: 14,
                            textAlign: "center",
                          },
                        }}
                        defaultValue={parameter?.high.minor}
                        onChange={(e) => {
                          parameter.high.minor =
                            e.target.value === ""
                              ? null
                              : parseInt(e.target.value);
                          setAlarms([...alarms]);
                        }}
                      />
                    </Grid>
                  )}
                  {!isEditingThresholds &&
                    parameter.high.minor &&
                    "≥ " + parameter.high.minor}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: theme.palette.common.black,
                    backgroundColor: "#F4CF95",
                  }}
                >
                  {isEditingThresholds && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Typography>≥</Typography>
                      <TextField
                        sx={{
                          width: 50,
                        }}
                        inputProps={{
                          style: {
                            color: theme.palette.common.black,
                            fontSize: 14,
                            padding: 0,
                            textAlign: "center",
                          },
                        }}
                        defaultValue={parameter?.high.major}
                        onChange={(e) => {
                          parameter.high.major =
                            e.target.value === ""
                              ? null
                              : parseInt(e.target.value);
                          setAlarms([...alarms]);
                        }}
                      />
                    </Grid>
                  )}
                  {!isEditingThresholds &&
                    parameter.high.major &&
                    "≥ " + parameter.high.major}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: theme.palette.common.black,
                    backgroundColor: "#E59D88",
                  }}
                >
                  {isEditingThresholds && (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Typography>≥</Typography>
                      <TextField
                        sx={{
                          width: 60,
                        }}
                        inputProps={{
                          style: {
                            color: theme.palette.common.black,
                            fontSize: 14,
                            padding: 0,
                            textAlign: "center",
                          },
                        }}
                        defaultValue={parameter?.high.critical}
                        onChange={(e) => {
                          parameter.high.critical =
                            e.target.value === ""
                              ? null
                              : parseInt(e.target.value);
                          setAlarms([...alarms]);
                        }}
                      />
                    </Grid>
                  )}
                  {!isEditingThresholds &&
                    parameter.high.critical &&
                    "≥ " + parameter.high.critical}
                </TableCell>
                <TableCell align="center">
                  {parameter.audible ? (
                    <NotificationsIcon
                      sx={{
                        color: "#28a745",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsEditingThresholds(true);
                        parameter.audible = false;
                        setAlarms([...alarms]);
                      }}
                    />
                  ) : (
                    <NotificationsOffIcon
                      sx={{
                        color: "#dc3545",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsEditingThresholds(true);
                        parameter.audible = true;
                        setAlarms([...alarms]);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={parameter.enable}
                    sx={{
                      color: parameter.enable
                        ? theme.palette.common.white
                        : theme.palette.common.black,
                    }}
                    onChange={(e) => {
                      setIsEditingThresholds(true);
                      parameter.enable = e.target.checked;
                      setAlarms([...alarms]);
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  key={parameter.code}
                  id={`tablecell-${parameter.code}`}
                >
                  <MenuButton
                    parameter={parameter}
                    alarms={alarms}
                    setAlarms={setAlarms}
                    setIsEditingThresholds={setIsEditingThresholds}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const AlarmProfileHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const bs = {
    border: 0,
    bgcolor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: 1,
    paddingBottom: 1,
  };
  return (
    <Grid container direction="row" sx={bs}>
      <Grid item width={150}>
        <Typography variant="h5">{t("code")}</Typography>
      </Grid>
      <Grid item width={300}>
        <Typography variant="h5">{t("parameter")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Critical")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Major")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Minor")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Normal")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Minor")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Major")}</Typography>
      </Grid>
      <Grid item width={80}>
        <Typography variant="h5">{t("Critical")}</Typography>
      </Grid>
    </Grid>
  );
};

export const Gte = () => {
  return <span>&#8804;</span>;
};

export const Lte = () => {
  return <span>&#8805;</span>;
};

export const AlarmProfileLine = (alm: any) => {
  const alm_low_cr = alm.alm.low.critical !== "";
  const alm_low_mj = alm.alm.low.major !== "";
  const alm_low_mn = alm.alm.low.minor !== "";

  const alm_high_cr = alm.alm.high.critical !== "";
  const alm_high_mj = alm.alm.high.major !== "";
  const alm_high_mn = alm.alm.high.minor !== "";

  const theme = useTheme();

  return (
    <Grid container direction="row" height={30} alignItems="center">
      <Grid item className="AlmProfileCode">
        {alm.alm.code}
      </Grid>
      <Grid item className="AlmProfileName">
        {alm.alm.name}
      </Grid>
      <Grid
        item
        className="AlmProfileCritical"
        sx={{ color: theme.palette.common.black }}
      >
        {alm_low_cr ? <Gte /> : ""} {alm.alm.low.critical}
      </Grid>
      <Grid
        item
        className="AlmProfileMajor"
        sx={{ color: theme.palette.common.black }}
      >
        {alm_low_mj ? <Gte /> : ""} {alm.alm.low.major}
      </Grid>
      <Grid
        item
        className="AlmProfileMinor"
        sx={{ color: theme.palette.common.black }}
      >
        {alm_low_mn ? <Gte /> : ""} {alm.alm.low.minor}
      </Grid>
      <Grid
        item
        className="AlmProfileNormal"
        sx={{ color: theme.palette.common.black }}
      >
        -
      </Grid>
      <Grid
        item
        className="AlmProfileMinor"
        sx={{ color: theme.palette.common.black }}
      >
        {alm_high_mn ? <Lte /> : ""} {alm.alm.high.minor}
      </Grid>
      <Grid
        item
        className="AlmProfileMajor"
        sx={{ color: theme.palette.common.black }}
      >
        {alm_high_mj ? <Lte /> : ""} {alm.alm.high.major}
      </Grid>
      <Grid
        item
        className="AlmProfileCritical"
        sx={{ color: theme.palette.common.black }}
      >
        {alm_high_cr ? <Lte /> : ""} {alm.alm.high.critical}
      </Grid>
    </Grid>
  );
};
