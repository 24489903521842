// src/DeviceCard.tsx

import React, { useRef, useEffect } from "react";
import { Device } from "../interfaces/Device";
import { Grid, Typography } from "@mui/material";
import { useDrag } from "react-dnd";

interface DeviceCardProps {
  device: Device;
}

const DeviceCard: React.FC<DeviceCardProps> = ({ device }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drag, preview] = useDrag({
    type: "ITEM",
    item: { id: device.device_id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const deviceCardStyle: React.CSSProperties = {
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid black",
    padding: "20px",
    textAlign: "center",
    fontSize: "1.0em",
    height: "3.0em",
  };

  useEffect(() => {
    if (ref.current) {
      const node = ref.current;
      const rect = node.getBoundingClientRect();

      const dragPreviewCanvas = document.createElement("canvas");
      dragPreviewCanvas.width = rect.width;
      dragPreviewCanvas.height = rect.height;
      const ctx = dragPreviewCanvas.getContext("2d");

      if (ctx) {
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        ctx.fillRect(0, 0, rect.width, rect.height);
        ctx.font = "2.2em Arial";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(device.device_id, rect.width / 2, rect.height / 2);
      }

      const img = new Image();
      img.src = dragPreviewCanvas.toDataURL();
      img.onload = () => {
        preview(img);
      };
    }
  }, [preview, device]);

  return (
    <Grid item xs={12} sm={2}>
      <div ref={drag}>
        <div style={deviceCardStyle} ref={ref}>
          <Typography variant="h6">{device.device_id}</Typography>
          <Typography variant="subtitle1">{device.nickname}</Typography>
        </div>
      </div>
    </Grid>
  );
};

export default DeviceCard;
