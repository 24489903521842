import { memo } from "react";
import { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { GRAPH_MARGIN } from "./CareSummaryInfo";

// TimelineBox //

export interface TimelineProps {
  children: any;
  min_ts: number;
  max_ts: number;
}

export const TimelineBox = ({ min_ts, max_ts, children }: TimelineProps) => {
  //console.log ("Init TimelineBox:", min_ts, max_ts)
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Draw dates

  const start_date = new Date(min_ts);

  // Draw hours
  useEffect(() => {
    // Initialize canvas
    if (!canvasRef.current) {
      return;
    }
    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) {
      return;
    }
    ctx.canvas.width = canvasRef?.current?.parentElement?.offsetWidth || 0;
    ctx.canvas.height = 55; //canvasRef?.current?.parentElement?.offsetHeight || 0;

    // Establish the mapping from timespan to pixels

    const n_pixels = ctx.canvas.width;
    const n_ms = max_ts - min_ts;
    const px_per_ms = (n_pixels - GRAPH_MARGIN) / n_ms;

    var boundary_date = new Date(start_date.setHours(0, 0, 0, 0));
    var boundary_ts = boundary_date.getTime();

    const monthStr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Draw days and boundaries (e.g. "Oct 19")
    while (boundary_ts < max_ts) {
      // Draw a line on the date boundary

      const ts_offset = boundary_ts - min_ts;
      const x_px = ts_offset * px_per_ms + GRAPH_MARGIN;
      ctx.fillStyle = "black";
      ctx.beginPath();
      ctx.moveTo(x_px, 0);
      ctx.lineTo(x_px, 26);
      ctx.stroke();

      // Show the date day/month
      ctx.font = "14px Arial";
      ctx.fillStyle = "#000000";
      ctx.textAlign = "left";
      const datestr =
        monthStr[boundary_date.getMonth()] + " " + boundary_date.getDate();
      const text_px = ts_offset < 0 ? GRAPH_MARGIN : x_px + GRAPH_MARGIN;
      ctx.fillText(datestr, text_px, 20);

      boundary_date.setDate(boundary_date.getDate() + 1);
      boundary_ts = boundary_date.getTime();
    }

    // Draw the chart start time
    ctx.font = "10px Arial";
    ctx.fillStyle = "#000000";
    const startTime = new Date(min_ts);
    const start_hour = startTime.getHours();
    const hour_str = start_hour < 10 ? "0" + start_hour : start_hour;
    const start_minute = startTime.getMinutes();
    const minutes_str = start_minute < 10 ? "0" + start_minute : start_minute;
    const start_str = hour_str + ":" + minutes_str;
    ctx.textAlign = "center";
    ctx.fillText(start_str, GRAPH_MARGIN, 40);
    ctx.beginPath();
    ctx.moveTo(GRAPH_MARGIN, 42);
    ctx.lineTo(GRAPH_MARGIN, ctx.canvas.height);
    ctx.stroke();

    // Draw hours and half-hours
    const hourDate = new Date(min_ts);
    hourDate.setMinutes(0, 0, 0);
    var hourDate_ts = hourDate.getTime();
    const hour_interval_px = 60 * 60 * 1000 * px_per_ms;
    const hour_postfix = hour_interval_px > 60 ? ":00" : "";
    const hour30_postfix = hour_interval_px > 60 ? ":30" : "";
    const HOUR_TEXT_START = 95;

    //var first = false;
    while (hourDate_ts < max_ts) {
      const ts_offset = hourDate_ts - min_ts;
      const x_px = ts_offset * px_per_ms + GRAPH_MARGIN;
      const hour = hourDate.getHours();
      if (x_px > HOUR_TEXT_START) {
        // Show the hours
        if (x_px > 0) {
          ctx.beginPath();
          ctx.moveTo(x_px, 42);
          ctx.lineTo(x_px, ctx.canvas.height);
          ctx.stroke();

          ctx.textAlign = "center";
          const hourstr: string =
            hour < 10
              ? "0" + hour.toString() + hour_postfix
              : hour.toString() + hour_postfix;
          const text_px = x_px;
          ctx.fillText(hourstr, text_px, 40);
        }
      }

      const ts_30 = ts_offset + 1000 * 60 * 30;
      const x_px_30 = ts_30 * px_per_ms + GRAPH_MARGIN;
      if (x_px_30 > HOUR_TEXT_START) {
        // Show half-hour, if there is room
        if (hour30_postfix !== "") {
          if (x_px_30 > 0) {
            ctx.beginPath();
            ctx.moveTo(x_px_30, 42);
            ctx.lineTo(x_px_30, ctx.canvas.height);
            ctx.stroke();

            const hour30_str =
              hour < 10
                ? "0" + hour.toString() + hour30_postfix
                : hour.toString() + hour30_postfix;
            ctx.textAlign = "center";
            ctx.fillText(hour30_str, x_px_30, 40);
          }
        } // if 30-minute
      }
      hourDate.setHours(hourDate.getHours() + 1);
      hourDate_ts = hourDate.getTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, max_ts]);

  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      style={{ height: 64, marginTop: 10 }}
    >
      <Grid item xs={2} container direction="row" alignItems="flex-end">
        {children}
      </Grid>
      <Grid item xs={10} style={{ border: "1px solid grey" }}>
        <canvas ref={canvasRef}></canvas>
      </Grid>
    </Grid>
  );
};
export const TimelineBoxMemo = memo(TimelineBox);
