// http://www.petecorey.com/blog/2019/08/19/animating-a-canvas-with-react-hooks/

import "./LiveUnit.css";
import { Grid, GridSize } from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import { LiveUnitHeader } from "../../../components/device/LiveUnitHeader";
import { Device } from "../../../interfaces/Device";
import { Waveform } from "../../../interfaces/vitals/Waveform";
import { UserContext } from "../../../context/UserContext";
import "../../Waveforms/Waveforms.css";
import { GetSocketName } from "../../../utils/functions/GetSocketName";
import { LiveUnitWaveforms } from "./LiveWaveforms";
import SelectParameters from "../../../components/device/SelectParameters";
import * as CONSTANTS from "../../../utils/constants/constants";
import { useTranslation } from "react-i18next";
import UpdateVitals from "../../../utils/functions/UpdateVitals";
import { PatientVitals } from "../../../interfaces/vitals/PatientVitals";
import { AlarmsContext } from "../../../context/AlarmsContext";
import { isBrowser } from "react-device-detect";
import { isMobile } from "react-device-detect";
import { useExternalScript } from "../../../hooks/useExternalScript";
import { toast } from "react-toastify";
import { Event } from "../../WaveMonitor";

const SOCKET_OPEN = 1;

export interface LiveUnitProps {
  device: Device;
  availableWaveforms: string[];
  availableParameters: string[];
  width?: GridSize;
  type?: string;
  waveTitle?: boolean;
  header: boolean;
  minHeight: number;
  parameterBox: boolean;
  useLink?: boolean;
  showWaveform?: boolean;
  hideCamera?: boolean;
  addEvent?: (event: Event) => void;
  alarmProp?: any;
}

export interface CanvasDims {
  width: number;
  height: number;
  maxHeight?: number;
  minHeight?: number;
}

interface LiveDataPacket {
  parameters: PatientVitals;
  waveforms: Waveform[];
  patientInfo: PatientInfo;
}

export interface PatientInfo {
  first_name?: string;
  last_name?: string;
  age?: string;
  gender?: string;
  id?: string;
  hospital?: string;
  bed_no?: string;
  category?: string; // Adult, Child, Neonate, Unknown
}

// ----------------------------------------------------
//
// LiveUnit
//
// This component displays live data for a monitor.
//
// ----------------------------------------------------
export const LiveUnit = ({
  device,
  availableWaveforms,
  availableParameters,
  parameterBox,
  useLink,
  header,
  minHeight,
  width = 6,
  type = "livemonitor",
  showWaveform = false,
  hideCamera = false,
  addEvent,
  alarmProp,
}: LiveUnitProps) => {
  //console.log ("+++ +++ LiveUnit renders", device, availableParameters);
  //console.log ("--- --- device_def:", JSON.parse (device.device_def))
  const [socket, setSocket] = useState<any | null>(null);
  const userCtx = useContext(UserContext);
  const [myDims, setMyDims] = useState<CanvasDims>({
    height: minHeight,
    width: 0,
  });
  const timer = useRef<any>();
  const componentRef = useRef<HTMLDivElement>(null);
  const [liveData, setLiveData] = useState<LiveDataPacket>({
    parameters: {},
    waveforms: [],
    patientInfo: {},
  });
  const savedParams = JSON.parse(
    // @ts-ignore
    localStorage.getItem(type + "-" + device?.device_id)
  );
  const last_datagram_ts = useRef(0);
  const [parmConnectStatus, setParmConnectStatus] = useState("disconnected");
  const connectStatus = useRef(false);
  const [selectableParameters, setSelectableWaveforms] = useState<string[]>([]);
  const [selectedWaveforms, setSelectedWaveforms] = useState<string[]>(
    savedParams || ["ECG_L1"]
  );
  const [isVideoOn, setIsVideoOn] = useState(false);
  const alarmsCtx = useContext(AlarmsContext);

  const start_monitor_connectivity_check = () => {
    timer.current = setTimeout(check_monitor_timeout, 3000, this);
    //console.log ("Start connectivity check ", device.device_id)
  };

  const check_monitor_timeout = () => {
    if (timer.current === 0) {
      return;
    }
    setTimeout(check_monitor_timeout, 3000, this);

    let time_now = new Date().getTime();
    let diff = time_now - last_datagram_ts.current;
    //console.log ("check timeout:", device.device_id, last_datagram_ts.current, diff)

    if (diff > CONSTANTS.CONNECTIVITY_TIMEOUT) {
      if (connectStatus.current) {
        //console.log ("Disconnected", device.device_id)
        setParmConnectStatus("disconnected");
        connectStatus.current = false;
      }
    }
  };

  const strobe_connectivity = () => {
    //console.log ("Strobe:", device.device_id)
    if (!connectStatus.current) {
      //console.log ("Connected:", device.device_id)
      setParmConnectStatus("connected");
      connectStatus.current = true;
    }
    last_datagram_ts.current = new Date().getTime();
  };

  useEffect(() => {
    let isMounted = true;
    // console.log("updated", componentRef?.current?.clientHeight, componentRef?.current?.clientWidth);
    if (componentRef && componentRef.current) {
      var parmBoxWidth = 0;
      if (parameterBox) {
        var r = document.querySelector(":root");
        if (r) {
          var rs = getComputedStyle(r);
          const w: string = rs
            .getPropertyValue("--parameterbox-width")
            .replace("px", "");
          parmBoxWidth = +w;
        }
      }
      if (isMounted)
        setMyDims({
          height: minHeight,
          width:
            componentRef?.current?.clientWidth -
            (isVideoOn ? 204 : parmBoxWidth),
        });
    }

    if (isMounted) setSelectableWaveforms(availableWaveforms);
    return () => {
      isMounted = false;
    };
  }, [
    isVideoOn,
    componentRef?.current?.clientWidth,
    minHeight,
    parameterBox,
    availableWaveforms,
  ]);

  const handleVisibilityChange = () => {
    const user = userCtx?.user;
    if (!user || !user.organization || !user.user_id) {
      return;
    }
    if (document.visibilityState === "hidden") {
      //console.log ("Doc is hidden - stop streaming")
      stopStreaming(socket, device.device_id, user.organization, user.user_id);
    } else {
      //console.log ("Doc is visible - start streaming")
      startStreaming(socket, device.device_id, user.organization, user.user_id);
    }
  }; // handleVisibillityChange

  //
  // Keep track of whether the document is visible or not. If it's not visible then
  // temporarily stop streaming data since it won't be seen anyway.
  //
  useEffect(() => {
    if (socket == null) {
      return;
    }
    //console.log ("Adding eventlistener for visibilitychange")
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, device.device_id, userCtx?.user]);

  useEffect(() => {
    let isMounted = true;
    if (socket == null) {
      return;
    }
    if (isMounted) {
      connectWebSocket(socket, onSocketOpen, onSocketMessage);
      start_monitor_connectivity_check();
    }
    return () => {
      isMounted = false;
      closeWebSocket(socket, onSocketOpen, onSocketMessage);
      clearTimeout(timer.current);
      timer.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  //
  // Set up websocket to the device. This websocket lives as long as the LiveUnit component is active.
  //
  useEffect(() => {
    let isMounted = true;
    let socket_name =
      "vicu-socket/" + userCtx?.user?.organization + "/" + device?.device_id;
    let webSocket: WebSocket;
    if (isMounted) {
      webSocket = new WebSocket!(GetSocketName(socket_name));
      setSocket(webSocket);
    }

    // Runs when LiveUnit component is unmounted
    return () => {
      isMounted = false;
      closeWebSocket(webSocket, onSocketOpen, onSocketMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, userCtx?.user?.organization]);

  const onSocketOpen = () => {
    const user = userCtx?.user;
    if (!user || !user.organization || !user.user_id) {
      return;
    }
    startStreaming(socket, device.device_id, user.organization, user.user_id);
  }; // onSocketOpen

  const onSocketMessage = (message: any) => {
    //@ts-ignore
    let data = JSON.parse(message.data);
    // console.log (data.message.device_id, data)
    switch (data?.opcode) {
      case "device_datagram": {
        strobe_connectivity();
        const event = message;
        const myVitals: PatientVitals = UpdateVitals(
          event,
          null,
          null,
          alarmsCtx?.setAlarms,
          null
        );

        delete myVitals?.IBP2;
        //delete myVitals?.Temp;

        //console.log ("My vitals:", myVitals)

        //console.log (device.device_id, "-------------- Device datagram:", Date())
        const msg = data.message;
        const patientInfo = msg.patient;
        const waves = msg.waveforms;
        const parms = msg.parameters;
        const waveArray: Waveform[] = [];
        var parameters = null;

        if (waves) {
          waves.forEach((w: Waveform) => {
            const ww: Waveform = {
              digital_max: w.digital_max,
              digital_min: w.digital_min,
              error_code: w.error_code,
              error_message: w.error_message,
              n_samples: w.n_samples,
              name: w.name,
              physical_dim: w.physical_dim,
              physical_max: w.physical_max,
              physical_min: w.physical_min,
              samplerate: w.samplerate,
              samples: w.samples,
              text: w.text,
              valid: w.valid ? 1 : 0,
            };
            waveArray.push(ww);
          }); // for all waves
        } // if waves

        if (parameters) {
          parameters = parms;
        }

        const liveData = {
          patientInfo: patientInfo,
          parameters: myVitals,
          waveforms: waveArray,
        };
        //console.log ("/ / / / / / / set liveData:", Date(), liveData)

        setLiveData(liveData);

        break;
      } // device datagram

      case "alarm-event": {
        const msg = data.message;
        const alms = msg.alarms;
        const device_id = msg.device_id;
        for (var i = 0; i < alms.length; i++) {
          alms[i]["device_id"] = device.nickname ? device.nickname : device_id;
        }
        if (device.enable_alarms) {
          //console.log ("Alarm:", device_id, alms)
          alarmsCtx?.setAlarms(alms);
          if (addEvent) {
            //var alm_e: Event = {
            //	timestamp: new Date (msg["timestamp"]),
            //	eventType: "alarm",
            //	description: data.message,
            //	eventData: ""
            //}
            // Disable alarms for now.
            // addEvent(alm_e);
          }
        } else {
          //console.log ("Squelch alarm:", alms)
        }

        break;
      }

      case "device_trend_datagram": {
        break;
      }

      case "event": {
        const msg = data.message;
        const devicename = device.nickname ? device.nickname : msg.device_id;
        console.log("Event msg:", msg);
        var dark = false;
        const eventType = msg["event_type"];
        // console.log("Event type:", eventType);

        switch (eventType) {
          case "msg": {
            if (addEvent) {
              var event_m: Event = {
                timestamp: new Date(msg["timestamp"]),
                eventType: "msg",
                description: msg["event_data"],
                eventData: "",
              };
              addEvent(event_m);
            }
            break;
          }

          case "restingecg": {
            //toast ( devicename + " : Resting ECG received", {
            //	position: "bottom-center",
            //	theme: dark ? "dark" : "light"
            //})

            if (addEvent) {
              const evData: string = JSON.stringify({
                type: "pdf",
                data: msg["event_data"],
              });
              var descStr = "Resting ECG received";
              var ecg_e: Event = {
                timestamp: new Date(msg["timestamp"]),
                eventType: "ecg",
                description: descStr,
                eventData: evData,
              };
              addEvent(ecg_e);
              toast(devicename + " : " + descStr, {
                position: "bottom-center",
                theme: dark ? "dark" : "light",
              });
            }

            break;
          } // restingecg

          case "defib": {
            const msgData = JSON.parse(msg["event_data"]);
            var datastr: string = "";
            if ("Description" in msgData) {
              datastr = msgData["Description"];
              if (datastr === "DEFI: Discharge") {
                dark = true;
              }
            }
            if ("Energy" in msgData) {
              datastr = "Energy: " + msgData["Energy"] + " Joules";
            }

            if (datastr !== "" && dark === true) {
              toast(devicename + " : " + datastr, {
                position: "bottom-center",
                theme: dark ? "dark" : "light",
              });
            }
            if (addEvent) {
              var event_e: Event = {
                timestamp: new Date(msg["timestamp"]),
                eventType: "event",
                description: datastr,
                eventData: "",
              };
              addEvent(event_e);
              console.log("Adding event:", event_e);
            }

            break;
          } // defib
          case "image": {
            if (addEvent) {
              var event_i: Event = {
                timestamp: new Date(msg["timestamp"]),
                eventType: "image",
                description: msg["fileName"],
                eventData: JSON.stringify({
                  unique_id: msg["unique_id"],
                  thumbnail: msg["thumbnail"],
                }),
              };
              addEvent(event_i);
            }
            break;
          }
        } // switch eventType

        break;
      } // case "event"

      default: {
        console.log("Unknown message:", data);
      }
    } // switch
  };

  const state = useExternalScript("ivideon/iv-standalone-web-sdk.js");

  const startCamera = () => {
    if (isVideoOn) {
      setIsVideoOn(false);
      return;
    }
    setIsVideoOn(true);
    if (state === "ready") {
      // @ts-ignore
      _ivideon.sdk
        .init({
          rootUrl: "ivideon/",
          l10nOptions: {
            availableLanguages: ["de", "en", "fr"],
            language: "en",
          },
        })
        .then(
          function (sdk: any) {
            sdk.configureWithCloudApiAuthResponse({
              api_host: "openapi-alpha.ivideon.com",
              access_token: device?.camera_token,
            });

            var camera = sdk.createCamera({
              id: device?.camera_id,
              cameraName: "Pet Cam",
              imageWidth: 800,
              imageHeight: "123px",
              soundEnabled: false,
            });

            var player = sdk.createPlayer({
              container: "#ivideon_stream_" + device.device_id,
              camera: camera,
              sizeCalculationMethod: sdk.playerSize.FILL_BY_WIDTH,
              timelineEnabled: false,
              playPauseButtonEnabled: false,
              goToLiveButtonEnabled: false,
              volumeControlEnabled: false,
              qualityControlEnabled: false,
              speedControlEnabled: false,
              previewEnabled: false,
              previewFromCameraEnabled: false,
              layout: "expanded",
            });

            player.playLive({ quality: 2 });
          },
          function (error: any) {
            console.error(error);
          }
        )
        .catch((error: any) => {
          console.error(error);
        });
    }
  };

  const paramBoxWidthRef = useRef("0");
  useEffect(() => {
    var r = document.querySelector("#liveUnitHeader");
    if (r) {
      var rs = getComputedStyle(r);
      const w: number = parseInt(
        rs.getPropertyValue("width").replace("px", "")
      );
      const parameterBoxWidth = w - 205;
      paramBoxWidthRef.current = parameterBoxWidth.toString() + "px";
    }
  }, []);

  // --------------------------------------------------------------------------------------------------
  //
  // The display html
  //
  //console.log ("liveData:", liveData)
  //console.log ("selected waves:", selectedWaveforms, availableWaveforms)
  return (
    <Grid
      container
      item
      xs={12}
      sm={width ?? 6}
      md={width ?? 6}
      className="liveUnit"
    >
      <Grid style={{ width: "100%" }} item>
        {header && (
          <LiveUnitHeader
            key={device.device_id}
            device={device}
            patientInfo={liveData.patientInfo}
            useLink={useLink}
            connectStatus={parmConnectStatus}
            selectParams={
              <SelectParameters
                deviceId={device.device_id}
                selectableParameters={selectableParameters}
                selectedParameters={selectedWaveforms}
                setSelectedParameters={setSelectedWaveforms}
                maxParameters={availableWaveforms.length}
                type={type}
              />
            }
            fullWidth={width === 12}
            isVideoOn={isVideoOn}
            startCamera={hideCamera ? undefined : startCamera}
            alarmProp={alarmProp}
          />
        )}
        <Grid container direction="row" ref={componentRef}>
          {/* Waveform box */}
          {(isBrowser || showWaveform) && (
            <Grid item xs className="waveformBox">
              <LiveUnitWaveforms
                canvasDims={myDims}
                selectedWaves={selectedWaveforms}
                waves={liveData.waveforms.filter(
                  (w: Waveform) =>
                    selectedWaveforms.includes(w.name) &&
                    availableWaveforms.includes(w.name)
                )}
              />
            </Grid>
          )}
          {/* Parameter box web/mobile when video is off */}
          {parameterBox &&
            !isVideoOn &&
            (isMobile ? (
              <Grid item className="parameterBox" width={"100%"}>
                <LiveUnitParameters
                  availableParms={availableParameters}
                  parms={liveData.parameters}
                  connectStatus={parmConnectStatus}
                />
              </Grid>
            ) : (
              <Grid item className="parameterBox" height={myDims.height}>
                <LiveUnitParameters
                  availableParms={availableParameters}
                  parms={liveData.parameters}
                  connectStatus={parmConnectStatus}
                />
              </Grid>
            ))}
          {/* Parameter box on mobile when video is on */}
          {parameterBox && isVideoOn && isMobile && (
            <Grid
              item
              className="parameterBox"
              width={paramBoxWidthRef.current}
            >
              <LiveUnitParameters
                availableParms={availableParameters}
                parms={liveData.parameters}
                connectStatus={parmConnectStatus}
              />
            </Grid>
          )}
          {/* Video player */}
          {isVideoOn && state === "ready" && (
            <div
              className="myapp-player-container"
              id={"ivideon_stream_" + device.device_id}
              style={{
                width: "205px",
                height: "123px",
                backgroundColor: "black",
              }}
            ></div>
          )}
        </Grid>
      </Grid>
      {/* Parameter box on web when video is on */}
      {parameterBox && isVideoOn && !isMobile && (
        <LiveUnitParameters
          availableParms={availableParameters}
          parms={liveData.parameters}
          connectStatus={parmConnectStatus}
          isVideoOn={isVideoOn}
        />
      )}
    </Grid>
  );

  // --------------------------------------------------------------------------------------------------
}; // LiveUnit

export const LiveUnitParameters = ({
  parms,
  availableParms,
  connectStatus,
  isVideoOn,
}: LiveParmsProps) => {
  //console.log ("LiveUnitParameters parms:", availableParms)
  const params = ["HR", "SpO2", "Resp", "IBP1", "Temp"];
  if (isVideoOn) {
    return (
      <Grid container>
        {params.map(
          (param) =>
            availableParms.includes(param) && (
              <ParmDisplay
                key={param}
                parm_name={param}
                // @ts-ignore
                parm={parms[param]}
                connectStatus={connectStatus}
                isVideoOn={isVideoOn}
              />
            )
        )}
      </Grid>
    );
  }

  return (
    <Grid item width={"100%"} container>
      {availableParms.includes("HR") && (
        <ParmDisplay
          parm_name="HR"
          parm={parms.HR}
          connectStatus={connectStatus}
        />
      )}

      {availableParms.includes("SpO2") && (
        <ParmDisplay
          parm_name="SpO2"
          parm={parms.SpO2}
          connectStatus={connectStatus}
        />
      )}

      {availableParms.includes("Resp") && (
        <ParmDisplay
          parm_name="Resp"
          parm={parms.Resp}
          connectStatus={connectStatus}
        />
      )}

      {availableParms.includes("IBP1") && (
        <ParmDisplay
          parm_name="IBP1"
          parm={parms.IBP1}
          connectStatus={connectStatus}
        />
      )}
      {availableParms.includes("Temp") && (
        <ParmDisplay
          parm_name="Temp"
          parm={parms.Temp}
          connectStatus={connectStatus}
        />
      )}
    </Grid>
  );
}; // LiveUnitParameters

export interface LiveParmsProps {
  availableParms: string[];
  parms: PatientVitals;
  connectStatus: string;
  isVideoOn?: boolean;
}

export interface LiveParmProps {
  parm: any;
  parm_name: string;
  connectStatus: string;
  isVideoOn?: boolean;
}

export const ParmDisplay = ({
  parm,
  parm_name,
  connectStatus,
  isVideoOn,
}: LiveParmProps) => {
  //console.log (connectStatus)
  const { t } = useTranslation();

  // Different parameters have slightly different parameter structures. Deal with that here.
  var parm_unit = "-";
  var parm_val = "-";
  var active_alarm = false;

  if (!parm) {
    parm_unit = "";
    parm_val = "-";
  } else {
    switch (parm_name) {
      case "HR": {
        parm_unit = parm.unit;
        parm_val = parm.value;
        active_alarm = parm.active_alarm;
        break;
      }
      case "SpO2": {
        parm_unit = parm.sp ? parm.sp.unit : parm.unit;
        parm_val = parm.sp ? parm.sp.value : parm.value;
        active_alarm = parm.active_alarm;
        break;
      }
      case "Resp": {
        parm_unit = parm.rr.unit;
        parm_val = parm.rr.value;
        active_alarm = parm.active_alarm;
        break;
      }
      case "IBP1": {
        parm_unit = parm.systole.unit;
        parm_val = parm.systole.value;
        active_alarm = parm.active_alarm;
        break;
      }
      case "Temp": {
        parm_unit = parm.t1 ? parm.t1.unit : parm.unit;
        parm_val = parm.t1 ? parm.t1.value : parm.value;
        //var parm_num = +parm_val; // convert from string to Number
        //if (isNaN (parm_num)) {
        //	parm_num = -1
        //} else {
        //	parm_num = +parm_num.toFixed(1)
        //}
        //				if (isNaN(+parm_val)) {
        //					parm_val = parm.t1.value;
        //				} else {
        //					const parm_num = Number(parm.t1.value);
        //					parm_val = parm_num.toFixed(1);
        //				}
        //parm_val = parm_val.toFixed (1)
        active_alarm = parm.active_alarm;
        break;
      }
    }
  }

  if (isVideoOn) {
    return (
      <Grid
        container
        item
        xs={3}
        sx={{
          padding: 0.5,
          backgroundColor: "black",
          borderColor: "red",
          mt: -0.5,
        }}
        direction="row"
      >
        <Grid
          item
          className={
            connectStatus === "connected"
              ? "parameter-name-" + parm_name
              : "parameter-name-disconnected"
          }
        >
          {parm_name && t(parm_name)}
          <span
            style={{ marginLeft: 4 }}
            className={
              connectStatus === "connected"
                ? "parameter-unit-" + parm_name
                : "parameter-unit-disconnected"
            }
          >
            {parm_unit}
          </span>
        </Grid>
        <Grid
          item
          xs={3}
          className={
            connectStatus === "connected"
              ? "parameter-value-" + parm_name
              : "parameter-value-disconnected"
          }
          style={{ textAlign: "right" }}
        >
          <div
            className={
              active_alarm ? "parameter-value-alarm" : "parameter-value-normal"
            }
          >
            {parm_val}
          </div>
        </Grid>
      </Grid>
    );
  }

  //console.log (parm_name, active_alarm)
  return (
    <Grid
      container
      direction="row"
      style={{ marginBottom: "1px", height: "100%" }}
    >
      {/* Parameter Name */}
      <Grid
        item
        xs={8}
        className={
          connectStatus === "connected"
            ? "parameter-name-" + parm_name
            : "parameter-name-disconnected"
        }
      >
        {parm_name && t(parm_name)}

        <span
          className={
            connectStatus === "connected"
              ? "parameter-unit-" + parm_name
              : "parameter-unit-disconnected"
          }
        >
          {" "}
          {parm_unit}
        </span>
      </Grid>

      {/* Parameter Value */}
      <Grid
        item
        xs={4}
        className={
          connectStatus === "connected"
            ? "parameter-value-" + parm_name
            : "parameter-value-disconnected"
        }
        style={{ textAlign: "right" }}
      >
        <div
          className={
            active_alarm ? "parameter-value-alarm" : "parameter-value-normal"
          }
        >
          {parm_val}
        </div>
      </Grid>
    </Grid>
  );
}; // ParmDisplay

const startStreaming = (
  socket: any,
  device_id: string,
  organization: string,
  user_id: number
) => {
  if (socket !== null && socket.readyState === SOCKET_OPEN) {
    //console.log ("Start streaming:", socket)
    const stream = {
      type: "server-request",
      opcode: "stream_waveforms",
      organization: organization,
      device_id: device_id,
      user_id: user_id,
    };
    socket.send(JSON.stringify(stream));
  }
};

const stopStreaming = (
  socket: any,
  device_id: string,
  organization: string,
  user_id: number
) => {
  if (socket !== null && socket.readyState === SOCKET_OPEN) {
    //console.log ("Stop streaming:", socket)
    const stopStream = {
      type: "server-request",
      opcode: "stop_streaming",
      organization: organization,
      device_id: device_id,
      user_id: user_id,
    };
    socket.send(JSON.stringify(stopStream));
  }
};

const closeWebSocket = (
  webSocket: WebSocket,
  onOpenFunction: () => void,
  onMessageFunction: (message: any) => void
) => {
  webSocket.removeEventListener("open", onOpenFunction);
  webSocket.removeEventListener("message", onMessageFunction);
  if (webSocket !== null && webSocket.readyState === WebSocket.OPEN) {
    webSocket.close();
  }
};

const connectWebSocket = (
  socket: any,
  onOpenFunction: () => void,
  onMessageFunction: (message: any) => void
) => {
  // console.log("Connecting to WebSocket...", socket);
  socket.addEventListener("open", onOpenFunction);
  socket.addEventListener("message", onMessageFunction);
};
