import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Typography,
} from "@mui/material";

import { Loading } from "../../components/Loading";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Organization } from "../../interfaces/Organization";
import { OrgDialog } from "./OrgDialog";
import { isBrowser } from "react-device-detect";
import { IngestionServer } from "../../interfaces/IngestionServer";

interface Props {
  orglist: Organization[];
  dialogMode: string;
  ingestionServers: IngestionServer[];
}

export const OrgTable = ({ orglist, dialogMode, ingestionServers }: Props) => {
  //console.log ("OrgTable renders: ", dialogMode, ingestionServers)
  const [org, setOrg] = useState<Organization | null>(null);
  const [showDialog, setShowDialog] = useState(dialogMode === "add");

  const { t } = useTranslation();
  const theme = useTheme();
  const fontVariant = isBrowser ? "body2" : "caption";

  if (!orglist) {
    return <Loading />;
  }

  //console.log("Org list:", dialogMode, orglist);
  return (
    <TableContainer component={Paper}>
      {org && (
        <OrgDialog
          openState={showDialog}
          setOpenState={setShowDialog}
          targetOrg={org}
          ingestionServers={ingestionServers}
          dialogMode={"edit"}
        />
      )}

      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              border: 0,
              bgcolor: theme.palette.primary.main,
              "& th": {
                color: theme.palette.text.secondary,
              },
            }}
          >
            <TableCell
              sx={{
                padding: "16px 6px",
              }}
            >
              {t("organization_id")}
            </TableCell>

            <TableCell
              sx={{
                padding: "16px 6px",
              }}
            >
              {t("organization_name")}
            </TableCell>

            <TableCell
              sx={{
                padding: "16px 6px",
              }}
            >
              {t("login_domain")}
            </TableCell>

            <TableCell
              sx={{
                padding: "16px 6px",
              }}
            >
              {t("sentioweb_variant")}
            </TableCell>

            <TableCell
              sx={{
                padding: "16px 6px",
              }}
            >
              {t("admin_email")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orglist?.map((org: Organization, i: number) => (
            <TableRow
              onClick={() => {
                setOrg(org);
                //vicuRef.current = v;
                setShowDialog(true);
              }}
              key={i}
              hover
              sx={{
                "&:nth-of-type(odd)": {
                  background: theme.palette.info.dark,
                },
                "&:nth-of-type(even)": {
                  background: theme.palette.info.light,
                },
                cursor: "pointer",
              }}
            >
              <TableCell
                sx={{
                  padding: "16px 6px",
                }}
              >
                <Typography variant={fontVariant}>
                  {org.organization_id}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  padding: "16px 6px",
                }}
              >
                <Typography variant={fontVariant}>
                  {org.organization_name}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  padding: "16px 6px",
                }}
              >
                <Typography variant={fontVariant}>
                  {org.login_domain}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  padding: "16px 6px",
                }}
              >
                <Typography variant={fontVariant}>{org.sentio_type}</Typography>
              </TableCell>

              <TableCell
                sx={{
                  padding: "16px 6px",
                }}
              >
                <Typography variant={fontVariant}>{org.admin_email}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
