import * as CONSTANTS from "../utils/constants/constants";
import { Credentials } from "../interfaces/user/Credentials";
import { MFA } from "../interfaces/user/MFA";

const axios = require("axios").default;

class LoginService {
  static login(credentials: Credentials) {
    return axios
      .post(CONSTANTS.HOST + "auth/login/", credentials)
      .then((response: any) => response.data)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }

  static otp(credentials: MFA) {
    return axios
      .post(CONSTANTS.HOST + "auth/login/code/", credentials)
      .then((response: any) => response.data)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }
}

export default LoginService;
