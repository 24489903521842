import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import OrgService from "../../services/OrganizationService";
import { useState } from "react";
import { OrgTable } from "./OrgTable";
import { OrgDialog } from "./OrgDialog";
import { User } from "../../interfaces/user/User";
import IngestionServerService from "../../services/IngestionServerService";
import { useEffect } from "react";
import { IngestionServer } from "../../interfaces/IngestionServer";

export const SystemManagement = () => {
  const { t } = useTranslation();
  const [cookies, ,] = useCookies(["access_token"]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState("edit");
  const [user] = useState<User | null>(
    // @ts-ignore
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [servers, setServers] = useState<IngestionServer[]>([]);

  // Fetch all Organizations
  const {
    data: orgList,
    loading: orgsLoading,
    error: orgsError,
  } = useFetch({
    url: OrgService.getAllOrganizations(),
    access_token: cookies.access_token,
  });

  // Fetch all ingestion servers
  const {
    data: ingestionServers,
    loading: serversLoading,
    error: serverError,
  } = useFetch({
    url: IngestionServerService.getAllIngestionServers(),
    access_token: cookies.access_token,
  });

  useEffect(() => {
    if (ingestionServers === null) {
      return;
    }
    const updatedServers: IngestionServer[] = [
      ...ingestionServers,
      { server_id: "manual", name: "Manual Configuration" },
    ];
    setServers(updatedServers);
  }, [ingestionServers]);

  if (serversLoading) {
    return <Loading />;
  }
  if (serverError) {
    return <ServerProblem />;
  }
  if (orgsLoading) {
    return <Loading />;
  }
  if (orgsError) {
    return <ServerProblem />;
  }

  if (orgList === null) {
    return <>No Organizations have been defined</>;
  }

  const addDialog = () => {
    setDialogMode("add");
    setShowDialog(true);
  };

  //console.log("SystemManagement:", orgList, servers);
  return (
    <div>
      <OrgDialog
        openState={showDialog}
        setOpenState={setShowDialog}
        targetOrg={undefined}
        ingestionServers={servers}
        dialogMode={"add"}
      />

      <Grid item>
        <OrgTable
          orglist={orgList}
          dialogMode={dialogMode}
          ingestionServers={servers}
        />
      </Grid>

      {user?.is_superadmin && (
        <Grid item sx={{ mt: 2 }}>
          <Button variant="contained" onClick={addDialog}>
            {t("add_organization")}
          </Button>
        </Grid>
      )}
    </div>
  );
};
