import { Typography, Grid } from "@mui/material";

export const ScoringBoxLabel = ({ def, ranges }: any) => {
  const low_val =
    ranges.low.minor !== ""
      ? ranges.low.minor
      : ranges.low.major !== ""
      ? ranges.low.major
      : ranges.low.critical;
  const high_val =
    ranges.high.minor !== ""
      ? ranges.high.minor
      : ranges.high.major !== ""
      ? ranges.high.major
      : ranges.high.critical;

  var include_ranges = true;
  if (["ACVPU", "RespDistress", "EtCO2"].includes(ranges.code)) {
    include_ranges = false;
  }
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item>
          {" "}
          <Typography variant="h6" align="center" color="text.primary">
            {ranges.name}
          </Typography>{" "}
        </Grid>
        {include_ranges && (
          <Grid item>
            {" "}
            <Typography variant="subtitle2" color="text.primary">
              {low_val} - {high_val}{" "}
            </Typography>{" "}
          </Grid>
        )}
      </Grid>
    </>
  );
};
