import { Grid, Typography } from "@mui/material";

export const ServerProblem = () => {
  return (
    <Grid container direction="column" alignItems="center" color="text.primary">
      <Typography variant="h1" color="text.primary">
        Problems with the server
      </Typography>
      <Grid item sx={{ mt: 2 }}>
        <Typography variant="h5">
          It seems that there is a problem with the server. Please try again
          later or try and refresh the page.
        </Typography>
      </Grid>
    </Grid>
  );
};
