import { Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export const PageNotFound = () => {
  const history = useHistory();
  console.log("Page not found!");
  return (
    <Grid container direction="column" alignItems="center" color="text.primary">
      <Typography variant="h1" color="text.primary">
        Page not found
      </Typography>
      <Grid item sx={{ mt: 2 }}>
        <Typography variant="h5">
          Page not found :( Maybe the page you are looking for has been removed,
          or you typed in the wrong URL
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <Button onClick={history.goBack}>Go back</Button>
        <Button onClick={() => history.push("/")}>Go Home</Button>
      </Grid>
    </Grid>
  );
};
