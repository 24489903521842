import { Device } from "./Device";

export interface VICU {
  vicu_id: string;
  group_id: string;
  vicu_name: string;
  group_name: string;
  n_monitors: number;
  n_patients: number;
  primary_contact: string;
  parameter_granularity: number;
  vicu_type: string;
  devices: Device[];
}

// NB: make sure these harmonize witn back-end definitions in organization.models.py
// we only use the key
export const VICU_TYPES_OLD: { [key: string]: string } = {
  continuous: "Continuous Connected Monitoring", // Streaming devices such as Recobro, ChronoLife
  "off-line": "Off-line Recording", // Off-line devices such as LP1220, myPatch-SL
  rpm: "Discrete RPM", // Discrete measurement devices, such as iHealth, Cosinuss c-med alpha
};

export const VICU_TYPES = ["continuous", "off-line", "rpm"];
