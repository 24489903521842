import {
  Grid,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { Loading } from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import VICUService from "../../services/VICUService";
import { VICU } from "../../interfaces/VICU";
import { Device } from "../../interfaces/Device";
import useFetch from "../../hooks/useFetch";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { GranularitySelector } from "../../components/chart/GranularitySelector";
import { VICU_TYPES } from "../../interfaces/VICU";

import "./VicuManagement.css";
interface Props {
  targetvicu: VICU;
  openState: boolean;
  setOpenState: (show: boolean) => void;
}

export const VicuDialog = ({ targetvicu, openState, setOpenState }: Props) => {
  //console.log ("VicuDialog:", targetvicu)

  if (!targetvicu.parameter_granularity) {
    targetvicu.parameter_granularity = 10;
  }
  const { t } = useTranslation();
  const [cookies, ,] = useCookies(["access_token"]);
  const [vicuname, setVicuname] = useState<string>(targetvicu.vicu_name);
  const [contact, setContact] = useState<string>(
    targetvicu ? targetvicu.primary_contact : ""
  );
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [selectedAvailableDevice, setSelectedAvailableDevice] =
    useState<Device | null>(null);
  const [vicunameChanged, setVicunameChanged] = useState(false);
  const [contactChanged, setContactChanged] = useState(false);
  const [vicuTypeChanged, setVicuTypeChanged] = useState(false);
  const [parmGranularity, setParmGranularity] = useState(
    targetvicu.parameter_granularity
  );
  const [granularityChanged, setGranularityChanged] = useState(false);
  const [vicuType, setVicuType] = useState(targetvicu.vicu_type);
  const [vicuDesc, setVicuDesc] = useState<string>(
    t(targetvicu.vicu_type + "_desc")
  );

  const theme = useTheme();

  const { data: assignedDevices, loading: assignedDevicesLoading } = useFetch({
    url: VICUService.getVICUDevices(),
    access_token: cookies.access_token,
    // @ts-ignore
    id: targetvicu.vicu_id,
  });

  var { data: availableDevices, loading: availableDevicesLoading } = useFetch({
    url: VICUService.getAvailableDevices(),
    access_token: cookies.access_token,
    // @ts-ignore
    id: targetvicu.vicu_type,
  });

  const handleClose = () => {
    setOpenState(false);
    window.location.href = "/vicumgmt";
  };

  const handleVicunameChange = (val: string) => {
    setVicuname(val);
    setVicunameChanged(val.trim() !== targetvicu?.vicu_name);
  };

  const handleContactChange = (val: string) => {
    setContact(val);
    setContactChanged(val.trim() !== targetvicu?.primary_contact);
  };

  const handleGranularityChange = (val: number) => {
    setParmGranularity(val);
    setGranularityChanged(val !== targetvicu?.parameter_granularity);
  };

  const handleVicutypeChange = (event: SelectChangeEvent) => {
    setVicuType(event.target.value);
    console.log("desc:", event.target.value + "_desc");
    setVicuDesc(t(event.target.value + "_desc"));
    setVicuTypeChanged(event.target.value !== targetvicu?.vicu_type);
  };

  const handleSaveChanges = () => {
    // console.log ("Handle save changes:", parmGranularity)
    VICUService.editVICU(
      targetvicu.vicu_id,
      vicuname,
      contact,
      parmGranularity,
      vicuType,
      cookies.access_token
    )
      .then((res: any) => {
        //console.log("result:", res);
        targetvicu.vicu_name = vicuname;
        targetvicu.primary_contact = contact;
        targetvicu.parameter_granularity = parmGranularity;
        targetvicu.vicu_type = vicuType;
        setShowSaveButton(false);
        setContactChanged(false);
        setVicunameChanged(false);
        setGranularityChanged(false);
        setVicuTypeChanged(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setShowSaveButton(
      vicunameChanged || contactChanged || granularityChanged || vicuTypeChanged
    );
  }, [vicunameChanged, contactChanged, granularityChanged, vicuTypeChanged]);

  if (assignedDevicesLoading || availableDevicesLoading) return <Loading />;

  const addDevice = (devicelist: Device[], device: Device) => {
    devicelist.push(device);
  };
  const removeDevice = (devicelist: Device[], device: Device) => {
    devicelist.forEach((item, index) => {
      if (item.device_id === device.device_id) devicelist.splice(index, 1);
    });
  };

  const removeMonitor = () => {
    if (selectedDevice === null) {
      return;
    }
    VICUService.manageGroupDevices(
      // @ts-ignore
      targetvicu.vicu_id,
      { type: "detach", devices: [{ device_id: selectedDevice.device_id }] },
      cookies.access_token
    )
      .then((res: any) => {
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
    addDevice(availableDevices, selectedDevice);
    removeDevice(assignedDevices, selectedDevice);
  };

  const assignMonitor = () => {
    if (selectedAvailableDevice === null) {
      return;
    }
    VICUService.manageGroupDevices(
      // @ts-ignore
      targetvicu.vicu_id,
      {
        type: "attach",
        devices: [{ device_id: selectedAvailableDevice.device_id }],
      },
      cookies.access_token
    )
      .then((res: any) => {
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
    addDevice(assignedDevices, selectedAvailableDevice);
    removeDevice(availableDevices, selectedAvailableDevice);
  };

  const handleClickAway = () => {
    setSelectedDevice(null);
  };
  const handleAvailableClickAway = () => {
    setSelectedAvailableDevice(null);
  };

  const deleteVICU = () => {
    setConfirmDelete(true);
  };

  const cancelDelete = () => {
    setConfirmDelete(false);
  };

  const yesDelete = () => {
    // Really delete the VICU. Send a request to the back-end
    if (!targetvicu) {
      return;
    }
    VICUService.deleteVICU(targetvicu.vicu_id, cookies.access_token)
      .then((res: any) => {
        setConfirmDelete(false);
        window.location.href = "/vicumgmt";
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  //console.log ("Assigned devices:", assignedDevices)
  //console.log ("Available devices:", availableDevices)
  //console.log ("parameter_granularity:", targetvicu.parameter_granularity, )
  return (
    <>
      <Dialog open={confirmDelete}>
        <DialogTitle> {t("confirm_group_delete")} </DialogTitle>
        <DialogContent>
          <Typography>{t("delete_group_notification")}</Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="outlined" onClick={cancelDelete}>
              {" "}
              {t("cancel")}
            </Button>
            <Button variant="contained" onClick={yesDelete} color="warning">
              {" "}
              {t("yes_delete")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog open={openState} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle> {t("group_administration")} </DialogTitle>

        <DialogContent>
          <Grid container item pt={3} mb={1}>
            <Grid container direction="row" columnSpacing={1}>
              <Grid item>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t("group_name")}
                  id="vicu_name"
                  value={vicuname}
                  onChange={(e) => handleVicunameChange(e.target.value)}
                  sx={{
                    input: {
                      color: theme.palette.text.primary,
                      background: theme.palette.background.default,
                    },
                    label: { color: theme.palette.text.primary },
                  }}
                />
              </Grid>

              <Grid item>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={t("contact")}
                  id="contact"
                  value={contact}
                  onChange={(e) => handleContactChange(e.target.value)}
                  sx={{
                    input: {
                      color: theme.palette.text.primary,
                      background: theme.palette.background.default,
                    },
                    label: { color: theme.palette.text.primary },
                    width: { md: 250 },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item>
            {/* Monitor Group Type and Description */}
            <Grid container direction="row" pt={3}>
              <Grid item xs={6}>
                <InputLabel sx={{ color: "text.primary" }} id="vicutype">
                  {t("vicu_type")}
                </InputLabel>

                <Select
                  value={vicuType}
                  label={t("vicu_type")}
                  disabled={assignedDevices?.length > 0}
                  onChange={handleVicutypeChange}
                >
                  {VICU_TYPES.map((key) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Typography id="group_description"> {vicuDesc} </Typography>
              </Grid>
            </Grid>

            {vicuType === "continuous" && (
              <Grid item>
                <GranularitySelector
                  sliderWidth={300}
                  defaultval={targetvicu.parameter_granularity}
                  setParmInterval={setParmGranularity}
                  handleChange={handleGranularityChange}
                  title={t("set_default_parameter_interval")}
                />
              </Grid>
            )}
            <Grid container justifyContent={"flex-end"}>
              {showSaveButton && (
                <Button onClick={handleSaveChanges}>
                  {" "}
                  {t("save_changes")}
                </Button>
              )}
              {!showSaveButton && (
                <Button disabled> {t("save_changes")}</Button>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={5}>
            {" "}
            {/* Outer container */}
            <Grid container item xs={6}>
              {" "}
              {/* Left container */}
              <ClickAwayListener onClickAway={handleClickAway}>
                <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                  <Typography>{t("monitors_in_group")}</Typography>

                  <Table
                    sx={{ width: "100%" }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          border: 0,
                          bgcolor: theme.palette.primary.main,
                          "& th": {
                            fontSize: "1.25rem",
                            color: theme.palette.text.secondary,
                          },
                        }}
                      >
                        <TableCell>{t("device_id")}</TableCell>
                        <TableCell>{t("nickname")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assignedDevices?.map((d: Device, i: number) => (
                        <TableRow
                          onClick={() => {
                            console.log(d);
                            setSelectedDevice(d);
                          }}
                          key={i}
                          hover
                          sx={{
                            "&:nth-of-type(odd)": {
                              background: theme.palette.info.dark,
                            },
                            "&:nth-of-type(even)": {
                              background: theme.palette.info.light,
                            },
                            cursor: "pointer",
                          }}
                          className="connectionOK"
                        >
                          <TableCell
                            className={
                              selectedDevice === d ? "selectedRow" : ""
                            }
                          >
                            {d.device_id}
                          </TableCell>
                          <TableCell
                            className={
                              selectedDevice === d ? "selectedRow" : ""
                            }
                          >
                            {d.nickname}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ClickAwayListener>
              {selectedDevice && (
                <Button variant="outlined" onClick={removeMonitor}>
                  {t("unassign monitor")}{" "}
                </Button>
              )}
            </Grid>{" "}
            {/* Left container */}
            <Grid container item xs={6}>
              {" "}
              {/* Right container */}
              <ClickAwayListener onClickAway={handleAvailableClickAway}>
                <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                  <Typography>{t("available_monitors")}</Typography>

                  <Table
                    sx={{ width: "100%" }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          border: 0,
                          bgcolor: theme.palette.primary.main,
                          "& th": {
                            fontSize: "1.25rem",
                            color: theme.palette.text.secondary,
                          },
                        }}
                      >
                        <TableCell>{t("device_id")}</TableCell>
                        <TableCell>{t("nickname")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {availableDevices?.map((d: Device, i: number) => (
                        <TableRow
                          onClick={() => {
                            console.log(d);
                            setSelectedAvailableDevice(d);
                          }}
                          key={i}
                          hover
                          sx={{
                            "&:nth-of-type(odd)": {
                              background: theme.palette.info.dark,
                            },
                            "&:nth-of-type(even)": {
                              background: theme.palette.info.light,
                            },
                            cursor: "pointer",
                          }}
                          className="connectionOK"
                        >
                          <TableCell
                            className={
                              selectedAvailableDevice === d ? "selectedRow" : ""
                            }
                          >
                            {d.device_id}
                          </TableCell>
                          <TableCell
                            className={
                              selectedAvailableDevice === d ? "selectedRow" : ""
                            }
                          >
                            {d.nickname}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ClickAwayListener>
              {selectedAvailableDevice && (
                <Button variant="outlined" onClick={assignMonitor}>
                  {t("assign monitor")}{" "}
                </Button>
              )}
            </Grid>
          </Grid>{" "}
          {/* Outer container */}
        </DialogContent>

        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="outlined" onClick={handleClose}>
              {" "}
              {t("close")}
            </Button>
            <Button variant="outlined" onClick={deleteVICU}>
              {" "}
              {t("delete_monitorgroup")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ItemProps {
  title: any;
  content: any;
}

export const CardItem = ({ title, content }: ItemProps) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        {" "}
        <Typography>{title} </Typography>{" "}
      </Grid>
      <Grid item xs={6}>
        {" "}
        <Typography>{content}</Typography>{" "}
      </Grid>
    </Grid>
  );
};
