import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GRAPH_MARGIN } from "./CareSummaryInfo";
import { graphUnitHeightPx } from "./CareSummaryInfo";

export interface scoringBoxProps {
  parm: any[];
  timestamps: number[];
  ranges: any;
  colors: any;
  min_ts: number;
  max_ts: number;
  current_ts: number;
}
export const ScoringBoxGraph = ({
  parm,
  timestamps,
  ranges,
  colors,
  min_ts,
  max_ts,
  current_ts,
}: scoringBoxProps) => {
  const waveCanvasRef = useRef<HTMLCanvasElement>(null);
  const gridCanvasRef = useRef<HTMLCanvasElement>(null);
  const canvasHeight = useRef(0);
  const drawIndex = useRef(0);
  const { t } = useTranslation();
  const trendLine = useRef<any[]>([]);
  const prev_x = useRef<number | null>(null);
  const prev_y = useRef<number | null>(null);
  const averageArray = useRef<any[]>([]);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    if (!gridCanvasRef.current || !waveCanvasRef.current) {
      return;
    }
    // Initialize canvas

    const min_value = ranges.unitStep.min; // * ranges.unitStep.step;
    const max_value = ranges.unitStep.max; // * ranges.unitStep.step;
    const n_steps = (max_value - min_value) / ranges.unitStep.step; //ranges.unitStep.max - ranges.unitStep.min;
    canvasHeight.current = n_steps * graphUnitHeightPx;
    const graphRealRangeY = {
      min: min_value,
      max: max_value,
      scaleFactor: canvasHeight.current / (max_value - min_value),
    };

    const waveCanvas = waveCanvasRef.current;
    const gridCanvas = gridCanvasRef.current;
    const waveCtx = waveCanvas.getContext("2d");
    const gridCtx = gridCanvas.getContext("2d");

    if (!waveCtx || !gridCtx) {
      return;
    }
    waveCtx.canvas.height = canvasHeight.current;
    waveCtx.canvas.width =
      waveCanvasRef?.current?.parentElement?.offsetWidth || 0;
    gridCtx.canvas.height = canvasHeight.current;
    gridCtx.canvas.width =
      gridCanvasRef?.current?.parentElement?.offsetWidth || 0;

    // Clear both canvases
    waveCtx.fillStyle = "white";
    waveCtx.clearRect(0, 0, waveCtx.canvas.width, waveCtx.canvas.height);
    drawIndex.current = 0;
    trendLine.current = [];
    averageArray.current = [];
    /*
     * Fill in the color ranges
     */

    // Fill in UPPER ranges. Map from real values to pixels
    const scaleFactor = graphRealRangeY.scaleFactor;
    const width = waveCtx.canvas.width - GRAPH_MARGIN;

    var critical_top = ranges.unitStep.max;
    var major_top = ranges.unitStep.max;
    var minor_top = ranges.unitStep.max;
    var critical_bottom = ranges.unitStep.min;
    var major_bottom = ranges.unitStep.min;
    var minor_bottom = ranges.unitStep.min;

    const critical_high_defined = ranges.high.critical !== "";
    const major_high_defined = ranges.high.major !== "";
    const minor_high_defined = ranges.high.minor !== "";
    const critical_low_defined = ranges.low.critical !== "";
    const major_low_defined = ranges.low.major !== "";
    const minor_low_defined = ranges.low.minor !== "";

    if (critical_high_defined) {
      // draw critical range. It is from the top of the diagram to the critical threshold

      waveCtx.fillStyle = colors.critical;

      const range_span = critical_top - ranges.high.critical;
      const cr_bottom = critical_top - range_span;
      const cr_y = 0; //(ranges.unitStep.max - critical_start) * scaleFactor;
      const range_height = range_span * scaleFactor;
      waveCtx.fillRect(GRAPH_MARGIN, cr_y, width, range_height);
      //console.log ("Fillrect Critical:", 0, cr_y, width, range_height)

      major_top = cr_bottom;
      minor_top = cr_bottom;
    }

    if (major_high_defined) {
      // draw major range. It is from the major threshold up until major_start (end of cr)
      waveCtx.fillStyle = colors.major;
      const range_span = major_top - ranges.high.major;
      const mj_bottom = major_top - range_span;
      const cr_y = (critical_top - major_top) * scaleFactor;
      const range_height = range_span * scaleFactor;
      waveCtx.fillRect(GRAPH_MARGIN, cr_y, width, range_height);

      minor_top = mj_bottom;
    }

    if (minor_high_defined) {
      // draw minor range
      waveCtx.fillStyle = colors.minor;
      const range_span = minor_top - ranges.high.minor;
      const cr_y = (critical_top - minor_top) * scaleFactor;
      const range_height = range_span * scaleFactor;
      waveCtx.fillRect(GRAPH_MARGIN, cr_y, width, range_height);
    }

    // Bottom ranges
    if (critical_low_defined) {
      waveCtx.fillStyle = colors.critical;

      const cr_top = ranges.low.critical;
      const range_span = cr_top - critical_bottom;

      const cr_y =
        canvasHeight.current - (cr_top - critical_bottom) * scaleFactor;
      const range_height = range_span * scaleFactor;
      waveCtx.fillRect(GRAPH_MARGIN, cr_y, width, range_height);

      major_bottom = cr_top;
      minor_bottom = cr_top;
    }

    if (major_low_defined) {
      waveCtx.fillStyle = colors.major;

      const mj_top = ranges.low.major;
      const range_span = mj_top - major_bottom;

      const cr_y =
        canvasHeight.current - (mj_top - critical_bottom) * scaleFactor;
      const range_height = range_span * scaleFactor;
      waveCtx.fillRect(GRAPH_MARGIN, cr_y, width, range_height);

      minor_bottom = mj_top;
      minor_bottom = mj_top;
    }

    if (minor_low_defined) {
      waveCtx.fillStyle = colors.minor;

      const mn_top = ranges.low.minor;
      const range_span = mn_top - minor_bottom;

      const cr_y =
        canvasHeight.current - (mn_top - critical_bottom) * scaleFactor;
      const range_height = range_span * scaleFactor;
      waveCtx.fillRect(GRAPH_MARGIN, cr_y, width, range_height);
    }

    // Draw horizontal guide lines.
    const step_add = graphUnitHeightPx;
    waveCtx.strokeStyle = "grey";
    waveCtx.lineWidth = 1;
    var current_label = ranges.unitStep.max - ranges.unitStep.step;
    waveCtx.font = "12px Arial";
    waveCtx.fillStyle = "#000000";
    waveCtx.textAlign = "right";

    const ACVPU_label = ["A", "C", "V", "P", "U"];
    const RespDistress_label = [
      t("normal"),
      t("mild"),
      t("moderate"),
      t("severe"),
    ];
    for (
      var y_idx = step_add;
      y_idx <= canvasHeight.current;
      y_idx += step_add
    ) {
      waveCtx.beginPath();
      waveCtx.moveTo(GRAPH_MARGIN, y_idx);
      waveCtx.lineTo(waveCtx.canvas.width, y_idx);
      waveCtx.stroke();

      var labelstr: string = current_label.toString();
      var label_delta = 0;
      if (ranges.code === "ACVPU") {
        labelstr = ACVPU_label[current_label];
        label_delta = 8;
      }

      if (ranges.code === "RespDistress") {
        labelstr = RespDistress_label[current_label];
        label_delta = 8;
      }

      // The bottom scale number is partly obscured for number-parameters. Skip that one.
      if (label_delta === 0 && y_idx === canvasHeight.current) {
        continue;
      }

      waveCtx.fillText(labelstr, GRAPH_MARGIN - 10, y_idx + 4 - label_delta);
      current_label -= ranges.unitStep.step;
    }

    //console.log ("Init canvas!!!")
    setTrigger(trigger + 1);
    // eslint-disable-next-line
  }, [gridCanvasRef, waveCanvasRef, ranges, max_ts]);

  useEffect(() => {
    //console.log (parm, typeof (parm))
    if (!waveCanvasRef.current || !gridCanvasRef.current) {
      return;
    }
    const waveCanvas = waveCanvasRef.current;
    const waveCtx = waveCanvas.getContext("2d");

    const gridCanvas = gridCanvasRef.current;
    const gridCtx = gridCanvas.getContext("2d");

    if (!waveCtx || !gridCtx) {
      return;
    }
    waveCtx.fillStyle = "#000000";

    const n_pixels = waveCtx.canvas.width;
    const n_ms = max_ts - min_ts;
    const px_per_ms = (n_pixels - GRAPH_MARGIN) / n_ms;

    const min_value = ranges.unitStep.min; // * ranges.unitStep.step;
    const max_value = ranges.unitStep.max; // * ranges.unitStep.step;
    const px_per_unit = waveCtx.canvas.height / (max_value - min_value);

    var x_px = 0;
    var averageWindow = 8;
    while (drawIndex.current < parm.length) {
      const p = parm[drawIndex.current];
      const ts = Math.floor(timestamps[drawIndex.current] * 1000);
      drawIndex.current++;

      //console.log (p, ranges)
      //const ts = p.timestamp;
      //if (initial) console.log (p.timestamp, min_ts, ts - min_ts)
      const ts_offset = ts - min_ts;

      var value: number = -1;

      if (p !== "--") {
        value = +p;
      }

      if (value === -1) {
        continue;
      }

      if (["ACVPU", "RespDistress"].includes(ranges.code)) {
        value += 0.5; // The 0.5 is to get the line right in the middle of the plot area.
      }

      x_px = ts_offset * px_per_ms + GRAPH_MARGIN;
      const y_px = waveCtx.canvas.height - (value - min_value) * px_per_unit;
      waveCtx.fillRect(x_px, y_px, 2, 2); // fill in the pixel at (10,10)

      if (prev_x.current !== null && prev_y.current !== null) {
        // Draw a line between the points
        waveCtx.strokeStyle = "#AAAa";
        waveCtx.lineWidth = 0.3;
        waveCtx.beginPath();
        waveCtx.moveTo(prev_x.current, prev_y.current);
        waveCtx.lineTo(x_px, y_px);
        waveCtx.closePath();
        waveCtx.stroke();
      }
      prev_x.current = x_px;
      prev_y.current = y_px;

      averageArray.current.push(y_px);
      if (averageArray.current.length > averageWindow) {
        averageArray.current.shift();
      }
      const t =
        averageArray.current.reduce((acc: number, val: number) => {
          return acc + val;
        }) / averageArray.current.length;

      trendLine.current.push([x_px, t]);
    } // while more parms to draw

    // Draw a trend line
    waveCtx.strokeStyle = "#05A";
    waveCtx.lineWidth = 2;
    waveCtx.beginPath();
    for (var x = 0; x < trendLine.current.length; x++) {
      const p = trendLine.current[x];
      if (x > 0) {
        waveCtx.lineTo(p[0], p[1]);
      }
      waveCtx.moveTo(p[0], p[1]);
    }
    waveCtx.closePath();
    waveCtx.stroke();

    // Clear the grid canvas - otherwise the cursor and notch won't appear to be moving, but just smear to the right.
    gridCtx.clearRect(0, 0, gridCtx.canvas.width, gridCtx.canvas.height);

    // Draw a cursor line at the last parameter position
    gridCtx.strokeStyle = "#888a";
    gridCtx.lineWidth = 0.5;
    gridCtx.beginPath();
    gridCtx.moveTo(x_px + 1, 0);
    gridCtx.lineTo(x_px + 1, waveCtx.canvas.height);
    gridCtx.stroke();

    const notch_x_center = (current_ts - min_ts) * px_per_ms + GRAPH_MARGIN + 1;
    const NOTCH_WIDTH = 6;
    const notch_x_left = notch_x_center - NOTCH_WIDTH / 2;
    const notch_x_right = notch_x_center + NOTCH_WIDTH / 2;
    const notch_y_top = 0; //gridCtx.canvas.height - 10
    const notch_y_bottom = notch_y_top + 6;

    gridCtx.fillStyle = "#b00";
    gridCtx.beginPath();
    gridCtx.moveTo(notch_x_left, notch_y_top);
    gridCtx.lineTo(notch_x_right, notch_y_top);
    gridCtx.lineTo(notch_x_center, notch_y_bottom);
    gridCtx.lineTo(notch_x_left, notch_y_top);
    gridCtx.closePath();
    gridCtx.fill();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parm, trigger]);

  return (
    <>
      <Grid item>
        <div style={{ position: "relative" }}>
          <canvas
            ref={gridCanvasRef}
            style={{
              objectFit: "contain",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          ></canvas>
          <canvas
            ref={waveCanvasRef}
            style={{
              objectFit: "contain",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          ></canvas>
        </div>
      </Grid>
    </>
  );
};
