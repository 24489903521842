/* eslint-disable no-whitespace-before-property */
import "../../Waveforms/Waveforms.css";
import "../../Waveforms/Waveforms.css";
import "./LiveUnit.css";
import { Grid } from "@mui/material";
//import {  Typography } from "@mui/material";
//import { useState } from "react";
import { useRef, useEffect } from "react";
import { Waveform } from "../../../interfaces/vitals/Waveform";
import { CanvasDims } from "./LiveUnit";

const waveDrawInfo: { [name: string]: DrawInfo } = {
  ECG_L1: {
    index: 0,
    color: "green",
    y_scalefactor: 1,
    range_min: -1,
    range_max: 1,
  },
  ECG_L2: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  ECG_L3: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  ECG_AVR: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  ECG_AVL: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  ECG_AVF: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  ECG_V: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  ECG_Defi: {
    index: 0,
    color: "green",
    y_scalefactor: 2,
    range_min: -1,
    range_max: 1,
  },
  SpO2: {
    index: 1,
    color: "yellow",
    y_scalefactor: 0.3,
    range_min: -100,
    range_max: 100,
  },
  Resp: {
    index: 2,
    color: "cyan",
    y_scalefactor: 0.25,
    range_min: -127,
    range_max: 128,
  },
  IBP1: {
    index: 3,
    color: "purple",
    y_scalefactor: 0.5,
    range_min: 0,
    range_max: 180,
  },
  IBP2: {
    index: 3,
    color: "purple",
    y_scalefactor: 0.5,
    range_min: 0,
    range_max: 180,
  },
  EtCO2: {
    index: 3,
    color: "pink",
    y_scalefactor: 0.5,
    range_min: 0,
    range_max: 60,
  },
  ACT: {
    index: 3,
    color: "#FFA500",
    y_scalefactor: 0.5,
    range_min: 0,
    range_max: 24,
  },
};

const waveDrawOrder = [
  "ECG_L1",
  "ECG_L2",
  "ECG_L3",
  "ECG_AVR",
  "ECG_AVL",
  "ECG_AVF",
  "ECG_V",
  "ECG_Defi",
  "SpO2",
  "Resp",
  "IBP1",
  "IBP2",
  "EtCO2",
  "ACT",
];

const arrAvg = (arr: number[]) =>
  arr.reduce((a: number, b: number) => a + b, 0) / arr.length;

const STREAM_BUFFER_MAX_SECONDS = 5; // 40 for Chronolife
const STREAM_BUFFER_MIN_SECONDS = 2;
const STREAM_BUFFER_MAX_MS = STREAM_BUFFER_MAX_SECONDS * 1000;
const STREAM_BUFFER_MIN_MS = STREAM_BUFFER_MIN_SECONDS * 1000;

const ECG_WAVEFORMS = [
  "ECG_L1",
  "ECG_L2",
  "ECG_L3",
  "ECG_V",
  "ECG_AVR",
  "ECG_AVL",
  "ECG_AVF",
  "ECG_Defi",
];
const PIXELS_PER_SECOND = 80; // screen dimensions : px / sec
const px_per_ms = PIXELS_PER_SECOND / 1000;
const sec_per_px = 1 / PIXELS_PER_SECOND;
const ms_per_px = sec_per_px * 1000;
const NORMAL_SPEED_DELTA = 1.05;
const HIGH_SPEED_DELTA = 1.2; // SPeed up by this factor if drawing falls behind incoming buffer pace.

export interface LiveWavesProps {
  waves: Waveform[];
  canvasDims: CanvasDims;
  selectedWaves: string[];
}
export interface sampleDict {
  samples: { [name: string]: number };
}

/*
 * Convert sample buffer entries to pixels - scale according to number of pixels per second.
 */
const makePixels = (
  waveform: Waveform,
  pixels_per_second: number,
  swimlane_height: number
) => {
  // console.log ("makePixels:", waveform.name)
  const pixels: number[] = [];
  const samplerate: number = waveform.samplerate;
  const correct_mean: boolean = ECG_WAVEFORMS.includes(waveform.name);
  const samples: number[] = waveform.samples;
  const myDrawInfo = waveDrawInfo[waveform.name];
  const swimlane_center = swimlane_height * 0.5;

  // Pick out which pixels we want based on the viewport resolution.
  // We take evry n'th sample calculated by n = samples per second / pixels per second
  // That is the sample rate divied by the pots per second resolution of the viewport.

  const meanval: number = correct_mean ? arrAvg(samples) : 0;

  if (waveform.name === "ECG_L1") {
    //console.log ("mean:", meanval)
  }
  const value_range = myDrawInfo.range_max - myDrawInfo.range_min;
  const scalefactor = swimlane_height / value_range;

  const check = "IBP1xx";
  if (waveform.name === check) {
    console.log(check + " samples:", samples);
    console.log(check + " range:", value_range, waveform);
    console.log(
      check +
        " samples minmax is " +
        Math.min(...samples) +
        "   " +
        Math.max(...samples)
    );
  }

  var pixel_index = 0;
  const n_samples = waveform.samples.length; // number of samples in buffer
  const samples_per_pixel = samplerate / pixels_per_second; // sample/sec * sec/pixel = sample/pixel
  const pixels_per_sample = 1 / samples_per_pixel;
  const n_pixels = Math.floor(n_samples * pixels_per_sample); // pixels = samples

  // Map samples to pixel values
  while (pixel_index++ < n_pixels) {
    const sample_index = Math.floor(pixel_index * samples_per_pixel);
    if (sample_index > n_samples) {
      console.log("Sample index overrun", sample_index, n_samples);
    }

    const sample_val = samples[Math.floor(sample_index)] - meanval;
    var pixel_y = sample_val * scalefactor + swimlane_center;
    var d0 = 0;
    switch (waveform.name) {
      case "ECG_L1":
      case "ECG_L2":
      case "ECG_L3":
      case "ECG_V":
      case "ECG_AVR":
      case "ECG_AVL":
      case "ECG_AVF":
      case "ECG_Defi": {
        d0 = pixel_y;
        pixels.push(d0);
        break;
      }

      case "SpO2": {
        d0 = pixel_y;
        pixels.push(d0);
        break;
      }
      case "Resp": {
        d0 = pixel_y;
        pixels.push(d0);
        break;
      }

      case "EtCO2": {
        d0 = pixel_y;
        pixels.push(d0);
        break;
      }
      case "ACT": {
        //console.log (sample_val)
        d0 = pixel_y;
        pixels.push(d0);
        break;
      }

      case "IBP1":
      case "IBP2": {
        //const sample_val = samples[Math.floor(sample_index)] - myDrawInfo.range_min;
        //const sample_fraction = sample_val / value_range;
        //d0 = swimlane_height - sample_fraction  * swimlane_height ;
        const sample_val =
          samples[Math.floor(sample_index)] - meanval - myDrawInfo.range_min;
        const px_y = sample_val * scalefactor;
        pixels.push(px_y);
        break;
      }

      default: {
        d0 = (sample_val - myDrawInfo.range_min) * scalefactor;
        pixels.push(-d0);
      }
    }
  }
  if (waveform.name === check) {
    console.log("IBP1 samples:", samples);
    console.log(check + " pixels:", pixels);
    console.log(check + " scalefactor is " + scalefactor);
    console.log(check + " meanval is " + meanval);
    console.log(
      check +
        " pixel range is 0 to " +
        Math.floor(swimlane_height) +
        "   minmax:  " +
        Math.min(...pixels) +
        "   " +
        Math.max(...pixels)
    );
  }

  //console.log ("Made num pixels:", n_px_made)
  return pixels;
}; // makePixels

export interface DrawInfo {
  index: number;
  color: string;
  y_scalefactor: number;
  range_min: number;
  range_max: number;
}

export interface waveBufferType {
  [name: string]: number[];
}

const fastForward = (
  buffers: waveBufferType,
  newLength: number
): waveBufferType => {
  //console.log ("buffers:", buffers)
  Object.entries(buffers).forEach(([key, value]) => {
    if (buffers[key].length > newLength) {
      buffers[key] = value.slice(-newLength);
    }
    //            console.log(key, value)
  });
  //console.log ("buffers after:", buffers)
  return buffers;
};

export const LiveUnitWaveforms = ({
  waves,
  canvasDims,
  selectedWaves,
}: LiveWavesProps) => {
  //console.log ("LiveUnitWaveforms renders", selectedWaves + "  " + Date())
  //console.log (waves)
  const gridCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const waveCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const waveBuffers = useRef<waveBufferType>({});
  const selectedWaveforms = useRef<string[]>([]);
  const currentDrawState = useRef<{
    [name: string]: { prev_x: number; prev_y: number };
  }>({});
  const canvasActualDims = useRef<CanvasDims>({ width: 0, height: 0 });
  const parmBoxWidth = useRef<number>(0);
  const BUFFER_MODE = useRef<boolean>(false);
  const prevTime = useRef(0);
  const pixel_speed_delta = useRef(0);
  const fps = 20;
  const fpsInterval = 1000 / fps;

  // This useEffect transposes the total widget dimensions into the actual canvas dims which we use for drawing.
  useEffect(() => {
    // Get the width of the parameter box, specified in CSS. We subtract this from the overall canvas width
    var r = document.querySelector(":root");
    if (r) {
      var rs = getComputedStyle(r);
      const w: string = rs
        .getPropertyValue("--parameterbox-width")
        .replace("px", "");
      parmBoxWidth.current = +w;
      parmBoxWidth.current = 0;

      // Also update the waveform drawing colors while we're here.
      waveDrawInfo["ECG_L1"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_L2"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_L3"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_AVR"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_AVL"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_AVF"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_V"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["ECG_Defi"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-hr");
      waveDrawInfo["SpO2"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-spo2");
      waveDrawInfo["Resp"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-resp");
      waveDrawInfo["IBP1"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-ibp1");
      waveDrawInfo["IBP2"].color = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--parm-color-ibp1");
    }

    // Size the canvas.

    if (waveCanvasRef.current) {
      const ctx = waveCanvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      if (ctx) {
        ctx.canvas.height = canvasDims.height;
        ctx.canvas.width = canvasDims.width;
      }
    }
    if (gridCanvasRef.current) {
      const ctx = gridCanvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      if (ctx) {
        ctx.canvas.height = canvasDims.height;
        ctx.canvas.width = canvasDims.width;
      }
    }

    initWaveformGrid(selectedWaves);
    canvasActualDims.current = canvasDims;

    //if (canvasDims !== myDims) {
    //    setMyDims (canvasDims)
    //}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasDims, selectedWaves]);

  const initWaveformGrid = (waveforms: string[]) => {
    // console.log ("InitWaveformGrid  " + waveforms + "   " +  waveforms.length)
    if (waveforms.length === 0) {
      return;
    }

    if (!gridCanvasRef.current || !waveCanvasRef.current) {
      return;
    }

    // Correct for the dimensions (width, really) of the paramter box
    gridCanvasRef.current.width = canvasDims.width - parmBoxWidth.current - 1;
    gridCanvasRef.current.height = canvasDims.height - 0;

    waveCanvasRef.current.width = gridCanvasRef.current.width;
    waveCanvasRef.current.height = gridCanvasRef.current.height;

    const ctx = gridCanvasRef.current.getContext("2d", {
      willReadFrequently: true,
    });
    if (!ctx) {
      return;
    }

    // Draw a rectangle
    //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.fillStyle = "rgba(0, 255, 255,0.5)";
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // console.log ("Width in seconds:", gridCanvasRef.current.width, gridCanvasRef.current.width * sec_per_px);
    // Draw vertical lines every one second
    const display_width = gridCanvasRef.current.width;
    const display_height = gridCanvasRef.current.height;
    const pixels_per_second = PIXELS_PER_SECOND;
    const pixels_per_waveform = display_height / selectedWaves.length;

    ctx.strokeStyle = "grey";
    ctx.lineWidth = 1;
    for (var x = 0; x < display_width; x += pixels_per_second) {
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, display_height);
      ctx.stroke();
    }

    // Draw horizontal lines between waveforms
    for (var y = 0; y < display_height; y += pixels_per_waveform) {
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(display_width, y);
      ctx.stroke();
    }

    var swimlane_idx = 0;

    waveDrawOrder.forEach((w_name) => {
      const n_waveforms: number = waveforms.length; // Object.keys(waveBuffers.current).length;

      if (waveforms.includes(w_name)) {
        const SWIMLANE_HEIGHT = canvasActualDims.current.height / n_waveforms;
        const swimlane_offset = swimlane_idx * SWIMLANE_HEIGHT;
        const swimlane_center = SWIMLANE_HEIGHT * 0.5;
        const y_center = swimlane_offset + swimlane_center;

        // Draw the waveform name
        ctx.font = SWIMLANE_HEIGHT > 50 ? "14px Arial" : "12px Arial";
        ctx.fillStyle = "#F2F2F2";
        ctx.textAlign = "left";
        ctx.fillText(w_name, 10, y_center); //- (SWIMLANE_HEIGHT/4));

        swimlane_idx++;
      }
    });
  }; // initWaveformGrid

  useEffect(() => {
    //console.log ("Wave selection changes: ", selectedWaves)
    initWaveformGrid(selectedWaves);

    // Clear out any old data
    waveDrawOrder.forEach((w_name) => {
      waveBuffers.current[w_name] = [];
      currentDrawState.current[w_name] = { prev_x: -1, prev_y: -999 };
    });

    selectedWaveforms.current = selectedWaves;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWaves]);

  //
  // Ingest new waveforms and transform to pixels.
  //
  useEffect(() => {
    // Check if we have changed the selected waveforms. If so, reset the drawing to the new waveforms.
    const incoming_waves: string[] = [];
    waves.forEach((w) => {
      incoming_waves.push(w.name);
    });

    const n_waveforms: number = Object.keys(selectedWaveforms.current).length;

    waves.forEach((w) => {
      const swimlane_height = canvasActualDims.current.height / n_waveforms;
      const pixels_to_add = makePixels(w, PIXELS_PER_SECOND, swimlane_height);
      waveBuffers.current[w.name] = [
        ...waveBuffers.current[w.name],
        ...pixels_to_add,
      ];
    });
  }, [waves]);

  useEffect(() => {
    // Start animation to draw the waveforms
    draw_waveforms();

    // Draw grid
    if (gridCanvasRef.current == null) {
      return;
    }

    const ctx = gridCanvasRef.current.getContext("2d", {
      willReadFrequently: true,
    });
    if (ctx != null) {
      initWaveformGrid(selectedWaveforms.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Debug info
  //var [n_pixels, setn_pixels] = useState (99);
  //var [speed, setSpeed] = useState (99);
  //var [bufms, setBufms] = useState ("99");

  // This function is called at a certain frames per second to do the drawing work.
  const draw_waveforms = () => {
    requestAnimationFrame(draw_waveforms);

    const now: number = Date.now();
    const elapsed = now - prevTime.current;
    var fast_forward_done = false;

    // Calculate how many pixels to draw, and adjust the drawing speed if necessary
    if (elapsed > fpsInterval) {
      const n_pixels_to_draw = Math.floor(
        elapsed * px_per_ms * pixel_speed_delta.current
      );
      prevTime.current = now; // - elapsed;//(elapsed % fpsInterval);
      //console.log ("pixels to draw: " +  n_pixels_to_draw, "   elapsed ms since last draw: " + Math.floor (elapsed))
      //console.log ("n_pixels to draw:", n_pixels_to_draw)

      // Find the lead waveform which paces the drawing methods.
      const LEAD_WAVEFORM = selectedWaveforms.current[0]; // "ECG_L1"

      // Adjust speed according to buffer contents.
      if (waveBuffers.current[LEAD_WAVEFORM]) {
        // Check how many pixels are remaining to be drawn. Also get the same qty in milliseconds.
        const pixels_remaining = waveBuffers.current[LEAD_WAVEFORM].length;
        const ms_remaining = pixels_remaining * ms_per_px;
        const sec_remaining = ms_remaining / 1000;

        //setBufms (sec_remaining.toFixed(2));

        // If we have a significant backlog of data to be drawn, fast-forward. This can happen if a device
        // sends a long burst of packets after congestion.
        fast_forward_done = false;
        if (sec_remaining > STREAM_BUFFER_MAX_SECONDS) {
          const newBufferLength = STREAM_BUFFER_MIN_SECONDS * PIXELS_PER_SECOND;
          waveBuffers.current = fastForward(
            waveBuffers.current,
            newBufferLength
          );
          fast_forward_done = true;
        }

        // If we have accumulated too much un-drawn data, speed up a bit to catch up.
        if (ms_remaining > STREAM_BUFFER_MAX_MS) {
          if (pixel_speed_delta.current !== HIGH_SPEED_DELTA) {
            pixel_speed_delta.current = HIGH_SPEED_DELTA;

            //console.log ("Speed UP:", pixel_speed_delta.current);
            //setSpeed (pixel_speed_delta.current); // debug info
          }
        } // if buffer max overflow

        // If we are runnning out of data to plot (we have caught up), slow down to normal speed.
        if (ms_remaining < STREAM_BUFFER_MIN_MS) {
          if (pixel_speed_delta.current !== NORMAL_SPEED_DELTA) {
            pixel_speed_delta.current = NORMAL_SPEED_DELTA;

            //console.log ("Speed DOWN:", pixel_speed_delta.current);
            //setSpeed (pixel_speed_delta.current); // debug info
          }
        } // if buffer min underflow

        if (BUFFER_MODE.current) {
          // Don't draw anything until we have collected the minimum number of samples to draw as dicatated by stream_buffer
          const n_msec_target = STREAM_BUFFER_MIN_MS;

          if (ms_remaining >= n_msec_target) {
            // We've buffered enough samples, start drawing again.
            //console.log ("Exit buffering mode: ", ms_remaining, pixels_remaining)
            BUFFER_MODE.current = false;
          } else {
            // Keep buffering
            return;
          }
        } else {
          // See if we need to get into buffer mode
          if (pixels_remaining === 0) {
            //set_buffer_mode (true);
            //console.log ("Starting buffering mode")
            BUFFER_MODE.current = true;
            return;
          }
        } // BUFFER_MODE
      } // adjust speed

      if (!waveCanvasRef.current) {
        return;
      }
      const ctx = waveCanvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      if (!ctx) {
        return;
      }
      ctx.lineWidth = 1.0;

      if (!gridCanvasRef.current) {
        return;
      }
      const gridCtx = gridCanvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      if (!gridCtx) {
        return;
      }

      // Draw the waveform segments in their swimlanes
      const n_waveforms: number = selectedWaveforms.current.length;
      var swimlane_idx = 1;

      waveDrawOrder.forEach((w_name) => {
        if (selectedWaveforms.current.includes(w_name)) {
          const SWIMLANE_HEIGHT = canvasActualDims.current.height / n_waveforms;
          const swimlane_offset =
            canvasActualDims.current.height -
            (n_waveforms - swimlane_idx) * SWIMLANE_HEIGHT;
          const swimlane_center = SWIMLANE_HEIGHT * 0.5;
          const y_center = swimlane_offset + swimlane_center;

          const prev_x = currentDrawState.current[w_name].prev_x;
          const prev_y =
            currentDrawState.current[w_name].prev_y === -999
              ? y_center
              : currentDrawState.current[w_name].prev_y;

          if (fast_forward_done) {
            // Draw a red line to indicate that something happened here.
            ctx.strokeStyle = "grey";
            ctx.lineWidth = 2;
            ctx.beginPath();
            const offset_gap = 10;
            ctx.moveTo(prev_x, offset_gap);
            ctx.lineTo(prev_x, gridCtx.canvas.height - offset_gap);
            ctx.stroke();

            // Also show a small arrow sign on the top
            ctx.font = "16px Arial";
            ctx.fillStyle = "grey";
            ctx.textAlign = "right";
            ctx.fillText(">", prev_x, offset_gap + 10);

            fast_forward_done = false;
          }

          // Prep the context
          ctx.strokeStyle = waveDrawInfo[w_name].color;
          ctx.lineWidth = 1.5;

          // Move to the previous pixel
          ctx.beginPath();
          ctx.moveTo(prev_x, prev_y);

          // Start drawing the pixels
          var draw_x = prev_x;
          var draw_y = y_center;

          const thisBuffer = waveBuffers.current[w_name];

          //setn_pixels (selectedWaveforms.current.length > 0 ?  waveBuffers.current [selectedWaveforms.current [0]].length : -99)
          //const len_before = thisBuffer.length;
          //console.log ("Buffer length before:", len_before)
          if (w_name === "ACT") {
            //console.log ("act", thisBuffer)
          }
          for (var px_idx = 0; px_idx < n_pixels_to_draw; px_idx++) {
            // Advance to the next pixel
            draw_x += 1;
            if (
              gridCanvasRef?.current &&
              draw_x > gridCanvasRef.current.width
            ) {
              ctx.stroke();
              draw_x = 0;
              ctx.beginPath();
              ctx.moveTo(0, draw_y);
            }

            // Get the calculated pixel value
            const y = thisBuffer.shift();

            if (y !== undefined) {
              // Adjust for the swimlane offset
              draw_y = swimlane_offset - y;
              ctx.lineTo(draw_x, draw_y);
            } else {
              draw_y = prev_y;
            }
          } // for all pixels
          //const len_after = thisBuffer.length;
          //console.log ("Buffer length after:", len_after)

          ctx.stroke();

          currentDrawState.current[w_name].prev_x = draw_x;
          currentDrawState.current[w_name].prev_y = draw_y;

          // Draw a few pixels gap after the last one.
          const PIXEL_GAP = 25;
          ctx.fillStyle = "#000000";

          // Create a gradient fillstyle
          var grd = ctx.createLinearGradient(draw_x, 0, draw_x + PIXEL_GAP, 0);
          grd.addColorStop(0, "rgba(0,0,0, 1.0)");
          grd.addColorStop(1, "rgba(0,0,0, 0.0)");

          // Fill with gradient
          ctx.fillStyle = grd;

          var imgData = gridCtx.getImageData(
            draw_x,
            0,
            PIXEL_GAP,
            gridCtx.canvas.height
          );
          ctx.putImageData(imgData, draw_x, 0);

          //console.log ("Buffer after draw:", waveBuffers.current [w_name].length)
          swimlane_idx++;
        } // if not undefined
      });
    }
  };
  //console.log ("selected: ", selectedWaveforms.current)
  return (
    <div>
      <Grid item>
        <div style={{ position: "relative" }}>
          <canvas
            ref={gridCanvasRef}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          ></canvas>
          <canvas
            ref={waveCanvasRef}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          ></canvas>
        </div>
      </Grid>
    </div>
  );
}; // LiveUnitWaveforms
