import { useContext, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  Link,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { UserContext } from "../../context/UserContext";
import { Credentials } from "../../interfaces/user/Credentials";
import { Token } from "../../interfaces/user/Token";
import { User } from "../../interfaces/user/User";
import LoginService from "../../services/LoginService";
import UserService from "../../services/UserService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/infiniwell_PMS_logo-id.png";
import { DialogConfirm } from "../../components/DialogConfirm";
import { MFA } from "../../interfaces/user/MFA";
import { isMobile } from "react-device-detect";

export const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ephemeralToken, setEphemeralToken] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showMFAfield, setShowMFAfield] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [, setCookie] = useCookies();
  const userCtx = useContext(UserContext);
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();

  const login = (event: any) => {
    event.preventDefault();
    const credentials: Credentials = {
      username: username,
      password: password,
    };
    LoginService.login(credentials)
      .then((response: Token) => {
        if (response.access && response.refresh) {
          UserService.getMe(response.access).then((user: User) => {
            console.log("data from getme:", user);
            var savedUser = localStorage.getItem("user");
            var autolog = "15"; // default to 15 minutes
            let is_auto_logout_enabled = true;
            if (savedUser) {
              const jj = JSON.parse(savedUser);
              autolog = jj.autoLogout;
            }
            console.log("Login: autoLogout is " + autolog + " minutes");

            user = {
              ...user,
              autoLogout: autolog,
              is_auto_logout_enabled: is_auto_logout_enabled,
            };
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                token: response,
                mfaEnabled: false,
              })
            );
            userCtx?.setUser({
              ...user,
              token: response,
              mfaEnabled: false,
            });
          });
          let date = new Date();

          // @ts-ignore
          setCookie("access_token", response.access, {
            expires: new Date(date.setHours(date.getHours() + 24)),
          });
          // @ts-ignore
          setCookie("refresh_token", response.refresh, {
            expires: date,
          });

          history.push("/");
        } else if (response.ephemeral_token) {
          setEphemeralToken(response.ephemeral_token);
          setShowMFAfield(true);
        }
      })
      .catch((err: any) => {
        setError(true);
      });
  };

  const loginMFA = () => {
    const credentials: MFA = {
      ephemeral_token: ephemeralToken,
      code: otp,
    };
    LoginService.otp(credentials)
      .then((response: Token) => {
        if (response.access && response.refresh) {
          UserService.getMe(response.access).then((user: User) => {
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                token: response,
                mfaEnabled: true,
              })
            );
            userCtx?.setUser({
              ...user,
              token: response,
              mfaEnabled: true,
            });
          });
          let date = new Date();

          // @ts-ignore
          setCookie("access_token", response.access, {
            expires: new Date(date.setHours(date.getHours() + 24)),
          });
          // @ts-ignore
          setCookie("refresh_token", response.refresh, {
            expires: date,
          });

          history.push("/");
        } else {
          setError(true);
        }
      })
      .catch((err: any) => {
        setError(true);
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="75vh"
    >
      <Grid item>
        <img alt="Infiniwell-logo" src={logo} width="300" height="210" />
      </Grid>
      <Grid item>
        <form onSubmit={login}>
          <Grid
            container
            item
            mt={5}
            justifyContent={"space-around"}
            columnSpacing={1}
          >
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                id="username"
                value={username}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUsername(event.target.value)
                }
                label={t("username")}
                variant="outlined"
                error={error}
                InputLabelProps={{
                  style: {
                    color: theme.palette.text.primary,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                sx={{ mt: isMobile ? 1 : 0 }}
                fullWidth
                id="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(event.target.value)
                }
                label={t("password")}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    color: theme.palette.text.primary,
                  },
                }}
                error={error}
              />
              <Grid item mt={1}>
                <Link underline="hover" href="/forgot-password">
                  {t("forgot_password?")}
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                sx={{ mt: 2.3 }}
                color="primary"
                type="submit"
                variant="contained"
              >
                {t("login")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <DialogConfirm
        title={"Two Factor Authentication"}
        open={showMFAfield}
        setOpen={setShowMFAfield}
        text={
          "You have enable MFA for your account. You have received an email with a code to verify your login."
        }
        func={loginMFA}
        funcSec={login}
        textField={true}
        value={otp}
        setValue={setOtp}
        error={error}
      />
    </Grid>
  );
};
