import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { patientVitalsColor } from "../../utils/data/PatientVitalsColor";
import { CustomRow } from "../CustomRow";
import { ParameterBox } from "./ParameterBox";
import { DeviceAlarm } from "./DeviceAlarm";
import { useTranslation } from "react-i18next";

interface Props {
  parameter: any;
  connectStatus: string;
  compact?: boolean;
}

export const Parameter = ({
  parameter,
  connectStatus,
  compact = false,
}: Props) => {
  const paramsMap = ["IBP1", "IBP2", "NIBP"];
  const paramsRespAndTemp = ["Resp", "Temp"];

  const [colors] = useState(patientVitalsColor);
  const [color, setColor] = useState<string>("#FFFFFF");
  const { t } = useTranslation();

  useEffect(() => {
    if (parameter) {
      for (const [key, value] of Object.entries(colors)) {
        if (key === parameter.param_name) setColor(value);
      }
    }
  }, [colors, parameter.param_name, parameter]);

  if (compact) {
    if (paramsMap.includes(parameter.param_name)) {
      const systole =
        parameter.systole?.value && connectStatus === "connected"
          ? parameter.systole?.value
          : "--";
      const diastole =
        parameter.diastole?.value && connectStatus === "connected"
          ? parameter.diastole?.value
          : "--";
      return (
        <Grid
          item
          xs={3}
          className={
            parameter.active_alarm
              ? "parameter-value-alarm"
              : "parameter-value-normal"
          }
        >
          <Typography variant="body2" color={color}>
            {parameter.param_name}: {systole + "/" + diastole}
          </Typography>
        </Grid>
      );
    } else if (paramsRespAndTemp.includes(parameter.param_name)) {
      let value = "--";
      if (parameter.param_name === "Resp") {
        value =
          parameter.rr && connectStatus === "connected"
            ? parameter.rr.value
            : "--";
      } else if (parameter.param_name === "Temp") {
        value =
          parameter.t1 && connectStatus === "connected"
            ? parameter.t1.value
            : "--";
      }
      return (
        <Grid
          item
          xs={3}
          className={
            parameter.active_alarm
              ? "parameter-value-alarm"
              : "parameter-value-normal"
          }
        >
          <Typography variant="body2" color={color}>
            {parameter.param_name}: {value}
          </Typography>
        </Grid>
      );
    }
    const obj = parameter[Object.keys(parameter)[0]];

    let val = "--";
    if (parameter.value) val = parameter.value;
    else if (obj !== undefined) {
      // @ts-ignore
      val = obj[Object.keys(obj)[2]];
    }

    return (
      <Grid
        item
        xs={3}
        className={
          parameter.active_alarm
            ? "parameter-value-alarm"
            : "parameter-value-normal"
        }
      >
        <Typography variant="body2" color={color}>
          {parameter.param_name}: {val}
        </Typography>
      </Grid>
    );
  }

  if (paramsMap.includes(parameter.param_name)) {
    const systole =
      parameter.systole?.value && connectStatus === "connected"
        ? parameter.systole?.value
        : "--";
    const diastole =
      parameter.diastole?.value && connectStatus === "connected"
        ? parameter.diastole?.value
        : "--";
    const map =
      parameter.map?.value && connectStatus === "connected"
        ? parameter.map?.value
        : "--";
    return (
      <ParameterBox
        activeAlarm={parameter.active_alarm && connectStatus === "connected"}
      >
        <CustomRow>
          <Typography variant="h6" color={color}>
            {parameter.param_name}
          </Typography>
          <Typography
            variant="h6"
            color={color}
            id={
              parameter.param_name === "IBP1"
                ? "parm_ibp1_art"
                : "parm_ibp2_art"
            }
          >
            {systole + " / " + diastole}
          </Typography>
        </CustomRow>
        <Typography
          color={color}
          id={
            parameter.param_name === "IBP1" ? "parm_ibp1_map" : "parm_ibp2_map"
          }
        >
          Map: {map}
        </Typography>
        <DeviceAlarm parameter={parameter} connectStatus={connectStatus} />
      </ParameterBox>
    );
  } else if (paramsRespAndTemp.includes(parameter.param_name)) {
    return (
      <ParameterBox
        activeAlarm={parameter.active_alarm && connectStatus === "connected"}
      >
        <CustomRow>
          <Typography variant="h6" color={color}>
            {parameter.param_name}
          </Typography>
          <Grid>
            <Typography color={color} variant="body1">
              {(parameter.co2?.text && "CO2") ||
                (parameter.t1?.text && "T1 °" + parameter.t1.unit)}
            </Typography>
            <Typography color={color} variant="body1">
              {(parameter.rr?.text && "RR") ||
                (parameter.t2?.text && "T2 °" + parameter.t2.unit)}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              color={color}
              variant="body1"
              id={parameter.param_name === "Temp" ? "parm_temp1" : "parm_etco2"}
            >
              {connectStatus === "connected" &&
                (parameter.co2?.value || parameter.t1?.value)}
            </Typography>
            <Typography
              color={color}
              variant="body1"
              id={parameter.param_name === "Temp" ? "parm_temp2" : "parm_rr"}
            >
              {connectStatus === "connected" &&
                parameter.param_name === "Temp" &&
                parameter.t2?.value}
              {connectStatus === "connected" &&
                parameter.param_name !== "Temp" &&
                parameter.rr?.value}
            </Typography>
          </Grid>
        </CustomRow>
        <DeviceAlarm parameter={parameter} connectStatus={connectStatus} />
      </ParameterBox>
    );
  } else if ("SpO2" === parameter.param_name) {
    return (
      <ParameterBox
        activeAlarm={parameter.active_alarm && connectStatus === "connected"}
      >
        <CustomRow>
          <Typography variant="h6" color={color}>
            {parameter.param_name}
          </Typography>
          <Typography variant="h6" color={color} id="parm_spo2">
            {parameter.sp?.value && connectStatus === "connected"
              ? parameter.sp?.value
              : "--"}
          </Typography>
        </CustomRow>
        <CustomRow>
          <Typography color={color}>Perf %</Typography>
          <Typography color={color} id="parm_perf">
            {parameter.perf?.value && connectStatus === "connected"
              ? parameter.perf?.value
              : "--"}
          </Typography>
        </CustomRow>
        <DeviceAlarm parameter={parameter} connectStatus={connectStatus} />
      </ParameterBox>
    );
  }
  return (
    <ParameterBox
      activeAlarm={parameter.active_alarm && connectStatus === "connected"}
    >
      <CustomRow>
        <Typography variant="h6" color={color}>
          {t(parameter.param_name)}
        </Typography>
        <Typography variant="h6" color={color} id="parm_hr">
          {connectStatus === "connected" ? parameter.value : "--"}
        </Typography>
      </CustomRow>
      <DeviceAlarm parameter={parameter} connectStatus={connectStatus} />
    </ParameterBox>
  );
};
