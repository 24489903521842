import React, { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTabs from "../components/tabs/CustomTabs";
import { Event } from "../pages/WaveMonitor";
import EventTab from "./EventTab";

interface Props {
  alarmList: Event[];
  eventList: Event[];
  ecgList: Event[];
  msgList: Event[];
  imageList: Event[];
  onEventSelected: (event: Event) => void;
  style?: React.CSSProperties; // add this line
}

export const EventTable: React.FC<Props> = ({
  alarmList,
  eventList,
  ecgList,
  msgList,
  imageList,
  onEventSelected,
}) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  //const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
  //  setActiveTab(newValue);
  //};

  //console.log (imageList)
  return (
    <Box sx={{ width: "100%" }}>
      <CustomTabs
        tabNames={[t("Events"), t("ECG"), t("Msg"), t("Images")]}
        tabComponents={[
          <EventTab events={eventList} onEventSelected={onEventSelected} />,
          <EventTab events={ecgList} onEventSelected={onEventSelected} />,
          <EventTab events={msgList} onEventSelected={onEventSelected} />,
          <EventTab events={imageList} onEventSelected={onEventSelected} />,
        ]}
        value={activeTab}
        onTabChange={setActiveTab}
      />
    </Box>
  );
};

export default EventTable;
