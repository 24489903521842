import { ReactNode, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import { UserContext } from "../../context/UserContext";
import { Grid, IconButton, Typography, useTheme } from "@mui/material";
// @ts-ignore
import logo from "../../assets/images/infiniwell_PMS_logo-symbol.png";
import { useCookies } from "react-cookie";
import { ThemeContext } from "../../context/ThemeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import "./CMSWrapper.css";
import Navbar from "./CMSNavbar";
import SysAdminNavbar from "../../pages/SysAdmin/SysAdminNavBar";
//import { useAutoLogout } from "../../hooks/useAutoLogout";
import { isBrowser } from "react-device-detect";

interface Props {
  children: ReactNode;
}

export const CMSWrapper = ({ children }: Props) => {
  console.log("CMSWrapper executes");
  const userCtx = useContext(UserContext);
  const [cookies] = useCookies(["access_token", "refresh_token"]);
  const themeCtx = useContext(ThemeContext);
  const history = useHistory();
  const theme = useTheme();

  //const { handleMouseMove } = useAutoLogout();

  useEffect(() => {
    console.log("userCtx changed:", userCtx);
    // @ts-ignore
    let user = JSON.parse(localStorage.getItem("user"));

    if (cookies.access_token && user && !userCtx?.user) userCtx?.setUser(user);

    // if (cookies.refresh_token && !userCtx?.user) {
    //     removeCookie("access_token");
    //     removeCookie("refresh_token");
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx]);

  const colorMode = () => {
    themeCtx.toggleColorMode();
    const themeTo = theme.palette.mode === "light" ? "dark" : "light";
    localStorage.setItem("mode", themeTo);
  };

  /*
   * If user is logged in, show the navbar etc.
   */
  if (userCtx?.user) {
    console.log("Context: user:", userCtx.user);
    return (
      // Grid for the whole screen
      <Grid
        sx={{
          height: "max-content",
          minHeight: "100vh",
          bgcolor: theme.palette.background.default,
        }}
        // BUG!!!! onMouseMove={() => handleMouseMove()}
      >
        {!userCtx?.user.is_superuser && <Navbar />}
        {userCtx?.user.is_superuser && <SysAdminNavbar />}

        <Grid
          sx={{
            margin: isBrowser ? "2rem" : "0.5rem",
            bgcolor: theme.palette.background.default,
          }}
        >
          {children}
        </Grid>
      </Grid>
    );
  } else {
    /*
     * User has not been logged in. Show login screen.
     */
    return (
      <Grid
        sx={{
          height: "100vh",
          bgcolor: theme.palette.background.default,
        }}
      >
        <AppBar
          position="sticky"
          sx={{
            bgcolor: theme.palette.background.default,
            paddingBottom: "1rem",
          }}
        >
          <div className="header">
            <img
              className="logo"
              alt="Infiniwell-logo"
              src={logo}
              width="55"
              height="30"
              onClick={() => history.push("/")}
            />
            <Typography
              color={theme.palette.text.primary}
              variant="h5"
              sx={{ textAlign: "center" }}
            >
              Hospital has left the building
            </Typography>
            <IconButton sx={{ ml: 1 }} onClick={colorMode} color="primary">
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </div>
        </AppBar>
        <Grid
          sx={{
            padding: isBrowser ? "2rem" : "0.5rem",
            bgcolor: theme.palette.background.default,
          }}
        >
          {children}
        </Grid>
      </Grid>
    );
  }
};
