import { Grid, Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../../hooks/useFetch";
import { Device } from "../../../interfaces/Device";
import TrendService from "../../../services/TrendService";
import DeviceService from "../../../services/DeviceService";
//import { patientVitalsColor } from "../../../utils/data/PatientVitalsColor";
import { TrendChart } from "./TrendChart";
import "./TrendUnit.css";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
//import {
//    useMotionValue,
//} from "framer-motion/dist/framer-motion";
//import { useRaisedShadow } from "./use-raised-shadow";
import SelectParameters from "../../../components/device/SelectParameters";
import { PatientInfo } from "../Live/LiveUnit";
import { LiveUnitHeader } from "../../../components/device/LiveUnitHeader";
//import { UserContext } from "../../../context/UserContext";
import { Loading } from "../../../components/Loading";
import { useTranslation } from "react-i18next";

interface Props {
  device: Device;
  hideTrend?: boolean;
  type: string;
  useLink: boolean;
  connectStatus?: string;
  fullWidth?: boolean;
}

export const TrendUnit = ({
  device,
  hideTrend,
  type,
  useLink,
  connectStatus,
  fullWidth,
}: Props) => {
  // console.log ("TrendUnit renders... will fetch data")
  //const y = useMotionValue(0);
  //const boxShadow = useRaisedShadow(y);
  //const dragControls = useDragControls();
  const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>({
    last_name: "-",
    first_name: "-",
    bed_no: "-",
  });
  const [cookies, ,] = useCookies(["access_token"]);
  //const [colors] = useState(patientVitalsColor());
  const { t } = useTranslation();

  var token = cookies.access_token; //userCtx?.user?.token;

  //
  // Retrieve trend data from back-end.
  //
  const { data, loading } = useFetch({
    url: TrendService.getTrend48Hours(),
    access_token: token,
    id: device?.device_id,
  });

  //
  // Retrieve the last recorded parameter from back-end - it has the patient info in it.
  //
  const { data: parm, loading: parm_loading } = useFetch({
    url: DeviceService.getLatestParameter(),
    access_token: token,
    id: device?.device_id,
  });

  useEffect(() => {
    let isMounted = true;
    if (parm) {
      const patientData = parm?.patient;
      if (patientData != null && isMounted) {
        setPatientInfo(patientData);
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parm]);

  const multipleParams = ["IBP1", "IBP2", "NIBP"];
  const standardParams = ["HR", "SpO2", "Resp", "Temp1"];

  const savedParams = JSON.parse(
    // @ts-ignore
    // localStorage.getItem("trend-" + device?.device_id)
    localStorage.getItem(type + "-" + device?.device_id)
  );

  const [selectableParameters, setSelectableParameters] = useState<string[]>(
    []
  );

  const [selectedParameters, setSelectedParameters] = useState<string[]>(
    savedParams || ["HR"]
  );

  useEffect(() => {
    let isMounted = true;
    if (data) {
      let visitedParams: string[] = [];
      let parameters = data.device_def.parameters;
      if (parameters) {
        Object.keys(parameters).forEach((key) => {
          let temp = key;
          if (temp.includes("_")) temp = temp.split("_")[0];
          else if (temp === "SPO2") temp = "SpO2";
          else if (temp === "RR") temp = "Resp";
          else if (temp === "Posture") return;

          if (!visitedParams.includes(temp) && isMounted) {
            setSelectableParameters((prev) => [...prev, temp]);
            visitedParams.push(temp);
          }
        });
      }

      if (!savedParams && isMounted) {
        setSelectedParameters(() => {
          if (visitedParams.length > 4) {
            return standardParams.filter((param, index) =>
              visitedParams.includes(param)
            );
          } else return visitedParams;
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const theme = useTheme();

  if (loading || data === null || parm_loading || parm === null)
    return <Loading />;

  const series = selectedParameters?.map((name) => {
    return {
      name:
        data && data.data[0] && !multipleParams.includes(name)
          ? t(
              Object.keys(data.data[0]["data"])[
                Object.keys(data.data[0]["data"]).indexOf(name)
              ]
            )
          : t(name),
      data: data?.data?.map((datapoint: any) => {
        if (multipleParams.includes(name)) {
          return {
            x: datapoint["timestamp"],
            y: datapoint["data"][name + "_Systole"],
            meta: {
              systole: datapoint["data"][name + "_Systole"],
              diastole: datapoint["data"][name + "_Diastole"],
              map: datapoint["data"][name + "_Map"],
            },
          };
        }
        return {
          x: datapoint["timestamp"],
          y: datapoint["data"][name],
        };
      }),
    };
  }) as ApexAxisChartSeries;

  const trendChartOptions = {
    // @ts-ignore
    //colors: selectedParameters?.map((name) => colors[name]),
    stroke: {
      width: 2,
      curve: "smooth",
    },
    title: {
      text: t("trend_for_the_last_48_hours"),
      align: "left",
      style: {
        fontSize: "11px",
      },
    },
    chart: {
      background: theme.palette.common.black,
      foreColor: theme.palette.common.white,
      id: device.device_id + "-chartArea",
      animations: {
        enabled: false,
      },
      events: {
        mounted: (chart: any) => {
          chart.windowResizeHandler();
        },
        mouseMove: (_: any, __: any, config: any) => {
          const dp = config.dataPointIndex;

          selectedParameters.forEach((param) => {
            const element = document.getElementById(
              device.device_id + "-" + param
            );
            if (
              multipleParams.includes(param) &&
              element &&
              config?.config?.series
            ) {
              let val = config?.config?.series.find(
                (p: any) => p.name === param
              );
              element.innerHTML = val?.data[dp]?.meta
                ? param +
                  ": " +
                  val?.data[dp]?.meta.systole +
                  "/" +
                  val?.data[dp]?.meta.diastole
                : param + ": --";
            } else if (element && config?.config?.series) {
              let val = config?.config?.series.find(
                (p: any) => p.name === param
              );
              element.innerHTML = val?.data[dp]?.y
                ? param + ": " + val?.data[dp]?.y
                : param + ": --";
            }
          });

          let el = document.getElementById(device.device_id + "-timestamp");
          if (el && config?.config?.series[0]?.data[dp]) {
            el.innerHTML = moment(config?.config?.series[0]?.data[dp].x)
              .format("ddd HH:mm")
              .toString();
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val?.toFixed(0);
        },
      },
    },
    selection: {
      fill: {
        color: theme.palette.primary.main,
        opacity: 0.5,
      },
      enabled: true,
      xaxis: {
        min: moment().subtract(15, "minutes").valueOf(),
        max: new Date().getTime(),
      },
    },
    events: {
      mounted: (chart: any) => {
        chart.windowResizeHandler();
      },
    },
  } as ApexOptions;

  const navChartOptions: ApexOptions = {
    chart: {
      width: "100%",
      background: theme.palette.common.black,
      foreColor: theme.palette.common.white,
      animations: {
        enabled: false,
      },
      id: device.device_id + "-chartBrush",
      brush: {
        target: device.device_id + "-chartArea",
        enabled: true,
      },
      selection: {
        fill: {
          color: theme.palette.primary.main,
          opacity: 0.5,
        },
        enabled: true,
        xaxis: {
          min: moment().subtract(15, "minutes").valueOf(),
          max: new Date().getTime(),
        },
      },
    },
    markers: {
      size: 0,
    },
    // @xxxxts-ignore
    //colors: selectedParameters?.map((name) => colors[name]),
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      tickAmount: 2,
    },
    legend: {
      show: false,
    },
  };

  if (!data && hideTrend) return null;

  //    if ((device.device_id === "EmptxxxyDevice") || (device.device_id === "Odd-Dev-1033")){
  //        console.log ("data:", data)
  //        console.log ("parm:", parm)
  //        console.log ("series:", series)
  //
  //    }
  return (
    <Grid item md={fullWidth ?? 6}>
      <Box>
        <Grid
          item
          xs={12}
          sx={{ marginBottom: data ? "10px" : "0px", padding: "3px" }}
        >
          <LiveUnitHeader
            key={device.device_id}
            device={device}
            patientInfo={patientInfo}
            useLink={useLink}
            selectParams={
              <SelectParameters
                deviceId={device.device_id}
                selectableParameters={selectableParameters}
                selectedParameters={selectedParameters}
                setSelectedParameters={setSelectedParameters}
                maxParameters={4}
                type={type}
              />
            }
          />
          <Grid container height="100%" width="100%">
            <Grid
              item
              container
              xs={data?.length > 0 ? 10 : 12}
              height="100%"
              width="400"
            >
              <TrendChart
                loading={loading}
                height="100%"
                options={trendChartOptions}
                series={series}
              />
            </Grid>

            {!loading && data && data.data[0] && (
              <Chart
                options={navChartOptions}
                series={series}
                type="area"
                height="100"
                style={{ width: "100%" }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
