export const HOST = process.env.REACT_APP_SERVER;

// export const HOST =
//     process.env.NODE_ENV === "development"
//         ? process.env.REACT_APP_SERVER
//         : window.location.href;

export const WS_HOST = () => {
  if (HOST!.substring(0, 5) === "https") {
    return HOST!.replace("https://", "wss://");
  }
  return HOST!.replace("http://", "ws://");
};

export const DEFAULT_AUTOLOGOUT_MINUTES = 15; // 15 minutes default for auto-logout after no activity
export const DEFAULT_AUTOLOGOUT_SECONDS = DEFAULT_AUTOLOGOUT_MINUTES * 60;
export const CONNECTIVITY_TIMEOUT = 30 * 1000;

export const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
export const isSentio = localStorage.getItem("SYSTEM_TYPE") === "SENTIO";
