//import {
//    useTheme,
//} from "@mui/material";
//
//import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { DeviceTable } from "../../pages/Home/DeviceTable";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";

export const DeviceManagement = () => {
  //const theme = useTheme();
  //const { t } = useTranslation();
  const [cookies, ,] = useCookies(["access_token"]);
  var token = cookies.access_token; // userCtx.user.token;

  // Retrieve all devices for this organization the when the CMSDashboard renders.
  // Should only happen once, as this is the top-level component.
  const {
    data: deviceList,
    loading: deviceLoading,
    error: deviceError,
  } = useFetch({
    url: DeviceService.getDevices(),
    access_token: token,
  });

  // One-time on component load: retrieve alarm profiles from back-end
  const {
    data: alarmProfiles,
    loading: almLoading,
    error: almError,
  } = useFetch({
    url: DeviceService.getTypes(),
    access_token: token,
  });

  if (almLoading || deviceLoading) {
    return <Loading />;
  }
  if (almError || deviceError) {
    return <ServerProblem />;
  }

  console.log("Retrieve for devicetable:", deviceList);
  return (
    <div>
      <DeviceTable devicelist={deviceList} alarmProp={alarmProfiles} />
    </div>
  );
};
