import L, { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { Marker, Popup, useMap, Tooltip } from "react-leaflet";
import DeviceMonitor from "../../../components/device/DeviceMonitor";
import useVitalsSocket from "../../../hooks/useVitalsSocket";
import { Device } from "../../../interfaces/Device";
import { memo } from "react";
import "./DeviceMarker.css";
import { parmMap } from "../../Home/LiveDashboard";

interface Props {
  device: Device;
  setDevices?: (devices: Device[]) => void;
}
const DeviceMarker = ({ device, setDevices }: Props) => {
  const { gpsLocation } = useVitalsSocket({
    device,
  });

  const [gpsLocationFound, setGpsLocationFound] = useState(false);

  const map = useMap();

  useEffect(() => {
    if (gpsLocation && !gpsLocationFound) {
      const visibleMarkers: { getLatLng: () => LatLngExpression }[] = [];
      map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          visibleMarkers.push(layer);
        }
      });

      // Ensure there's at least one visible Marker
      if (visibleMarkers.length > 0) {
        // Create bounds from first Marker then extend it with the rest
        const markersBounds = L.latLngBounds([visibleMarkers[0].getLatLng()]);
        visibleMarkers.forEach((marker) => {
          markersBounds.extend(marker.getLatLng());
        });

        // Fit the map with the visible markers bounds
        map.flyToBounds(markersBounds, {
          padding: L.point(36, 36),
          animate: true,
        });
      }
      setGpsLocationFound(true);
    }
  }, [gpsLocation, gpsLocationFound, map]);
  // console.log ("map:", device)
  const availableParams = parmMap[device.device_model]
    ? parmMap[device.device_model].parms
    : [];

  return (
    <>
      {gpsLocation && (
        <Marker position={[gpsLocation.lat, gpsLocation.lng]}>
          <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>
            {device.device_id}
          </Tooltip>

          <Popup minWidth={400} autoClose={false}>
            <DeviceMonitor
              device={device}
              setDevices={setDevices}
              compact
              fullWidth
              type="mapmonitor"
              availableParams={availableParams}
            />
          </Popup>
        </Marker>
      )}
    </>
  );
};

export default memo(DeviceMarker);
