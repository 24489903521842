import { Grid, Typography, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { Loading } from "../../../components/Loading";

interface Props {
  options: ApexOptions;
  series: ApexAxisChartSeries;
  height?: string;
  type?: any;
  loading?: boolean;
}
export const TrendChart = ({
  options,
  series,
  height,
  type,
  loading,
}: Props) => {
  const theme = useTheme();
  if (loading) return <Loading />;

  if (!series[0]?.data || series[0]?.data.length === 0) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        height={105}
        bgcolor="black"
      >
        <Grid item>
          <Typography variant="h5" color={theme.palette.text.secondary}>
            No trend data available
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (series) {
    return (
      <Chart
        options={options}
        series={series}
        type={type ? type : "line"}
        height={height}
        style={{ width: "100%" }}
      />
    );
  }
  return null;
};
