import { useEffect, useState } from "react";

const axios = require("axios").default;

interface Props {
  url: string | null;
  access_token: string;
  id?: string;
}
const useFetch = ({ url, access_token, id }: Props) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const [reFetch, setRefetch] = useState<boolean>(false);

  const refresh = () => {
    // Toggle the reFetch state to trigger a new fetch
    setRefetch((prev) => !prev);
  };

  useEffect(() => {
    //console.log ("Fetching for " + id , url, " with ", access_token)
    if (!url) return;
    let isMounted = true;
    if (isMounted) {
      setLoading(true);
      setData(null);
    }

    var target_url = url;
    if (url.includes("REPLACE")) {
      let newUrl = url.split("REPLACE");
      target_url = newUrl[0] + id + newUrl[1];
    }
    axios
      .get(target_url, {
        headers: {
          authorization: "Bearer " + access_token,
          "Content-Type": "text/plain",
        },
      })
      .then((response: any) => {
        if (isMounted) setData(response.data);
        //console.log ("Response data:", response.data)
      })
      .catch((error: any) => {
        console.log("Fetch error response:", error);
        console.log("                data:", data);
        if (isMounted) setError(error);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, url, reFetch]);
  return { data, setData, loading, error, reFetch, refresh };
};

export default useFetch;
