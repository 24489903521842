import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  activeAlarm?: boolean;
}

export const ParameterBox = ({ children, activeAlarm }: Props) => {
  const height = "85px";

  return (
    <Grid
      className={activeAlarm ? "parameter-box-alarm" : "parameter-box-normal"}
      sx={{
        bgcolor: "common.black",
        minHeight: height,
        border: activeAlarm ? "solid 2px red" : "solid 1px white",
        padding: "3px",
      }}
      container
      direction="column"
      justifyContent="space-between"
    >
      {children}
    </Grid>
  );
};
