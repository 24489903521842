import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material";

import { Event } from "../pages/WaveMonitor";
import "../pages/Home/Live/LiveUnit.css";

interface Props {
  events: Event[];
  onEventSelected: (event: Event) => void;
}

const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad single-digit hours, minutes, or seconds with a leading zero
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const EventTab: React.FC<Props> = ({ events, onEventSelected }) => {
  const [selectedEvent, setSelectedEvent] = React.useState<Event | null>(null);
  const theme = useTheme();
  console.log("event tab:", events);
  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
    onEventSelected(event);
  };

  //console.log (events)
  return (
    <TableContainer component={Paper}>
      <Table aria-label="table for header">
        <TableHead className="monitorHeader">
          <TableRow>
            <TableCell
              style={{ width: "20%", color: theme.palette.common.white }}
            >
              Timestamp
            </TableCell>
            <TableCell
              style={{ color: theme.palette.common.white }}
              align="left"
            >
              Description
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <TableContainer style={{ maxHeight: 300, overflow: "auto" }}>
        <Table size="small" aria-label="table for body">
          <TableBody>
            {events.map((event) => {
              if (event.eventData === "") {
                //return;
              }
              const eventData =
                event.eventData !== "" ? JSON.parse(event.eventData) : null;
              return (
                <TableRow
                  key={event.timestamp.getTime() + event.description}
                  selected={selectedEvent === event}
                  onClick={() => handleEventClick(event)}
                >
                  <TableCell
                    style={{ width: "20%" }}
                    component="th"
                    scope="row"
                  >
                    {formatTime(event.timestamp)}
                  </TableCell>
                  <TableCell align="left">{event.description}</TableCell>
                  {event.eventType === "image" && eventData && (
                    <TableCell align="right">
                      <img
                        src={`data:image/jpeg;base64,${eventData.thumbnail}`}
                        alt="Thumbnail"
                        style={{ width: "50px", height: "auto" }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </TableContainer>
  );
};

export default EventTab;
