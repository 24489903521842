import {
  Menu,
  MenuItem,
  Grid,
  Typography,
  TextField,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";

interface Props {
  parameter: any;
  alarms: any[];
  setAlarms: (alarms: any[]) => void;
  setIsEditingThresholds: (isEditingThreshold: boolean) => void;
}

export const MenuButton = ({
  parameter,
  alarms,
  setAlarms,
  setIsEditingThresholds,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const handleOpenEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Tooltip title={<Typography>{t("open_settings")}</Typography>}>
        <IconButton onClick={handleOpenEdit} sx={{ p: 0 }}>
          <SettingsIcon
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disableRipple
          sx={{
            cursor: "default",
            ":hover": {
              backgroundColor: "transparent",
            },
            ":focus-within": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={1}
            >
              <Typography mr={2}>Raise hold-off time (s):</Typography>
              <TextField
                sx={{
                  width: 60,
                }}
                inputProps={{
                  style: {
                    color: theme.palette.common.black,
                    fontSize: 14,
                    padding: 0,
                    textAlign: "center",
                  },
                }}
                defaultValue={parameter?.raise_holdoff}
                onChange={(e) => {
                  setIsEditingThresholds(true);
                  parameter.raise_holdoff =
                    e.target.value === "" ? null : parseInt(e.target.value);
                  setAlarms([...alarms]);
                }}
              />
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={1}
            >
              <Typography mr={2}>Clear hold-off time (s):</Typography>
              <TextField
                sx={{
                  width: 60,
                }}
                inputProps={{
                  style: {
                    color: theme.palette.common.black,
                    fontSize: 14,
                    padding: 0,
                    textAlign: "center",
                  },
                }}
                defaultValue={parameter?.clear_holdoff}
                onChange={(e) => {
                  setIsEditingThresholds(true);
                  parameter.clear_holdoff =
                    e.target.value === "" ? null : parseInt(e.target.value);
                  setAlarms([...alarms]);
                }}
              />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
};
