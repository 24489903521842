import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Device } from "../interfaces/Device";

interface DeviceCtx {
  devices: Device[] | null;
  setDevices: Dispatch<SetStateAction<Device[] | null>>;
}

export const DevicesContext = createContext<DeviceCtx | null>(null);

export default function PatientsProvider({ children }: any) {
  const [devices, setDevices] = useState<Device[] | null>(null);

  const devicesContext = { devices, setDevices };

  return (
    <DevicesContext.Provider value={devicesContext}>
      {children}
    </DevicesContext.Provider>
  );
}
