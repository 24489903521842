import * as CONSTANTS from "../utils/constants/constants";

const axios = require("axios").default;

class VICUService {
  static getAllVICU() {
    return CONSTANTS.HOST + "api/icus/";
  }

  static getOneVICU() {
    return CONSTANTS.HOST + "api/icus/REPLACE/";
  }

  static addVICU(data: any, access_token: string) {
    console.log("addVCU: ", data);
    return axios
      .post(CONSTANTS.HOST + "api/icus/", data, {
        headers: { authorization: "Bearer " + access_token },
      })
      .then((response: any) => response)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }

  static getVICUPatients() {
    return CONSTANTS.HOST + "api/icus/REPLACE/patients/";
  }

  static getVICUDevices() {
    return CONSTANTS.HOST + "api/icus/REPLACE/devices/";
  }

  static getAvailableDevices() {
    return CONSTANTS.HOST + "api/icus/REPLACE/availabledevices/";
  }

  static manageVICUDevices(vicu_id: string, data: any, access_token: string) {
    return axios
      .patch(
        CONSTANTS.HOST + "api/icus/" + vicu_id + "/manage-devices/",
        data,
        {
          headers: { authorization: "Bearer " + access_token },
        }
      )
      .then((response: any) => response)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }

  static deleteVICU(vicu_id: string, access_token: string) {
    return axios
      .delete(CONSTANTS.HOST + "api/icus/" + vicu_id + "/", {
        headers: { authorization: "Bearer " + access_token },
      })
      .then((response: any) => response)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }

  static editVICU(
    vicu_id: string,
    name: string,
    contact: string,
    parmGranularity: number,
    vicu_type: string,
    access_token: string
  ) {
    const data = {
      vicu_name: name,
      contact_info: contact,
      vicu_type: vicu_type,
      parm_granularity: parmGranularity,
    };

    return axios
      .patch(CONSTANTS.HOST + "api/icus/" + vicu_id + "/", data, {
        headers: { authorization: "Bearer " + access_token },
      })
      .then((response: any) => response)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }

  /*
   * Monitor Groups
   */
  static getAllGroups() {
    return CONSTANTS.HOST + "api/v1/groups/";
  }

  static getOneGroup(group_id: String) {
    return CONSTANTS.HOST + "api/v1/groups/" + group_id + "/";
  }

  static getGroupDevices(group_id: String) {
    return CONSTANTS.HOST + "api/v1/groups/" + group_id + "/devices/";
  }

  static getGroupPoolDevices(group_id: String) {
    return CONSTANTS.HOST + "api/v1/groups/" + group_id + "/pooldevices/";
  }

  static manageGroupDevices(group_id: string, data: any, access_token: string) {
    return axios
      .patch(
        CONSTANTS.HOST + "api/v1/groups/" + group_id + "/manage-devices/",
        data,
        {
          headers: { authorization: "Bearer " + access_token },
        }
      )
      .then((response: any) => response)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }

  static getUserGroups(user_id: String) {
    return CONSTANTS.HOST + "api/v1/groups/membership/" + user_id + "/";
  }

  static manageGroupAssignments(
    user_id: String,
    data: any,
    access_token: string
  ) {
    return axios
      .patch(
        CONSTANTS.HOST + "api/v1/groups/membership/assign/" + user_id + "/",
        data,
        {
          headers: { authorization: "Bearer " + access_token },
        }
      )
      .then((response: any) => response)
      .catch((err: any) => {
        console.log(err);
        return err.data;
      });
  }
}

export default VICUService;
