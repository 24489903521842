import { useState, useEffect } from "react";
import {
  useTheme,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { User } from "../../interfaces/user/User";
import { checkEmail } from "../../pages/Login/ForgotPassword";
import { isBrowser } from "react-device-detect";
import UserAccessDialog from "./UserAccessDialog";
import { GroupType } from "./UserAccessDialog";
import VICUService from "../../services/VICUService";

export const UserManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [cookies, ,] = useCookies(["access_token"]);
  const [showNewUser, setShowNewUser] = useState(false); // Show new user input dialog
  const [errorMsg, setErrorMsg] = useState(""); // Show error message dialog
  const [showOK, setShowOK] = useState(false); // Show OK dialog on user add success
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [userOrg, setUserOrg] = useState<string>("");
  const [fetchUsers, setFetchUsers] = useState(0);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [userAdminState, setUserAdminState] = useState(false);
  const axios = require("axios").default;
  var token = cookies.access_token; // userCtx.user.token;
  var [users, setUsers] = useState<User[]>([]);
  const [user] = useState<User | null>(
    // @ts-ignore
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);

  const handleRowClick = (username: any) => {
    setSelectedUsername(username);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedUsername(null);
  };

  //console.log ("user: ", user)
  useEffect(() => {
    const target_url = UserService.getAllUsers();
    axios
      .get(target_url, {
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "text/plain",
        },
      })
      .then((response: any) => {
        setUsers(response.data);
        //console.log ("Response data:", response.data)
      })
      .catch((error: any) => {
        console.log("Fetch error response:", error);
      })
      .finally(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUsers]);

  const addUserButton = () => {
    console.log("addUserButton handler");
    setShowNewUser(true);
  };

  const handleCancelUser = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setShowNewUser(false);
    }
  };

  const updateGroupAssignments = (username: string, groups: GroupType[]) => {
    const extractedData = groups.map(({ vicu_id, assigned }) => ({
      vicu_id,
      assigned,
    }));

    VICUService.manageGroupAssignments(username, extractedData, token)
      .then((res: any) => {
        console.log(res);
      })
      .catch((error: any) => {
        console.log("Error updateGroupAssignments:", error);

        setErrorMsg(error);
      })
      .finally(() => {
        //console.log ("Completed createUser")
      });
  };

  const handleSaveUser = async (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      const userData: User = {
        last_name: lastName,
        first_name: firstName,
        username: userName,
        email: email,
        phone: phone,
        title: "",
        is_admin: userAdminState,
        organization: userOrg,
      };
      setSaveInProgress(true);
      UserService.createUser(userData, token)
        .then((res: any) => {
          console.log(res);
          setSaveInProgress(false);
          if (res?.status === 200) {
            setShowNewUser(false);
            setShowOK(true);
            setFetchUsers(fetchUsers + 1);
            return;
          }

          switch (res.response.status) {
            case 400: {
              //console.log ("400")
              const errors = res.request.response;
              setErrorMsg(errors.password);
              break;
            }
            case 409: {
              //console.log ("409", res.request.response)
              setErrorMsg(t(res.request.response));
              break;
            }

            case 404: {
              //console.log ("404")
              setErrorMsg("Not found.");
              break;
            }
          }
        })
        .catch((error: any) => {
          console.log("Error createUser:", error);

          setErrorMsg(error);
        })
        .finally(() => {
          //console.log ("Completed createUser")
        });
    }
  };

  const handleCloseOK = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setShowNewUser(false);
      setShowOK(false);
    }
  };
  const handleCloseError = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setErrorMsg("");
    }
  };

  const handleAdminUserChange = (event: any) => {
    setUserAdminState(event.target.checked);
  };

  const checkRequired = () => {
    if (!checkEmail(email)) {
      return false;
    }
    if (lastName === "" || firstName === "" || userName === "") {
      return false;
    }
    return true;
  };

  useEffect(() => {
    checkRequired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const fontVariant = isBrowser ? "body2" : "caption";
  console.log(users);
  return (
    <div>
      <Grid container>
        {/* SUCCESS DIALOG */}
        <Dialog open={showOK} fullWidth={true} maxWidth={"md"}>
          <DialogTitle> {t("success")}</DialogTitle>

          <DialogContent>
            <Typography>{t("user_added_successfully")}</Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseOK}> {t("close")}</Button>
          </DialogActions>
        </Dialog>

        {/* ERROR MESSAGES DIALOG */}
        <Dialog open={errorMsg !== ""} fullWidth={true} maxWidth={"md"}>
          <DialogTitle> {t("error")}</DialogTitle>
          <DialogContent>
            <Typography color={theme.palette.error.main}>
              {t(errorMsg)} : {userName}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseError}>{t("close")}</Button>
          </DialogActions>
        </Dialog>

        {/* NEW USER INPUT DIALOG  */}
        <Dialog open={showNewUser} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle> {t("add_user")}</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              {user?.is_superadmin && (
                <TextField
                  id="user_org"
                  label={t("user_org_id")}
                  type="text"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  margin="dense"
                  onChange={(e) => {
                    setUserOrg(e.target.value);
                  }}
                  sx={{
                    input: {
                      color: theme.palette.text.primary,
                      background: theme.palette.background.default,
                    },
                    label: {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              )}
              <TextField
                id="last_name"
                label={t("lastname")}
                type="text"
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />

              <TextField
                id="first_name"
                label={t("firstname")}
                type="text"
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />

              <TextField
                id="username"
                label={t("username")}
                type="text"
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />

              <TextField
                id="email"
                label={t("email_address")}
                type="email"
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />
              <TextField
                id="phone"
                label={t("phone")}
                type="text"
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                sx={{
                  input: {
                    color: theme.palette.text.primary,
                    background: theme.palette.background.default,
                  },
                  label: {
                    color: theme.palette.text.primary,
                  },
                }}
              />
              {user?.is_superadmin && (
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: theme.palette.primary.main }}
                      onChange={handleAdminUserChange}
                    />
                  }
                  label={t("make_admin_user")}
                />
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelUser}>{t("cancel")}</Button>
            <Button
              disabled={!checkRequired() || saveInProgress}
              onClick={handleSaveUser}
            >
              {t("save")}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid item xs={12} md={10}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "16px 6px",
                    }}
                  >
                    <Typography variant={fontVariant} fontWeight="500">
                      {t("lastname")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "16px 6px",
                      minWidth: 70,
                    }}
                  >
                    <Typography variant={fontVariant} fontWeight="500">
                      {t("firstname")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "16px 6px",
                    }}
                  >
                    <Typography variant={fontVariant} fontWeight="500">
                      {t("username")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "16px 6px",
                    }}
                  >
                    <Typography variant={fontVariant} fontWeight="500">
                      {t("email_address")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "16px 6px",
                    }}
                  >
                    <Typography variant={fontVariant} fontWeight="500">
                      {t("phone")}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "16px 6px",
                    }}
                  >
                    <Typography variant={fontVariant} fontWeight="500">
                      {t("admin")}?
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((user: User) => {
                  return (
                    <TableRow
                      key={user.username}
                      hover
                      onClick={() => handleRowClick(user.username)}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        "&:nth-of-type(odd)": {
                          background: theme.palette.info.dark,
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "6px 6px",
                        }}
                      >
                        <Typography variant={fontVariant}>
                          {user.last_name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "6px 6px",
                        }}
                      >
                        <Typography variant={fontVariant}>
                          {user.first_name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "6px 6px",
                        }}
                      >
                        <Typography variant={fontVariant}>
                          {user.username}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "6px 6px",
                        }}
                      >
                        <Typography variant={fontVariant}>
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "6px 6px",
                        }}
                      >
                        <Typography variant={fontVariant}>
                          {user.phone}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "6px 6px",
                        }}
                      >
                        <Typography variant={fontVariant}>
                          {user.is_admin ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={6} md={4}>
          <Button variant="contained" onClick={addUserButton}>
            {t("add_user")}
          </Button>
        </Grid>
      </Grid>
      {selectedUsername && (
        <UserAccessDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          username={selectedUsername}
          onOk={(updatedGroups: GroupType[]) => {
            // Process or store the updatedGroups as needed
            console.log("Updated groups:", updatedGroups);
            updateGroupAssignments(selectedUsername, updatedGroups);
          }}
        />
      )}
    </div>
  );
}; // ResetPassword
