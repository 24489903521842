import { Language } from "../../interfaces/Language";

export const languages: Language[] = [
  {
    code: "ar",
    name: "Arabic",
    country_code: "ar",
  },
  {
    code: "ca",
    name: "Català",
    country_code: "ca",
  },
  {
    code: "de",
    name: "Deutsch",
    country_code: "de",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "es",
    name: "Español",
    country_code: "es",
  },
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "it",
    name: "Italiano",
    country_code: "it",
  },
  {
    code: "no",
    name: "Norsk",
    country_code: "no",
  },
  {
    code: "ro",
    name: "Română",
    country_code: "ro",
  },
];
